import {Auth} from 'aws-amplify'
import {getCongitoDefaultGroup} from '../../login/reducers/set-auth-user-reducer'
const authProvider = {
    login: async () => {
        const auth = await Auth.federatedSignIn({provider: 'Google'}).catch(
            error => {
                console.error('federatedSign in error:', error)
            }
        )
        return Promise.resolve(auth)
    },
    logout: () => Auth.signOut()
        .then(() => {
            localStorage.removeItem('permissions')
        })
        .catch(error => console.error('AUTH_LOG_OUT error', error)),
    checkError: error => Promise.resolve().catch(error_ =>
        console.error('AUTH_ERROR fails:', error_, error)
    ),
    checkAuth: () => (
        Auth.currentAuthenticatedUser({
            bypassCache: false
        })
        //  created separate Saga invoked by USER_CHECK ( so that it works also when page is refreshed and data loaded from localstorage) it fetches data via Auth.authenticatedUser which already decodes the jwt
        // param of Then is the CognitoUser ( with JWT already decoded into readable payload)
            .then(authenticated => {
                const role = getCongitoDefaultGroup(authenticated)
                localStorage.setItem('permissions', JSON.stringify(role))

                return authenticated
            })
            .catch(error => {
                console.error(' AUTH_CHECK ERROR:', error)
                return Promise.reject()
            })
    ),
    getPermissions: () => Promise.resolve(localStorage.getItem('permissions'))
}
export default authProvider
