import React, {useEffect, useState} from 'react'
import {
    FunctionField
} from 'react-admin'
import {Card, makeStyles, Button, IconButton, Typography, Chip, Box} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import VisibilityIcon from '@material-ui/icons/Visibility'
import IconCancel from '@material-ui/icons/Cancel'
import {PropTypes} from 'prop-types'
import {findOrAddCache} from '../../app-configs/s3-provider'
import {getCreativeDisplay, downloadBtn, CreativeInfo, displayResolution, displaySize} from './list'
import {CardSizeSlider, getInitiatedScale} from '../../variation/components/card-size-slider'
import {DEFAULT_CARD_SIZE} from '../../shared/shared-constent-values'

export const cardDataGridJsx = props => <CreativeGrid {...props}/>

const CreativeGrid = props => {
    const {data, ids, selectedIds, displayedFilters, ...rest} = props
    const [selectedScale, setSelectedScale] = useState(getInitiatedScale())
    const [selectedCreative, setSelectedCreative] = useState()
    const [showCreativeDialog, setShowCreativeDialog] = useState(false)
    const isSelected = id => new Set(selectedIds).has(id)
    return (
        <>
            <div>
                <CardSizeSlider
                    setSelectedScale={setSelectedScale}
                    selectedScale={selectedScale}
                />
                <CreativeModal
                    creative={selectedCreative}
                    resetCreative={() => setSelectedCreative()}
                    showCreativeDialog={showCreativeDialog}
                    setShowCreativeDialog={setShowCreativeDialog}
                />
            </div>
            <div className="cards-grid-container">
                {ids.map(id => (
                    <CreativeCard
                        key={`creative-${id}`}
                        record={data[id]}
                        selected={isSelected(id)}
                        currentScale={selectedScale}
                        setSelectedCreative={setSelectedCreative}
                        setShowCreativeDialog={setShowCreativeDialog}
                        {...rest}
                    />
                ))}
            </div>
        </>
    )
}

CreativeGrid.propTypes = {
    data: PropTypes.object,
    ids: PropTypes.array,
    selectedIds: PropTypes.array,
    displayedFilters: PropTypes.any
}

const useStyles = makeStyles({
    card: ({currentScale}) => ({
        display: 'inline-block',
        margin: '4px',
        width: '300px',
        height: '300px',
        position: 'relative',
        textAlign: 'center',
        transform: `scale(${currentScale})`,
        transformOrigin: '0 0'
    }),
    cardHolder: ({currentScale}) => ({
        width: `${currentScale * DEFAULT_CARD_SIZE}px`,
        height: `${currentScale * DEFAULT_CARD_SIZE}px`,
        margin: '10px'
    }),
    content: {
        height: '77%',
        backgroundColor: '#ffffff'
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    }
})

const IS_LIST_GRID = false
const CreativeCard = props => {
    const {
        record,
        onToggleItem,
        selected,
        currentScale,
        setSelectedCreative,
        setShowCreativeDialog
    } = props
    const classes = useStyles({currentScale})
    const {id, key, fileTypeId, fileName} = record
    const [url, setUrl] = useState()
    useEffect(() => {
        if (key) {
            const getUrl = async () => {
                const creativeUrl = await findOrAddCache(key)
                setUrl(creativeUrl)
            }

            getUrl()
        }
    }, [key])

    const creativeDisplay = getCreativeDisplay(fileTypeId, url, IS_LIST_GRID)
    const selectCreative = record => {
        setSelectedCreative(record)
        setShowCreativeDialog(true)
    }

    return (
        <div className={classes.cardHolder}>
            <Card key={id} className={classes.card}>
                <Checkbox
                    checked={selected}
                    id={`cb-c-${id}`}
                    className="creative-select-cb"
                    color="primary"
                    onChange={() => onToggleItem(id)}
                />
                <CardContent className={classes.content}>
                    {creativeDisplay}
                    <div className="creative-card-filename">
                        {fileName}
                    </div>
                </CardContent>

                <CardActions className={classes.actions}>
                    <IconButton
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => selectCreative(record)}
                    >
                        <VisibilityIcon/>
                    </IconButton>
                    <FunctionField label="Download" record={record} render={downloadBtn}/>
                </CardActions>
            </Card>
        </div>
    )
}

CreativeCard.propTypes = {
    record: PropTypes.object,
    onToggleItem: PropTypes.func,
    selected: PropTypes.bool,
    currentScale: PropTypes.number,
    setSelectedCreative: PropTypes.func,
    setShowCreativeDialog: PropTypes.func
}

const CreativeModal = ({setShowCreativeDialog, showCreativeDialog, creative, resetCreative}) => {
    if (!creative) {
        return <></>
    }

    const handleCloseClick = () => {
        resetCreative()
        setShowCreativeDialog(false)
    }

    return (
        <div>
            <Dialog
                fullWidth
                open={showCreativeDialog}
                aria-label="Share Creatives to Partners"
                onClose={handleCloseClick}
            >
                <DialogTitle>{creative.fileName}</DialogTitle>
                <DialogContent>
                    <CreativeInfo record={creative} isListGrid={false}/>
                    <Box p="1em" display="flex" justifyContent="space-between">
                        <Box flex={1} mr="1em">
                            <Typography gutterBottom>Id</Typography>
                            <Chip
                                key={`cr${creative.id}-id`}
                                label={`cid-${creative.id}`}
                                variant="outlined"
                            />
                        </Box>
                        <Box flex={1} mr="1em">
                            <Typography gutterBottom>Resolution</Typography>
                            <Chip
                                key={`cr${creative.id}-resolution`}
                                label={displayResolution(creative)}
                                variant="outlined"
                            />
                        </Box>
                        <Box flex={1} mr="1em">
                            <Typography gutterBottom>Size</Typography>
                            <Chip
                                key={`cr${creative.id}-size`}
                                label={displaySize(creative)}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {downloadBtn(creative)}
                    &nbsp;&nbsp;
                    <Button
                        variant="contained"
                        startIcon={<IconCancel/>}
                        onClick={handleCloseClick}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreativeModal.propTypes = {
    setShowCreativeDialog: PropTypes.func,
    showCreativeDialog: PropTypes.bool,
    creative: PropTypes.object,
    resetCreative: PropTypes.func
}
