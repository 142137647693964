const MAX_WIDTH = 400
const MAX_HEIGHT = 300
const getThumbRatio = (
    srcWidth,
    srcHeight,
    maxWidth = MAX_WIDTH,
    maxHeight = MAX_HEIGHT
) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
    return {width: srcWidth * ratio, height: srcHeight * ratio}
}

const buildImageThumb = async file => {
    const img = new Image()
    img.src = URL.createObjectURL(file)
    const snapImg = resolve => {
        const canvas = document.querySelector('#canvas')
            ? document.querySelector('#canvas')
            : document.createElement('canvas')
        const ratio = getThumbRatio(img.width, img.height)
        canvas.width = ratio.width
        canvas.height = ratio.height
        canvas
            .getContext('2d')
            .drawImage(img, 0, 0, canvas.width, canvas.height)
        const stillImg = new Image()
        stillImg.src = canvas.toDataURL()
        resolve(stillImg)
    }

    return new Promise(function (resolve) {
        img.addEventListener(
            'load',
            () => {
                snapImg.call(this, resolve)
            },
            false
        )
    })
}

const getImage = async file => {
    const vid = document.createElement('video')
    vid.src = URL.createObjectURL(file)
    vid.preload = 'metadata'
    vid.muted = true
    vid.playsInline = true
    vid.play()

    const snapImg = resolve => {
        const canvas = document.querySelector('#canvas')
            ? document.querySelector('#canvas')
            : document.createElement('canvas')

        canvas.width = Math.min(MAX_WIDTH, vid.videoWidth)
        canvas.height = Math.min(MAX_HEIGHT, vid.videoHeight)
        /* const ratio = getThumbRatio(canvas.width, canvas.height) */
        canvas
            .getContext('2d')
            .drawImage(vid, 0, 0, canvas.width, canvas.height)
        const dataURI = canvas.toDataURL()
        const success = dataURI.length > 100000

        if (success) {
            const img = new Image()
            img.src = dataURI
            vid.pause()
            URL.revokeObjectURL(vid.src)
            vid.removeEventListener('timeupdate', snapImg)
            img.addEventListener('load', () => {
                resolve(img)
            })
        }
    }

    return new Promise(function (resolve) {
        vid.addEventListener(
            'timeupdate',
            () => {
                snapImg.call(this, resolve)
            },
            false
        )
    })
}

const buildVideoThumb = async file => getImage(file)

export const generateThumb = async file => {
    const generate = file =>
        new Promise(resolve => {
            if (file.type.startsWith('image/')) {
                const img = buildImageThumb(file)
                resolve(img)
            } else if (file.type.startsWith('video/')) {
                const img = buildVideoThumb(file)
                resolve(img)
            }
        })
    return generate(file).then(thumbData => ({thumbSrc: thumbData.src}))
}
