import React from 'react'
import PropTypes from 'prop-types'
import {MenuItemLink} from 'react-admin'
import {CreativesIcon} from './icon'

export const CreativesMenu = ({onMenuClick}) => (
    <MenuItemLink
        to="/creatives"
        primaryText="Creatives"
        leftIcon={<CreativesIcon/>}
        onClick={onMenuClick}
    />
)

CreativesMenu.propTypes = {
    onMenuClick: PropTypes.func
}

CreativesMenu.defaultProps = {
    onMenuClick: undefined
}
