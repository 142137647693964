import {Loading, useQuery, useInput} from 'react-admin'
import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import {useForm} from 'react-final-form'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

const creatableStyle = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        height: '49px',
        marginBottom: '28px'
    }),
    menu: styles => ({
        ...styles,
        zIndex: 999,
        overflowY: 'auto'
    }),
    option: (styles, {isDisabled, isFocused}) => ({
        ...styles,
        backgroundColor: isFocused ? '#E8E8E8' : 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
        color: 'black'
    }),
    descInfo: {
        fontSize: '12px',
        color: 'gray',
        marginBottom: '5px'
    }
}
const TagsHelper = ({style}) => (
    <div style={style}>
        *<b>Select </b>from available Tags or start writing to <b>Add</b> a
        new one
    </div>
)

TagsHelper.propTypes = {
    style: PropTypes.object
}
TagsHelper.defaultProps = {
    style: null
}
const getInitValue = customTags => {
    if (customTags.length === 0) {
        return {}
    }

    const values = customTags.map(element => ({label: element.name, value: element.id}))
    return values
}

export const CustomTagsAutoCompleteInput = props => (
    <CreatableTagsAutoCompleteSelect
        resource="customTags"
        label="Available Custom Tags"
        source="customTags"
        optionValue="customTags"
        optionText="name"
        {...props}
    />
)

const CreatableTagsAutoCompleteSelect = props => {
    const form = useForm()
    const {
        input: {name, onChange}
    } = useInput(props)
    const {
        label,
        record: {customTags}
    } = props
    const {data, loading, error: failure} = useQuery({
        type: 'getDataSources',
        resource: 'customTags'
    })

    const [selectedOption, setSelectedOption] = useState()
    const initialValue
        = customTags && customTags.length > 0 ? getInitValue(customTags) : null

    const handleChange = selection => {
        if (!selection) {
            selection = []
        }

        const values
            = selection.length > 0 ? selection.map(item => item.value) : selection
        setSelectedOption(selection)
        onChange(selection && selection.label ? selection.label : '')
        form.change('customTags', values)
    }

    if (loading) {
        return <Loading/>
    }

    if (failure) {
        return (
            <>
                <ErrorOutline/> an error occurred displaying CustomTags
            </>
        )
    }

    const options = data.map(({id, name}) => ({
        value: id,
        label: name,
        key: id
    })).sort((a, b) => (a.label.length - b.label.length))
    return (
        <div>
            <TagsHelper style={creatableStyle.descInfo}/>
            <CreatableSelect
                isClearable
                isMulti
                name={name}
                menuPlacement="auto"
                placeholder={label}
                className="basic-multi-select"
                value={selectedOption || initialValue}
                options={options}
                styles={creatableStyle}
                style={{height: 'fit-content'}}
                onChange={handleChange}

            />
        </div>
    )
}

CreatableTagsAutoCompleteSelect.propTypes = {
    label: PropTypes.string.isRequired,
    record: PropTypes.object
}
CreatableTagsAutoCompleteSelect.defaultProps = {
    record: {}
}
