import JSZip from 'jszip'
import Storage from '@aws-amplify/storage'

export const generateZipFile = async (
    files,
    folderName,
    callBackSuccess,
    callBackUpdate,
    callBackError
) => {
    try {
        const zip = new JSZip()
        await getAndCreateFiles(zip.folder(folderName), files)
        zip.generateAsync(
            {type: 'blob', compression: 'STORE', streamFiles: true},
            metadata => {
                callBackUpdate(metadata.percent.toFixed(2) + ' %')
            }
        ).then(content => {
            if (typeof callBackSuccess === 'function') {
                callBackSuccess(content, formatFinalZipName(folderName))
            }

            return content
        })
    } catch (error) {
        if (typeof callBackError === 'function') {
            callBackError(error)
        } else {
            console.error(error)
        }
    }
}

const getAndCreateFiles = async (zipFolder, files) =>
    Promise.all(
        files.map(item =>
            Storage.get(item.key, {download: true}).then(data => {
                const {Body} = data
                zipFolder.file(item.name, Body)
            })
        )
    )

const formatFinalZipName = name =>
    name.includes('.zip') ? name : `${name}.zip`
