export const PARTNERS = [
    {
        name: 'Facebook',
        id: 'facebook'
    },
    {
        name: 'Youtube',
        id: 'youtube'
    }
]
