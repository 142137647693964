import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const styles = makeStyles({
    linksContainer: {
        margin: '5px',
        display: 'flex',
        justifyContent: 'center',
        color: 'white'
    },
    linksText: {
        fontSize: '12px',
        color: 'white'
    }
})
export const TermsAndPolicies = () => {
    const classes = styles()
    return (
        <>
            <div className={classes.linksContainer}>
                <a href="./privacy_policy.html" className={classes.linksText}>Privacy Policy |</a>
                <a href="./terms_and_conditions.html" className={classes.linksText}>&nbsp; Terms & Conditions</a>
            </div>
            <div className={classes.linksContainer}>
                <a href="https://www.youtube.com/t/terms" className={classes.linksText}>YouTube Terms of Service |</a>
                <a href="https://myaccount.google.com/permissions?pli=1" className={classes.linksText}>&nbsp; Google Security & Revocations Setting |</a>
                <a href="https://policies.google.com/privacy" className={classes.linksText}>&nbsp; Google Privacy Policy</a>
            </div>
        </>
    )
}
