import {
    Edit,
    TopToolbar,
    ListButton,
    FormTab,
    TextField,
    TabbedForm,
    required,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    RefreshButton,
    TextInput,
    Labeled,
    useGetList
} from 'react-admin'
import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import UploadArea from './upload/upload-component'
import {PrepareToolBar} from './variation-toolbar'
import {
    ConceptAutoCompleteInput,
    PropertySelect
} from './property-select'
import {
    validateCreative,
    validateName
} from './input-validations'
import {CustomTagsAutoCompleteInput} from './multi-custom-tags-component'
import MultipleUpload from './upload/multiple-upload'
import {CreativeValidation} from './shared-components'
import {Branch} from './branch'

const Title = ({record}) => (
    <span>{record ? ` Edit ${record.fullName}` : ''}</span>
)

Title.propTypes = {
    record: PropTypes.object
}

Title.defaultProps = {
    record: undefined
}

const EditActions = ({data, hasShow, hasList, ...props}) => (
    <TopToolbar>
        {/* <ShowButton label="ra.action.show" record={data} {...props} /> */}
        <RefreshButton label="ra.action.refresh" {...props}/>
        <ListButton label="ra.action.list" {...props}/>
    </TopToolbar>
)

EditActions.propTypes = {
    props: PropTypes.object,
    data: PropTypes.object,
    hasShow: PropTypes.any,
    hasList: PropTypes.any
}

const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '1rem'}
})

//  TODO we can use this to manipulate data in form before sending it to dataprovider for update - and eventually to prevent saving if no edits were done
/* const SaveOnlyWithChanges = ({handleSubmitWithRedirect, ...props}) => {
    const {basePath, redirect, resource} = props
    const [create] = useCreate(resource)
    const redirectTo = useRedirect()
    const notify = useNotify()

    const form = useForm()
    const handleClick = useCallback(() => {
        console.log('SAVE EDIT', form)
        if (form.getState().dirty) {
            handleSubmitWithRedirect('edit')
        } else {
            // FIXME showNotification("nothing was changed")
            console.warn('no changes to save')
        }
        //
        // // change the average_note field value
        // form.change('average_note', 10);
    }, [form, handleSubmitWithRedirect])

    // override handleSubmitWithRedirect with custom logic
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
} */

export const VariationEdit = props => {
    const classes = useStyles()
    const languageProps = useGetList(
        'languages',
        {page: 1, perPage: 200},
        {field: 'name', order: 'ASC'}
    )

    return (
        <Edit
            {...props}
            title={<Title/>}
            actions={<EditActions/>}
            undoable={false}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<PrepareToolBar/>}
                redirect="list"
                submitOnEnter={false}
                validate={validateCreative}
                // debug={object => {
                //    console.log('SPY FORM', object)
                // }}
            >
                <FormTab label="General Info & Tags">
                    <TextField source="id" label="Unique Identifier"/>

                    <ReferenceInput
                        label="Game"
                        source="gameId"
                        reference="games"
                        filter={{isActiveBrand: true}}
                        perPage={0}
                    >
                        <SelectInput optionText="name" validate={required()}/>
                    </ReferenceInput>

                    <ConceptAutoCompleteInput/>

                    <TextInput
                        fullWidth
                        multiline
                        resource="variations"
                        source="name"
                        label="Variation Name"
                        validate={validateName}
                    />

                    <TextInput
                        fullWidth
                        multiline
                        source="description"
                        label="Source Files"
                    />

                    <CustomTagsAutoCompleteInput/>

                    <PropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock}
                        resource="themes"
                        validate={required()}
                        label="Theme"
                        source="themes"
                        optionValue="id"
                        optionText="name"
                    />

                    <PropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock}
                        resource="contents"
                        validate={required()}
                        label="Content"
                        source="contents"
                        optionValue="id"
                        optionText="name"
                    />

                    <PropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock}
                        resource="moods"
                        validate={required()}
                        label="Mood"
                        source="moods"
                        optionValue="id"
                        optionText="name"
                    />

                    <PropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock}
                        resource="colorSchemes"
                        label="Color Scheme"
                        source="colorSchemes"
                        optionValue="id"
                        optionText="name"
                    />

                    <div className="creative-tag edit-tags">
                        <ReferenceInput
                            label="Execution Style"
                            source="styleId"
                            reference="styles"
                            perPage={0}
                        >
                            <SelectInput
                                optionText="name"
                                validate={required()}
                            />
                        </ReferenceInput>

                        <ReferenceInput
                            label="Classification"
                            source="classificationId"
                            reference="classifications"
                            perPage={0}
                        >
                            <SelectInput
                                optionText="name"
                                validate={required()}
                            />
                        </ReferenceInput>

                        <ReferenceInput
                            label="Motivation"
                            source="motivationId"
                            reference="motivations"
                            perPage={0}
                        >
                            <SelectInput
                                optionText="name"
                                validate={required()}
                            />
                        </ReferenceInput>
                        <Branch formClassName={classes.inlineBlock} targetSource="branchId"/>
                    </div>
                </FormTab>
                <FormTab label="Creatives">
                    <CreativeValidation/>
                    <Labeled label="Creatives multiple upload"/>
                    <MultipleUpload languageProps={languageProps}/>
                    <ArrayInput source="creatives">
                        <SimpleFormIterator>
                            <UploadArea languageProps={languageProps}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
