import React from 'react'
import {MenuItemLink, usePermissions} from 'react-admin'
import PropTypes from 'prop-types'
import {TagIcon, TagListIcon} from './icon'
import {SubMenuUIComponent} from '../layout/components/sub-menu'
import {hasEditPermission} from '../app-configs/auth/utils'

const tags = [
    {to: '/moods', primaryText: 'Moods'},
    {to: '/themes', primaryText: 'Themes'},
    {to: '/styles', primaryText: 'Styles'},
    {to: '/colorSchemes', primaryText: 'Color Scheme'},
    {to: '/cta', primaryText: 'CTA'},
    {to: '/motivations', primaryText: 'Motivations'},
    {to: '/classifications', primaryText: 'Classifications'},
    {to: '/contents', primaryText: 'Content'},
    {to: '/brandings', primaryText: 'Branding'},
    {to: '/customTags', primaryText: 'Custom Tags'},
    {to: '/platforms', primaryText: 'Platforms'},
    {to: '/branches', primaryText: 'Branches'},
    {to: '/subBranches', primaryText: 'SubBranches'}
]
export const TagMenu = ({onMenuClick, handleToggle, state, open}) => {
    const {permissions} = usePermissions()

    return hasEditPermission(permissions) ? (
        <SubMenuUIComponent
            handleToggle={() => handleToggle('tags')}
            isOpen={state.tags}
            sidebarIsOpen={open}
            name="Tags"
            icon={<TagIcon/>}
        >
            {tags.map(({to, primaryText}) => (
                <MenuItemLink
                    key={primaryText}
                    to={to}
                    primaryText={primaryText}
                    leftIcon={<TagListIcon/>}
                    onClick={onMenuClick}
                />
            ))}
        </SubMenuUIComponent>
    ) : null
}

TagMenu.propTypes = {
    handleToggle: PropTypes.func,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    state: PropTypes.object
}

TagMenu.defaultProps = {
    handleToggle: undefined,
    onMenuClick: undefined,
    open: undefined,
    state: undefined
}
