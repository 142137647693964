import React from 'react'
import {Create, required, SimpleForm, TextInput} from 'react-admin'
import {PropTypes} from 'prop-types'
import {AutocompletePropertySelect} from '../variation/components/property-select'

export const TagCreate = ({basePath, ...props}) => {
    const tagResource = props.resource
    const isSubBranch = tagResource === 'subBranches'
    const transform = data => {
        const creationParameters = {name: data.name}
        if (isSubBranch) {
            creationParameters.branchId = data.branches
        }

        return {
            ...data,
            creationParams: creationParameters
        }
    }

    return (
        <Create {...props} basePath={basePath} transform={transform}>
            <SimpleForm redirect="list">
                <TextInput
                    disabled
                    source="resource"
                    initialValue={tagResource}
                />
                {
                    isSubBranch && <AutocompletePropertySelect
                        resource="variations"
                        validate={required()}
                        label="Branch"
                        source="branches"
                        optionValue="id"
                        optionText="name"
                    />

                }
                <TextInput source="name"/>
            </SimpleForm>
        </Create>
    )
}

TagCreate.propTypes = {
    basePath: PropTypes.string,
    resource: PropTypes.string
}
