export const prepareData = (record, isIteration) => {
    const {
        concept,
        name,
        description,
        classificationId: classifications,
        motivationId: motivations,
        styleId: styles,
        branchId: branches,
        customTags,
        creatives,
        thumbnailKey
    } = record

    const colorSchemes = extractIds(record.colorSchemes)
    const subBranches = extractIds(record.subBranches)
    const contents = extractIds(record.contents)
    const moods = extractIds(record.moods)
    const themes = extractIds(record.themes)

    const source = {
        concept,
        name,
        description,
        styles,
        classifications,
        motivations,
        colorSchemes,
        subBranches,
        contents,
        moods,
        themes,
        branches,
        customTags
    }
    if (isIteration) {
        source.forkedFrom = record.id
        source.games = record.gameId
    } else {
        const newCreatives = creatives.map(original => {
            const creative = {...original}
            delete creative.id
            // TODO STP-2219 rename creative variables to be same on create/edit/clone/duplicate/backend
            creative.ImageWidth = original.width
            creative.ImageHeight = original.height
            creative.Orientation = original.orientationId
            creative.filename = original.fileName
            creative.fileType = original.fileTypeId
            creative.brandings = extractIds(original.brandings)
            creative.platforms = extractIds(original.platforms)
            return creative
        })
        source.creatives = newCreatives
        source.thumbnailKey = thumbnailKey
    }

    return source
}

export const getCloneURI = object => {
    const data = JSON.stringify(object)
    return `/variations/create?source=${encodeURIComponent(data)}`
}

export const extractIds = items => items && items.map(item => {
    if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
        throw new TypeError('Object dosnt have the property id')
    }

    return item.id
})

