import React from 'react'
import {PropTypes} from 'prop-types'

export const formatBytes = (bytes, decimals = 0) => {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return Number.parseFloat(((bytes / k) ** i).toFixed(dm)) + ' ' + sizes[i]
}

export const MediaDetails = ({record}) => {
    const mainFile = record.fileSize
        ? record
        : (record.resolutions
            ? record.resolutions[0]
            : null)
    if (!mainFile) {
        return null
    }

    const fileSize = mainFile.fileSize ? formatBytes(mainFile.fileSize) : null
    const fileWidth
        = mainFile.ImageWidth && mainFile.ImageWidth !== 'n/a'
            ? mainFile.ImageWidth
            : null
    const fileHeight
        = mainFile.ImageHeight && mainFile.ImageHeight !== 'n/a'
            ? record.ImageHeight
            : null
    const resolution
        = fileWidth && fileHeight ? ` - ${fileWidth}x${fileHeight}` : ''

    return (
        fileSize && (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '-28px'
                }}
            >
                <div
                    style={{
                        height: '20px',
                        width: '100%',
                        backgroundColor: 'white',
                        opacity: '0.7',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '15px'
                    }}
                >
                    {fileSize + resolution}
                </div>
            </div>
        )
    )
}

MediaDetails.propTypes = {
    record: PropTypes.object.isRequired
}
