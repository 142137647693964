import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {GoogleLogin, GoogleLogout} from 'react-google-login'
import {
    YT_CLIENT_ID,
    YT_HOSTED_DOMAIN,
    YT_SCOPE
} from '../../app-configs/partners/partner-constants'

export const AuthenticateButton = ({validToken, createToken}) => {
    const [loggedIn, setLoggedIn] = useState(validToken)
    const onLoggedOut = () => setLoggedIn(false)
    const onGoogleLoginFailed = error => {
        console.error('onGoogleLoginFailed', error)
        setLoggedIn(false)
    }

    const saveGoogleAccessCode = data => {
        const {code} = data
        setLoggedIn(true)
        createToken(code)
    }

    return loggedIn ? (
        <>
            Logged in to Youtube &nbsp;&nbsp;
            <GoogleLogout
                clientId={YT_CLIENT_ID}
                buttonText="Logout"
                onLogoutSuccess={() => onLoggedOut()}
            />
        </>
    ) : (
        <GoogleLogin
            hostedDomain={YT_HOSTED_DOMAIN}
            redirectUri={`${window.location.origin}/oauth2/idresponse`}
            clientId={YT_CLIENT_ID}
            accessType="offline"
            responseType="code"
            prompt="consent"
            scope={YT_SCOPE}
            onSuccess={data => saveGoogleAccessCode(data)}
            onFailure={onGoogleLoginFailed}
        />
    )
}

AuthenticateButton.propTypes = {
    validToken: PropTypes.bool,
    createToken: PropTypes.func
}
