import {
    SOCIAL_LOGIN,
    SOCIAL_LOGIN_SUCCEEDED,
    SET_AUTHORIZED_USER
} from './action-types'

export const socialLogin = ({provider, redirect}) => ({
    type: SOCIAL_LOGIN,
    payload: {
        provider,
        redirect
    }
})

export const socialLoginSucceed = ({cognitoUser, redirect}) => ({
    type: SOCIAL_LOGIN_SUCCEEDED,
    payload: {
        cognitoUser,
        redirect
    }
})

export const setAuthUser = user => ({
    type: SET_AUTHORIZED_USER,
    payload: {
        user
    }
})
