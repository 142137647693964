import React from 'react'
import {connect} from 'react-redux'
import {useNotify} from 'react-admin'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import NotificationsIcon from '@material-ui/icons/Notifications'
import dataProvider from '../app-configs/data-provider/data-provider'
import {getUserEmail} from '../login/selectors/user-selector'

const AddSubscription = props => {
    const {
        values: {gameId, branchId}
    } = props
    const disabled = gameId === undefined && branchId === undefined
    const notify = useNotify()

    return (
        <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={disabled}
            className="filter-form-button"
            onClick={() => saveSubscription(props, notify)}
        >
            <NotificationsIcon/>
            Subscribe
        </Button>
    )
}

AddSubscription.propTypes = {
    values: PropTypes.object
}

AddSubscription.defaultProps = {
    values: {}
}

const saveSubscription = ({email, values}, notify) => {
    const gamesAndBranches = {
        gameId: values.gameId,
        branchId: values.branchId
    }

    dataProvider
        .create('subscriptions', {
            data: {
                filter: JSON.stringify(gamesAndBranches),
                email
            }
        })
        .then(notify('Subscription saved', 'info'))
        .catch(error =>
            notify(`Subscription couldn’t save: ${error.message}`, 'warning')
        )
}

const mapStateToProps = state => ({
    email: getUserEmail(state)
})

export const AddSubscriptionButton = connect(mapStateToProps)(AddSubscription)
