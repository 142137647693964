import React from 'react'
import {PropTypes} from 'prop-types'
import {Card, makeStyles} from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

import {ImageField, EditButton, usePermissions} from 'react-admin'

import CustomDeleteBtnConfirmation from './custom-delete-btn-confirmation'

import {DownloadAll} from './download'
import {FileTypeIcon} from './file-type-icon'
import {ImageOverlay} from './image-overlay'
import {hasEditPermission} from '../../app-configs/auth/utils'
import {
    CARD_DEFAULT_SCALE,
    DEFAULT_CARD_SIZE
} from '../../shared/shared-constent-values'

import AcUnitIcon from '@material-ui/icons/AcUnit'

const useStyles = makeStyles({
    card: ({isSelected, currentScale}) => ({
        display: 'inline-block',
        margin: '1%',
        width: '300px',
        height: '300px',
        border: isSelected ? '2px solid red' : 'none',
        position: 'relative',
        transform: `scale(${currentScale})`,
        transformOrigin: '0 0',
        textAlign: 'center'
        // un comment maxWidth line to get a fixed size of card grid even on small screen
    }),
    content: ({isArchivedMode}) => ({
        // backgroundColor: '#ebebeb',
        minHeight: '168px',
        maxHeight: '230px',
        overflow: 'hidden',
        backgroundColor: isArchivedMode ? '#ffecec' : '#ffffff'
    }),
    cardHolder: ({currentScale}) => ({
        width: `${currentScale * DEFAULT_CARD_SIZE}px`,
        height: `${currentScale * DEFAULT_CARD_SIZE}px`,
        margin: '10px'
    }),
    image: {
        justifyContent: 'center',
        cursor: 'grab',
        objectFit: 'cover',
        minHeight: '220px',
        minWidth: '300px',
        maxHeight: 'fit-content !important',
        marginLeft: '-25px'
    },
    actions: ({currentScale}) => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-5px',
        transform: currentScale < 1 ? 'scale(1.4)' : ''
    })
})

const VariationCard = props => {
    const {
        record,
        selectedId,
        toggleItem,
        id,
        basePath,
        currentScale,
        isArchivedMode
    } = props
    const classes = useStyles({
        isSelected: id === selectedId,
        currentScale: currentScale || CARD_DEFAULT_SCALE,
        isArchivedMode
    })
    const {permissions} = usePermissions()
    if (!record) {
        return <></>
    }

    const showOnlyIcon = currentScale < 1

    let cardActions
    if (isArchivedMode) {
        cardActions = <AcUnitIcon/>
    } else {
        cardActions = (
            <CardActions className={classes.actions}>
                {hasEditPermission(permissions) && (
                    <EditButton
                        label={showOnlyIcon ? '' : 'ra.action.edit'}
                        record={record}
                        id={id}
                        basePath={basePath}
                    />
                )}
                <DownloadAll record={record} showOnlyIcon={showOnlyIcon}/>
                <CustomDeleteBtnConfirmation
                    label={showOnlyIcon ? '' : 'ra.action.delete'}
                    record={record}
                    {...props}
                />
            </CardActions>
        )
    }

    return (
        <div className={classes.cardHolder}>
            <Card key={id} className={classes.card}>
                <CardContent className={classes.content}>
                    <ImageOverlay record={record}/>
                    <div className="variation-card-filetypes">
                        {record.fileTypes
                            && record.fileTypes.map(filetype => (
                                <FileTypeIcon
                                    key={filetype}
                                    fileType={filetype}
                                />
                            ))}
                    </div>
                    <ImageField
                        record={record}
                        source="thumbnailUrl"
                        className={`${classes.image} card-image-holder`}
                        title={record.fullName}
                        onClick={event => {
                            toggleItem(record.id)
                            scrollToSelectedVariation(event.target)
                        }}
                    />
                </CardContent>

                {cardActions}
            </Card>
        </div>
    )
}

/**
 * scroll variation list to the position of the selected card,this function need a tiny delay since react admin re-order grid view upon screen size change
 * @param card , the selected variation card
 */
const scrollToSelectedVariation = card => {
    setTimeout(() => {
        const bodyRect = document.body.getBoundingClientRect()
        const cardRect = card.getBoundingClientRect()
        const marginTop = 60 // add some space above card for styling
        const offset = cardRect.top - bodyRect.top - marginTop // get card y position on the page
        window.scrollTo(0, offset)
    }, 200)
}

export default VariationCard

VariationCard.propTypes = {
    record: PropTypes.object.isRequired,
    toggleItem: PropTypes.func.isRequired,
    selectedId: PropTypes.number,
    currentScale: PropTypes.number,
    id: PropTypes.number,
    basePath: PropTypes.string,
    isArchivedMode: PropTypes.bool
}
VariationCard.defaultProps = {
    selectedId: undefined,
    basePath: undefined
}
