export const isStpRole = permissions =>
    permissions && permissions.includes('stp_engineering')
const isOthersRole = permissions =>
    permissions && permissions.includes('clb-others')
const isEditRole = permissions =>
    permissions && permissions.includes('clb-edit')
const isUploadRole = permissions =>
    permissions
    && permissions.includes('clb-upload')
    && permissions.includes('marketing-art')
const isDownloadRole = permissions =>
    permissions
    && permissions.includes('clb-download')
    && permissions.includes('marketing')
const isViewRole = permissions =>
    permissions && permissions.includes('clb-view')
const isUserAdminRole = permissions =>
    permissions && permissions.includes('clb-user-admin')

export const hasEditPermission = permissions =>
    isEditRole(permissions) || isStpRole(permissions)

export const hasTagAdminPermission = permissions =>
    hasEditPermission(permissions)

export const hasUploadPermission = permissions =>
    isUploadRole(permissions) || hasEditPermission(permissions)

export const hasCreateTagPermission = permissions =>
    isUploadRole(permissions) || hasEditPermission(permissions)

export const hasDownloadPermission = permissions =>
    isDownloadRole(permissions)
    || isOthersRole(permissions)
    || hasUploadPermission(permissions)

export const hasViewPermission = permissions =>
    isViewRole(permissions) || hasDownloadPermission(permissions)

export const hasUserMgmtPermission = permissions =>
    isStpRole(permissions) || isUserAdminRole(permissions)
