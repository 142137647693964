import {SET_AUTHORIZED_USER} from '../actions/action-types'
import {USER_CHECK} from 'react-admin'

const initialState = null
export const setAuthorizedUser = (
    // eslint-disable-next-line default-param-last
    previousState = initialState,
    {type, payload}
) => {
    switch (type) {
        case SET_AUTHORIZED_USER:
            return {...previousState, ...extractUserData(payload.user)}
        case USER_CHECK:
            return {...previousState, ...extractUserData(payload.user)}
        default:
            return previousState
    }
}

const isDefault = group => group.includes(process.env.REACT_APP_USER_POOL_ID)
export const getCongitoDefaultGroup = data =>
    data
    && data.signInUserSession.idToken.payload['cognito:groups'].filter(
        g => !isDefault(g)
    )

const extractUserData = user => {
    if (user) {
        const {email, name, picture} = user.attributes
        const groups = getCongitoDefaultGroup(user)
        return {
            email,
            name,
            picture,
            localStoragePrefix: user.keyPrefix,
            username: user.username,
            groups
        }
    }

    return initialState
}
