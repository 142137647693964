import restDataProvider from 'ra-data-simple-rest'
import {httpClient} from '../http-client/http-client'
import {
    getFileTypes,
    prepareForCreate,
    prepareForUpdate
} from './data-formatters'
import {retrieveThumbnailUrls, retrieveVariationCreatives} from '../s3-provider'
import {
    resourceRequireFilter,
    setConceptSortFilter,
    setUserPerPageCache,
    addGameIdToGamesList,
    isObjectEmpty
} from '../../app-configs/data-provider/utils'

import {loadSharedLinksByLoggedUser} from '../../creatives/util/load-shared-links'

const provider = restDataProvider(
    process.env.REACT_APP_API_URL,
    httpClient,
    'X-Total-Count' //  see STP-1980
)

const parametersDefaultProps = {
    filter: {},
    sort: {
        field: 'name',
        order: 'ASC'
    },
    range: [0, 49],
    pagination: {
        page: 1,
        perPage: 0
    }
}

// used as cache for aside component
const VARIATIONS_MAP = new Map()
const getId = ({id}) =>
    id.toString().includes('aside') ? id.replace(/\D/g, '').toString() : 'NA'
const addVariation = variation => {
    if (!variation) {
        return
    }

    const {id} = variation.data
    VARIATIONS_MAP.set(id.toString(), variation)

    return variation
}

const cacheDataProviderProxy = (dataProvider, duration = 5 * 60 * 1000) =>
    new Proxy(dataProvider, {
        get: (target, name) => (resource, parameters) => {
            if (
                name === 'getOne'
                || name === 'getMany'
                // STP-1966: it's workaround to avoid variation being cached. Problem is that variation getList and getOne have different data structure
                || (name === 'getList' && resource !== 'variations')
                || (name === 'getList' && resource !== 'sharedlinks')
            ) {
                return dataProvider[name](resource, parameters).then(
                    response => {
                        const validUntil = new Date()
                        validUntil.setTime(validUntil.getTime() + duration)
                        response.validUntil = validUntil
                        return response
                    }
                )
            }

            return dataProvider[name](resource, parameters)
        }
    })

const clbDataProvider = {
    ...provider,
    getOne: async (resource, parameters) => {
        switch (resource) {
            case 'variations': {
                const id = getId(parameters)
                if (VARIATIONS_MAP.has(id)) {
                    return Promise.resolve(VARIATIONS_MAP.get(id))
                }

                return provider
                    .getOne(resource, parameters)
                    .then(retrieveVariationCreatives)
                    .then(addVariation)
            }

            default:
                return provider.getOne(resource, parameters)
        }
    },
    getList: async (resource, parameters) => {
        let selectedPerPage
        if (
            resourceRequireFilter(resource)
            && isObjectEmpty(parameters.filter)
        ) {
            selectedPerPage = parameters.pagination.perPage
            setUserPerPageCache(resource, selectedPerPage)
            parameters.pagination.perPage = 3
        }

        switch (resource) {
            case 'variations': {
                return provider
                    .getList(resource, parameters)
                    .then(getFileTypes)
                    .then(retrieveThumbnailUrls)
            }

            case 'concepts': {
                parameters = setConceptSortFilter(parameters)

                return provider.getList(resource, parameters)
            }

            case 'sharedlinks': {
                return loadSharedLinksByLoggedUser()
            }

            default:
                return provider.getList(resource, parameters)
        }
    },
    update: async (resource, parameters) => {
        if (resource === 'variations') {
            const variation = await prepareForUpdate(parameters)
            const {id} = variation
            VARIATIONS_MAP.delete(id.toString())
            return provider.update(resource, {...parameters, data: variation})
        }

        return provider.update(resource, parameters)
    },
    getDataSources: async (resource, parameters = parametersDefaultProps) => {
        if (resource === 'concepts') {
            parameters = setConceptSortFilter(parameters)
        }

        return provider.getList(resource, parameters).then(response => response)
    },
    getMany: async (resource, parameters) => {
        switch (resource) {
            case 'games': {
                return provider
                    .getMany(resource, parameters)
                    .then(addGameIdToGamesList)
                /* TODO check if this is necessary to ctaId too */
                /* TODO check if this is necessary to styleId too */
            }

            case 'concepts': {
                parameters = setConceptSortFilter(parameters)

                return provider.getList(resource, parameters)
            }

            default:
                return provider.getMany(resource, parameters)
        }
    },
    create: async (resource, parameters) => {
        if (resource === 'variations') {
            // fixme: variation name is not unique - add validation
            const variation = await prepareForCreate(parameters.data)
            return provider.create(resource, {...parameters, data: variation})
        }

        return provider.create(resource, parameters)
    }
}

const CACHE_DURATION = 5 * 1000 // TODO STP-1931 - adjust value depends on needs
export default cacheDataProviderProxy(clbDataProvider, CACHE_DURATION)
