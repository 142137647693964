import React from 'react'
import {PropTypes} from 'prop-types'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import {ReferencedPropertySelect} from './shared-components'

export const OPTIONAL_FILTERS = [
    {
        filterKey: 'mood',
        label: 'Mood',
        cReference: 'moods',
        source: 'moods.id',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'colorScheme',
        label: 'Color Scheme',
        cReference: 'colorSchemes',
        source: 'colorSchemes.id',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'customTag',
        label: 'Custom Tags',
        cReference: 'customTags',
        source: 'customTags.id',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'theme',
        label: 'Theme',
        cReference: 'themes',
        source: 'themes.id',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'executionStyle',
        label: 'Execution Styles',
        cReference: 'styles',
        source: 'styleId',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'motivation',
        label: 'Motivation',
        cReference: 'motivations',
        source: 'motivationId',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'classification',
        label: 'Classification',
        cReference: 'classifications',
        source: 'classificationId',
        allowMultipleSelection: true,
        allowAutoComplete: false
    },
    {
        filterKey: 'content',
        label: 'Content',
        cReference: 'contents',
        source: 'contents.id',
        allowMultipleSelection: true,
        allowAutoComplete: true
    }
]

export const OptionalFilterField = props => {
    const {
        hideFilter,
        filterKey,
        label,
        cReference,
        source,
        getFilter,
        allowMultipleSelection,
        allowAutoComplete,
        values,
        displayedFilters,
        changeFormItem
    } = props
    if (!displayedFilters || !displayedFilters[filterKey]) {
        return null
    }

    const hideFilterClickButton = () => {
        changeFormItem(source, undefined)
        hideFilter(filterKey)
    }

    return (
        <div key={filterKey} className="pos-relative">
            <RemoveCircleOutlineIconButton onClick={hideFilterClickButton}/>
            <ReferencedPropertySelect
                label={label}
                cReference={cReference}
                source={source}
                allowMultipleSelection={allowMultipleSelection}
                allowAutoComplete={allowAutoComplete}
                filter={getFilter(values, source)}
            />
        </div>
    )
}

OptionalFilterField.propTypes = {
    hideFilter: PropTypes.func.isRequired,
    changeFormItem: PropTypes.func.isRequired,
    filterKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cReference: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    getFilter: PropTypes.func.isRequired,
    allowMultipleSelection: PropTypes.bool.isRequired,
    allowAutoComplete: PropTypes.bool,
    values: PropTypes.object.isRequired,
    displayedFilters: PropTypes.any.isRequired
}

const RemoveCircleOutlineIconButton = ({onClick}) => (
    <RemoveCircleOutlineIcon className="remove-filter-icon" onClick={onClick}/>
)

RemoveCircleOutlineIconButton.propTypes = {
    onClick: PropTypes.func.isRequired
}
