import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import {Box} from '@material-ui/core'

const LIST_WARNING_USE_FILTER_MSG
    = 'Apply at least 1 filter to load more records'
export const FiltersWarningMessage = () => (
    <Box p="1em" display="flex" justifyContent="center">
        <InfoIcon color="primary"/>
        <span style={filterNoticeParagragh}>{LIST_WARNING_USE_FILTER_MSG}</span>
    </Box>
)

const filterNoticeParagragh = {
    fontWeight: 'bold',
    color: '#3f51b5',
    padding: '2px'
}

const FAILED_UPLOAD_TO_PARTNER_MSG = 'Failed uploads to partners creative(s)'
export const FailedCreativesUploadMessage = () => (
    <Box p="1em" display="flex" justifyContent="center">
        <ErrorOutline/>
        <span style={errorNoticeParagragh}>{FAILED_UPLOAD_TO_PARTNER_MSG}</span>
    </Box>
)

const errorNoticeParagragh = {
    fontWeight: 'bold',
    color: 'red',
    padding: '2px'
}
