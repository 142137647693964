import React from 'react'
import {
    TextInput,
    ReferenceArrayInput,
    Create,
    SimpleForm,
    required,
    SelectArrayInput,
    SaveButton,
    useRedirect,
    translate,
    Toolbar,
    Edit,
    email
} from 'react-admin'
import Button from '@material-ui/core/Button'
import {PropertySelect} from '../../variation/components/property-select'

const validateEmail = [required(), email()]

export const UserCreateForm = props => (
    <Create {...props} className="drawer-form">
        <SimpleForm title="Add user" toolbar={<UserFormToolbar/>}>
            <TextInput source="email" validate={validateEmail}/>
            <ReferenceArrayInput
                source="roles"
                reference="roles"
                label="Roles"
            >
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
)

export const UserEditForm = props => (
    <Edit {...props} className="drawer-form">
        <SimpleForm title="Update user roles" toolbar={<UserFormToolbar/>}>
            <TextInput source="email" validate={validateEmail}/>
            <PropertySelect
                allowMultipleSelection
                showLoading
                resource="roles"
                validate={required()}
                label="Roles"
                source="roles"
                optionValue="id"
                optionText="name"
            />
        </SimpleForm>
    </Edit>
)

const UserFormToolbar = translate(({translate, ...props}) => {
    const redirect = useRedirect()
    return (
        <Toolbar {...props} className="drawer-action-btns">
            <SaveButton/>
            <>
                <Button variant="contained" onClick={() => redirect('/users')}>
                    {translate('ra.action.cancel')}
                </Button>
            </>
        </Toolbar>
    )
})
