import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import {GetApp} from '@material-ui/icons'
import {Button, IconButton} from '@material-ui/core'
import Storage from '@aws-amplify/storage'
import FileSaver from 'file-saver'
import i18n from '../../app-configs/i18n'
import {usePermissions, useNotify, useListContext} from 'react-admin'
import {hasDownloadPermission} from '../../app-configs/auth/utils'
import {generateZipFile} from '../utils/url-zipper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconHide from '@material-ui/icons/VisibilityOff'
import dataProvider from '../../app-configs/data-provider/data-provider'

const {generateCreativeFileName} = require('../../creatives/util/utils')

const forceDownload = async (creative, variation) => {
    const {key} = creative
    const downloadAs = generateCreativeFileName(creative, variation)
    Storage.get(key, {download: true}).then(({Body, ContentType}) => {
        const blob = new Blob([Body], {type: ContentType})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = downloadAs
        link.click()
    })
}

export const DownloadButton = ({creative, record}) => {
    const {permissions} = usePermissions()
    return hasDownloadPermission(permissions) ? (
        <IconButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
                forceDownload(creative, record)
            }}
        >
            <GetApp/>
        </IconButton>
    ) : null
}

DownloadButton.propTypes = {
    creative: PropTypes.object,
    record: PropTypes.object
}

DownloadButton.defaultProps = {
    creative: undefined,
    record: undefined
}

export const DownloadAll = ({record, showOnlyIcon}) => {
    const variation = {
        id: record.id,
        name: record.name,
        concept: record.concept,
        hierarchy: record.hierarchy
    }
    const [showDialog, setShowDialog] = useState(false)
    const [downValue, setDownValue] = useState('')
    const {permissions} = usePermissions()
    const notify = useNotify()
    const variationFolderName = record.fullName || 'variation'
    const onDownloadAllClick = async () => {
        setShowDialog(true)
        const files = await Promise.all(
            record.creatives.map(async creative => {
                if (!creative.platforms) {
                    const {data} = await dataProvider.getMany('platforms', {
                        filter: {creativeId: creative.id},
                        sort: {
                            field: 'name',
                            order: 'ASC'
                        },
                        range: [0, 49],
                        pagination: {
                            page: 1,
                            perPage: 0
                        }
                    })
                    creative.platforms = data
                }

                const downloadAs = generateCreativeFileName(creative, variation)
                // const fileUrl = await findOrAddCache(creative.key)
                // return {url: fileUrl, name: downloadAs, key: creative.key}
                return {name: downloadAs, key: creative.key}
            })
        )
        downloadAllCreatives(
            files,
            notify,
            setDownValue,
            setShowDialog,
            variationFolderName
        )
    }

    return hasDownloadPermission(permissions) ? (
        <DownloadCreativesZipFolder
            primaryText={showOnlyIcon ? '' : 'CREATIVES'}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            downValue={downValue}
            onDownloadAllClick={onDownloadAllClick}
        />
    ) : null
}

DownloadAll.propTypes = {
    creatives: PropTypes.array,
    record: PropTypes.object,
    showOnlyIcon: PropTypes.bool
}

DownloadAll.defaultProps = {
    creatives: undefined,
    record: undefined
}

const DownloadCreativesZipFolder = ({
    onDownloadAllClick,
    primaryText,
    showDialog,
    setShowDialog,
    downValue
}) => (
    <div>
        <Button
            color="primary"
            startIcon={<GetApp/>}
            onClick={onDownloadAllClick}
        >
            {primaryText}
        </Button>
        <Dialog
            fullWidth
            open={showDialog}
            onClose={() => setShowDialog(false)}
        >
            <DialogTitle>Downloading Creatives</DialogTitle>
            <DialogContent>
                <div>{downValue}</div>
            </DialogContent>
            <DialogActions style={{display: 'block'}}>
                <Button
                    style={{float: 'right'}}
                    color="primary"
                    startIcon={<IconHide/>}
                    onClick={() => setShowDialog(false)}
                >
                    HIDE DIALOG
                </Button>
                <div
                    style={{
                        color: 'gray',
                        fontSize: '12px',
                        marginLeft: '18px',
                        marginTop: '12px'
                    }}
                >
                    Hiding this dialog wont effect the download process
                </div>
            </DialogActions>
        </Dialog>
    </div>
)

DownloadCreativesZipFolder.propTypes = {
    onDownloadAllClick: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired,
    setShowDialog: PropTypes.func.isRequired,
    downValue: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired
}

const downloadAllCreatives = (
    files,
    notify,
    setDownValue,
    setDialog,
    folderName
) => {
    notify(i18n.translate('creative.downloading'), 'info')
    setDownValue(i18n.translate('creative.prepareForDownload'))
    const onSuccessCallback = (content, zipName) => {
        FileSaver.saveAs(content, zipName)
        notify(i18n.translate('creative.downloadSuccess'), 'info')
        setDownValue(i18n.translate('creative.downloadSuccess'))
        setDialog(false)
    }

    const onUpdateCallback = value => {
        setDownValue(`Download ${value}`)
    }

    const onFailureCallback = () => {
        notify(i18n.translate('creative.downloadError'), 'warning')
        setDownValue(i18n.translate('creative.downloadFailedMessage'))
    }

    generateZipFile(
        files,
        folderName,
        onSuccessCallback,
        onUpdateCallback,
        onFailureCallback
    )
}

export const DownloadSelectedCreatives = () => {
    const [showDialog, setShowDialog] = useState(false)
    const [downValue, setDownValue] = useState('')
    const notify = useNotify()

    const {data, selectedIds} = useListContext()
    const selectedCreatives = selectedIds.map(id => data[id])

    const onDownloadAllClick = async () => {
        setShowDialog(true)

        // todo  group creatives by variation id so we can create subfolders in the zip grouping them  (even though they are anyway ordered by name and starting with variation id)
        const files = selectedCreatives.map(creative => {
            const downloadAs = generateCreativeFileName(
                creative,
                creative.variations[0] // TODO STP-2134 - check if we can change the association between the creative and variation to fix this
            )
            return {name: downloadAs, key: creative.key}
        })

        downloadAllCreatives(
            files,
            notify,
            setDownValue,
            setShowDialog,
            'Creatives'
        )
    }

    return (
        <DownloadCreativesZipFolder
            primaryText={i18n.translate('creative.downloadSelected')}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            downValue={downValue}
            onDownloadAllClick={onDownloadAllClick}
        />
    )
}
