import compose from 'recompose/compose'
import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {getUser} from '../../login/selectors/user-selector'

const UserIcon = ({picture, classes}) =>
    picture ? (
        <Avatar className={classes.avatar} src={picture}/>
    ) : (
        <AccountCircle/>
    )

const style = {
    avatar: {
        height: 30,
        width: 30
    }
}
const mapStateToProps = state => ({
    picture: getUser(state) && getUser(state).picture
})
const enhance = compose(connect(mapStateToProps, null), withStyles(style))

UserIcon.propTypes = {
    picture: PropTypes.string,
    classes: PropTypes.object.isRequired
}

UserIcon.defaultProps = {
    picture: undefined
}
export default enhance(UserIcon)
