import React from 'react'
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    usePermissions
} from 'react-admin'
import {PropTypes} from 'prop-types'
import {hasEditPermission} from '../app-configs/auth/utils'
import {Filters} from './game-filter'
import {
    ListPagination,
    DEFAULT_PER_PAGE_PAGINATION
} from '../shared/list-pagination'

export const ListGames = ({basePath, ...props}) => {
    const {permissions} = usePermissions()
    return (
        <List
            {...props}
            basePath={basePath}
            filters={<Filters/>}
            bulkActionButtons={false}
            perPage={DEFAULT_PER_PAGE_PAGINATION}
            pagination={<ListPagination/>}
        >
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField
                    source="isActiveBrand"
                    label="Active Brand"
                    sortable={false}
                />
                {hasEditPermission(permissions) && (
                    <EditButton label="ra.action.edit"/>
                )}
            </Datagrid>
        </List>
    )
}

ListGames.propTypes = {
    basePath: PropTypes.string
}
