import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import {List, CreateButton, usePermissions} from 'react-admin'
import {Aside} from './aside'
import VariationCard from './variation-card'
import {hasUploadPermission} from '../../app-configs/auth/utils'
import {ListPagination} from '../../shared/list-pagination'
import {FiltersWarningMessage} from '../../shared/warning-message'
import {VARIATIONS_LIST_PER_PAGE} from '../../app-configs/data-provider/constants'
import {
    isObjectEmpty,
    getUserPerPageCache
} from '../../app-configs/data-provider/utils'
import {CardSizeSlider, getInitiatedScale} from './card-size-slider'
import {VariationFilterForm} from './list-filter-form'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right'
}

const PostActions = ({
    resource,
    filters,
    displayedFilters,
    filterValues,
    basePath,
    showFilter
}) => {
    const {permissions} = usePermissions()
    return (
        <CardActions style={cardActionStyle}>
            {/* todo eventually put here the buttons of Reset Filters and Apply Filter Search  */}
            {filters
                && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    basePath,
                    context: 'button'
                })}
            {hasUploadPermission(permissions) && (
                <CreateButton basePath={basePath}/>
            )}
        </CardActions>
    )
}

PostActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    displayedFilters: PropTypes.any,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    resource: PropTypes.string.isRequired,
    showFilter: PropTypes.func
}

const VariationGrid = ({ids, data, ...props}) => {
    const [selectedScale, setSelectedScale] = useState(getInitiatedScale())

    return (
        <div>
            {isObjectEmpty(props.filterValues) && <FiltersWarningMessage/>}
            <div>
                <CardSizeSlider
                    setSelectedScale={setSelectedScale}
                    selectedScale={selectedScale}
                />
            </div>
            <div className="cards-grid-container">
                {ids.map(id => (
                    <VariationCard
                        key={`variation-${id}`}
                        record={data[id]}
                        id={id}
                        currentScale={selectedScale}
                        {...props}
                    />
                ))}
            </div>
        </div>
    )
}

VariationGrid.propTypes = {
    data: PropTypes.object,
    ids: PropTypes.array,
    selectedId: PropTypes.number,
    currentScale: PropTypes.number,
    setSelectedScale: PropTypes.func,
    toggleItem: PropTypes.func.isRequired,
    filterValues: PropTypes.object
}

VariationGrid.defaultProps = {
    selectedId: undefined,
    data: undefined,
    ids: [],
    filterValues: {}
}

export const VariationList = props => {
    const [selectedItemId, setSelectedItemId] = useState(undefined)
    const toggleItem = id =>
        selectedItemId === id ? setSelectedItemId() : setSelectedItemId(id)
    // eslint-disable-next-line no-unused-vars
    const {hasShow, hasEdit, hasList, filterProps, location} = props

    let title = 'Variations List'
    let archived = false

    const [, stringURLParameters] = window.location.hash.split('?')
    const parametersURL = new URLSearchParams(stringURLParameters)
    const urlFilter = JSON.parse(parametersURL.get('filter'))
    if (urlFilter && urlFilter.isArchivedMode) {
        title = 'Archived Variations List'
        archived = true
    }

    return (
        <div style={{display: 'flex', marginRight: selectedItemId ? 600 : 0}}>
            <List
                {...props}
                title={title}
                bulkActionButtons={false}
                perPage={getUserPerPageCache(VARIATIONS_LIST_PER_PAGE)}
                pagination={<ListPagination/>}
                actions={<PostActions {...props}/>}
                sort={{field: 'createdAt', order: 'DESC'}}
                filters={<VariationFilterForm/>}
            >
                <VariationGrid
                    selectedId={selectedItemId}
                    toggleItem={toggleItem}
                    isArchivedMode={archived}
                />
            </List>
            <Aside
                selectedItemId={selectedItemId}
                setSelectedItemId={setSelectedItemId}
                isArchivedMode={archived}
            />
        </div>
    )
}

VariationList.propTypes = {
    hasShow: PropTypes.bool,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    filterProps: PropTypes.object,
    location: PropTypes.object
}
