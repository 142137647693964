import React from 'react'
import {
    List,
    TextField,
    Datagrid,
    CardActions,
    usePermissions,
    useRedirect,
    CreateButton
} from 'react-admin'
import PropTypes from 'prop-types'
import {Route} from 'react-router'
import {Drawer} from '@material-ui/core'
import {RoleCreateForm, RoleEditForm} from './role-form'
import {isStpRole} from '../../app-configs/auth/utils'
import {
    ListPagination,
    DEFAULT_PER_PAGE_PAGINATION
} from '../../shared/list-pagination'
import {Filters} from '../../shared/filter-name'

export const RoleList = props => {
    const redirect = useRedirect()
    const onClose = () => redirect('/roles')
    const {permissions} = usePermissions()

    return (
        <>
            <List
                {...props}
                filters={<Filters/>}
                pagination={<ListPagination/>}
                perPage={DEFAULT_PER_PAGE_PAGINATION}
                bulkActionButtons={false}
                actions={<PostActions {...props}/>}
            >
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="name"/>
                </Datagrid>
            </List>
            {isStpRole(permissions) && (
                <Route path="/roles/create">
                    {({match}) => (
                        <Drawer
                            open={Boolean(match)}
                            anchor="right"
                            onClose={onClose}
                        >
                            <RoleCreateForm {...props}/>
                        </Drawer>
                    )}
                </Route>
            )}
            {isStpRole(permissions) && (
                <Route path="/roles/:id">
                    {({match}) => {
                        const isMatch
                            = match
                            && match.params
                            && match.params.id !== 'create'

                        return (
                            <Drawer
                                open={isMatch || false}
                                anchor="right"
                                onClose={onClose}
                            >
                                {isMatch ? (
                                    <RoleEditForm
                                        id={isMatch ? match.params.id : null}
                                        {...props}
                                    />
                                ) : (
                                    <div className="drawer-form"/>
                                )}
                            </Drawer>
                        )
                    }}
                </Route>
            )}
        </>
    )
}

const PostActions = ({
    resource,
    filters,
    displayedFilters,
    filterValues,
    basePath,
    showFilter
}) => {
    const {permissions} = usePermissions()
    return (
        <CardActions>
            {filters
                && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    basePath,
                    context: 'button'
                })}
            {isStpRole(permissions) && <CreateButton basePath={basePath}/>}
        </CardActions>
    )
}

PostActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    displayedFilters: PropTypes.any,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    resource: PropTypes.string.isRequired,
    showFilter: PropTypes.func
}
