import dataProvider from '../data-provider/data-provider'

let fbData
let playLists
let FB_GAME_ACCOUNT
let YT_GAME_PLAYLIST

const parameters = {
    filter: {},
    pagination: {page: 1, perPage: 50},
    sort: {field: 'id', order: 'ASC'}
}
const fetchPartnerAccountList = partner => {
    parameters.filter = {partner}
    return dataProvider
        .getList('partneraccounts', parameters)
        .then(({data}) => JSON.parse(data))
}

export const initPartnersAccounts = async () => {
    if (!fbData) {
        const data = await fetchPartnerAccountList('facebook')
        fbData = data
        createFbGameAccountMap()
    }

    if (!playLists) {
        const data = await fetchPartnerAccountList('youtube')
        playLists = data.playLists
        createYtGamePlaylistMap()
    }
}

const createFbGameAccountMap = () => {
    if (FB_GAME_ACCOUNT) {
        return
    }

    FB_GAME_ACCOUNT = new Map()
    Object.keys(fbData).forEach(key => {
        FB_GAME_ACCOUNT.set(key.toString(), fbData[key])
    })
}

export const getFbGameAccounts = gameId =>
    gameId ? FB_GAME_ACCOUNT.get(gameId.toString()) : []
export const getYtPlaylist = gameId =>
    gameId ? YT_GAME_PLAYLIST.get(gameId.toString()) : []

const BreakException = {}
export const getFbGameDescriptionByAccountID = accId => {
    let description = 'NA'
    FB_GAME_ACCOUNT.forEach(value => {
        try {
            const descriptions = value
                .map(({accountId, description}) =>
                    accountId === accId ? description : undefined
                )
                .filter(Boolean)

            if (descriptions && descriptions[0]) {
                description = descriptions[0]
                throw BreakException
            }
        } catch (error) {
            if (error !== BreakException) {
                throw error
            }
        }
    })

    return description
}

export const getYtPlayListName = accId => {
    for (const pl of YT_GAME_PLAYLIST.values()) {
        if (pl.plId === accId) {
            return pl.plName
        }
    }

    return 'NA'
}

const createYtGamePlaylistMap = () => {
    if (YT_GAME_PLAYLIST) {
        return
    }

    YT_GAME_PLAYLIST = new Map()
    Object.keys(playLists).forEach(key => {
        YT_GAME_PLAYLIST.set(key.toString(), playLists[key])
    })
}
