import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    SaveButton,
    DeleteButton,
    Toolbar, required, SelectInput, ReferenceInput
} from 'react-admin'
import PropTypes from 'prop-types'
import {ConceptAutoCompleteInput} from '../variation/components/property-select'
import {isPathConcepts} from './list'

export const TagEdit = props => {
    const tagResource = props.resource
    const isSubBranch = tagResource === 'subBranches'

    const isPathConcept = isPathConcepts(props.basePath)
    // TODO add validate tag name alreadyExist
    const inputComponent = isPathConcept ? (
        <ConceptAutoCompleteInput {...props}/>
    ) : (
        <div>
            <TextInput source="name"/>
            {
                isSubBranch && (
                    <ReferenceInput
                        label="Branch"
                        source="branchId"
                        reference="branches"
                        validate={required()}
                        perPage={0}
                    >
                        <SelectInput optionText="name"/>
                    </ReferenceInput>)
            }
        </div>
    )
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<TagToolbar/>}>
                <TextField
                    disabled
                    source="id"
                    className="read-only-text-Field"
                />
                {inputComponent}
            </SimpleForm>
        </Edit>
    )
}

TagEdit.propTypes = {
    basePath: PropTypes.string,
    resource: PropTypes.string
}

TagEdit.defaultProps = {
    basePath: '',
    resource: ''
}

const TagToolbar = props => {
    const {basePath} = props
    const body = isPathConcepts(basePath) ? (
        <SaveButton {...props}/>
    ) : (
        <div className="tag-form-toolbar">
            <SaveButton {...props}/>
            <DeleteButton {...props}/>
        </div>
    )

    return <Toolbar {...props}>{body}</Toolbar>
}

TagToolbar.propTypes = {
    props: PropTypes.object.isRequired,
    basePath: PropTypes.string
}

TagToolbar.defaultProps = {
    basePath: ''
}
