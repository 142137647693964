import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
    Create,
    FormTab,
    SimpleFormIterator,
    TextInput,
    required,
    ArrayInput,
    TabbedForm,
    Labeled,
    useGetList
} from 'react-admin'
import UploadArea from './upload/upload-component'
import MultipleUpload from './upload/multiple-upload'
import {
    AutocompletePropertySelect,
    ConceptAutoCompleteInput,
    PropertySelect
} from './property-select'
import {PrepareToolBar} from './variation-toolbar'
import {
    validateCreative,
    validateName
} from './input-validations'
import {CustomTagsAutoCompleteInput} from './multi-custom-tags-component'
import {CreativeValidation, ForkedFrom} from './shared-components'
import {Branch} from './branch'
const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '38px'},
    inlineBlock2X: {display: 'inline-flex', marginRight: '10px'}
})

const getRandomInt = (max = 1000) => Math.floor(Math.random() * Math.floor(max))

const initConcept = `concept ${getRandomInt()}`
const initVariationName = `var ${getRandomInt()}`
export const VariationCreate = props => {
    const initialValues
        = process.env.NODE_ENV === 'development'
            ? {
                colorSchemes: [4, 5, 7],
                description: 'multiple resolutions default',
                concept: initConcept,
                contents: [2],
                name: initVariationName,
                classifications: 1,
                motivations: 1,
                moods: [19, 23],
                themes: [2]
            }
            : {}
    const classes = useStyles()
    const languageProps = useGetList(
        'languages',
        {page: 1, perPage: 200},
        {field: 'name', order: 'ASC'}
    )
    return (
        <Create {...props} title="Upload variation">
            <TabbedForm
                toolbar={<PrepareToolBar/>} // TODO there is a bug on react-admin whenever calling create with with values, check https://bit.ly/3mw1dO0
                /* warnWhenUnsavedChanges */ initialValues={initialValues}
                redirect="list"
                submitOnEnter={false}
                validate={validateCreative}
            >
                <FormTab label="General Info">
                    <PropertySelect
                        resource="variations"
                        validate={required()}
                        label="Game"
                        source="games"
                        optionValue="id"
                        optionText="name"
                        filter={{isActiveBrand: true}}
                    />
                    <ForkedFrom/>

                    <ConceptAutoCompleteInput/>

                    <TextInput
                        fullWidth
                        resource="variations"
                        source="name"
                        label="Variation Name"
                        validate={validateName}
                    />
                    <TextInput
                        fullWidth
                        resource="variations"
                        source="description"
                        label="Source Files"
                    />
                </FormTab>
                <FormTab label="Tags">
                    <CustomTagsAutoCompleteInput/>

                    <AutocompletePropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock2X}
                        resource="variations"
                        validate={required()}
                        label="Theme"
                        source="themes"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        formClassName={classes.inlineBlock}
                        resource="variations"
                        validate={required()}
                        label="Execution Style"
                        source="styles"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        formClassName={classes.inlineBlock}
                        resource="variations"
                        validate={required()}
                        label="Classification"
                        source="classifications"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        formClassName={classes.inlineBlock}
                        resource="variations"
                        validate={required()}
                        label="Motivation"
                        source="motivations"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock2X}
                        resource="variations"
                        validate={required()}
                        label="Mood"
                        source="moods"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock}
                        resource="variations"
                        label="Color Scheme"
                        source="colorSchemes"
                        optionValue="id"
                        optionText="name"
                    />

                    <AutocompletePropertySelect
                        allowMultipleSelection
                        formClassName={classes.inlineBlock2X}
                        resource="variations"
                        validate={required()}
                        label="Content"
                        source="contents"
                        optionValue="id"
                        optionText="name"
                    />
                    <Branch formClassName={classes.inlineBlock} targetSource="branches"/>
                </FormTab>
                <FormTab label="Creatives">
                    <CreativeValidation/>
                    <Labeled label="Creatives multiple upload"/>
                    <MultipleUpload languageProps={languageProps}/>
                    <ArrayInput
                        source="creatives"
                        label="Creative signle upload"
                    >
                        <SimpleFormIterator>
                            <UploadArea languageProps={languageProps}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}
