import React, {useState} from 'react'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {
    translate,
    useNotify,
    usePermissions,
    useDataProvider
} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import PropTypes from 'prop-types'
import {getUser} from '../../login/selectors/user-selector'
import {Button} from '@material-ui/core'
import {hasEditPermission} from '../../app-configs/auth/utils'
import {ListPartnerUploadFailures} from '../../uploads/components/list-partner-upload-failures'
import {ListSubscription} from '../../subscription/list'
import {SharedLinksList} from '../../creatives/components/sharedlinks-list'
import {ValidateCreateYtToken} from './validate-create-yt-token'

const EnvironmentCard = () =>
    process.env.REACT_APP_ENVSTAGE !== 'production' && (
        <div>{`Environment: ${process.env.REACT_APP_ENVSTAGE}`}</div>
    )

const UPDATING = 'Updating…'
const SYNC_GAMES_BTN_TEXT = 'Sync Games with DWH'

const DashboardComponent = ({translate, username, role}) => {
    const notify = useNotify()
    const {permissions} = usePermissions()
    const dataProvider = useDataProvider()

    const [syncGamesBtnTitle, setSyncGamesBtnTitle] = useState(
        SYNC_GAMES_BTN_TEXT
    )

    return (
        <Card>
            <CardHeader
                title={translate('dashboard.welcome.title')}
                subheader={<EnvironmentCard/>}
            />
            <CardContent>
                <div>
                    {translate('dashboard.welcome.message', {name: username})}
                </div>
                <div>{translate('dashboard.welcome.role', {role})}</div>
                {hasEditPermission(permissions) && (
                    <>
                        <br/>
                        <div>
                            <Button
                                variant="outlined"
                                style={{marginLeft: '30px'}}
                                disabled={syncGamesBtnTitle === UPDATING}
                                onClick={() => {
                                    triggerGameSync(
                                        setSyncGamesBtnTitle,
                                        notify,
                                        dataProvider
                                    )
                                }}
                            >
                                {syncGamesBtnTitle}
                            </Button>
                        </div>
                    </>
                )}
                <ValidateCreateYtToken/>
                <SharedLinksList/>
                <ListSubscription/>
                <ListPartnerUploadFailures/>
            </CardContent>
        </Card>
    )
}

DashboardComponent.propTypes = {
    translate: PropTypes.func,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
}

DashboardComponent.defaultProps = {
    translate
}

const triggerGameSync = async (setSyncGamesBtnTitle, notify, dataProvider) => {
    notify(
        'The synchronized process of CLB Game table with DWH - [STARTED]',
        'info'
    )
    setSyncGamesBtnTitle(UPDATING)

    const errorHandler = message => {
        let errorMessage = 'The synchronized process of CLB Game table with DWH Game Table - [FAILED]'
        if (process.env.REACT_APP_ENVSTAGE !== 'production') {
            errorMessage = errorMessage.concat(`:${message}`)
        }

        notify(
            errorMessage,
            'error'
        )
        setSyncGamesBtnTitle(SYNC_GAMES_BTN_TEXT)
    }

    dataProvider
        .getOne('sync', {id: 'DWH'})
        .then(() => {
            notify(
                'CLB Game table has been synchronized with DWH Game Table - [SUCCESS]',
                'info'
            )
            setSyncGamesBtnTitle(SYNC_GAMES_BTN_TEXT)
        })
        .catch(error => {
            errorHandler(error.message)
        })
}

const mapStateToProps = state => {
    const user = getUser(state)
    return {
        username: (user && user.name) || 'Unknown User',
        role: getMainRole(user) || 'Unknown Role'
    }
}

const getMainRole = user => {
    if (user && user.groups) {
        return user.groups.toString()
    }
}

const enhance = compose(translate, connect(mapStateToProps))

export const Dashboard = enhance(DashboardComponent)
