import Amplify from 'aws-amplify'

if (!process.env.LOG_LEVEL || process.env.LOG_LEVEL === 'DEBUG') {
    Amplify.Logger.LOG_LEVEL = 'DEBUG'
}

// fixme  could not find way to access Amplify configuration directly from Amplify instance
export const BUCKET = {
    bucket: `clb-media-${process.env.REACT_APP_ENVSTAGE}`,
    region: 'eu-west-1',
    identityPoolId: process.env.REACT_APP_identityPoolId
}

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
        mandatorySignIn: true,
        oauth: {
            domain: process.env.REACT_APP_SOCIAL_LOGIN_HOSTEDUI_DOMAIN,
            scope: [
                'phone',
                'email',
                'aws.cognito.signin.user.admin',
                'openid',
                'profile'
            ], // aws.cognito.signin.user.admin IS VERY IMPORTANT AS SCOPE ( IN COGNITO USERPOOL CONFIG TOO ) to read the attributes ( name / email / picture)
            redirectSignIn: process.env.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL_IN,
            redirectSignOut:
                process.env.REACT_APP_SOCIAL_LOGIN_REDIRECT_URL_OUT,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    // TODO: create bucket in backend
    Storage: BUCKET
})
