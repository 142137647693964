import React from 'react'
import LinkIcon from '@material-ui/icons/Link'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {List, TextField, Datagrid, DateField, useNotify} from 'react-admin'
import {PropTypes} from 'prop-types'
import {ListPagination} from '../../shared/list-pagination'

export const SharedLinksList = props => {
    const notify = useNotify()
    return (
        <>
            <div className="subs-list-title">
                <LinkIcon/>
                <span className="subs-list-header">
                    <b>Shared links</b>
                </span>
            </div>
            <List
                {...props}
                hasList
                resource="sharedlinks"
                basePath="/sharedlinks"
                actions={false}
                perPage={50}
                pagination={<ListPagination/>}
                empty={<></>}
                hasCreate={false}
                hasEdit={false}
                hasShow={false}
                bulkActionButtons={false}
            >
                <Datagrid>
                    <TextField source="name" sortable={false}/>
                    <TextField source="link" sortable={false}/>
                    <CopyToClipboard notify={notify}/>
                    <DateField showTime source="createdAt" sortable={false}/>
                </Datagrid>
            </List>
        </>
    )
}

const CopyToClipboard = ({record, notify}) => {
    const onClick = () => {
        try {
            navigator.clipboard.writeText(record.link)
            notify('copied to clipboard', 'info')
        } catch (error) {
            console.error('failed to copy to clipboard', {reason: error})
            notify('failed to copy text to clipboard', 'error')
        }
    }

    return <FileCopyIcon style={{cursor: 'pointer'}} onClick={onClick}/>
}

CopyToClipboard.propTypes = {
    record: PropTypes.object,
    notify: PropTypes.func.isRequired
}
