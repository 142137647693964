import {withStyles} from '@material-ui/core/styles'
import {Filter, TextInput} from 'react-admin'
import React from 'react'

const filterStyles = () => ({
    mainFilter: {
        padding: '5px'
    },
    filterNode: {
        marginLeft: '-50px'
    }
})

export const Filters = withStyles(filterStyles)(props => {
    const {
        classes,
        resource,
        defaultTitle,
        source = 'q',
        label = 'Name',
        ...rest
    } = props
    return (
        <Filter {...rest} className={classes.mainFilter}>
            <TextInput
                alwaysOn
                resource={resource}
                label={label}
                source={source}
                className="filter-text-style"
            />
        </Filter>
    )
})
