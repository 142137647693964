import React from 'react'
import {MenuItemLink, usePermissions} from 'react-admin'
import PropTypes from 'prop-types'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import {SubMenuUIComponent} from '../../layout/components/sub-menu'
import {hasUserMgmtPermission} from '../../app-configs/auth/utils'

const endpoints = [
    {to: '/users', primaryText: 'Users', icon: <GroupAddIcon/>},
    {to: '/roles', primaryText: 'Roles', icon: <LockOpenIcon/>}
]

const UserMgmtMenu = ({onMenuClick, handleToggle, state, open}) => {
    const {permissions} = usePermissions()

    return hasUserMgmtPermission(permissions) ? (
        <SubMenuUIComponent
            handleToggle={() => handleToggle('userMgmt')}
            isOpen={state.userMgmt}
            sidebarIsOpen={open}
            name="User Mgmt"
            icon={<PeopleAltIcon/>}
        >
            {endpoints.map(({to, primaryText, icon}) => (
                <MenuItemLink
                    key={primaryText}
                    to={to}
                    primaryText={primaryText}
                    leftIcon={icon}
                    onClick={onMenuClick}
                />
            ))}
        </SubMenuUIComponent>
    ) : null
}

UserMgmtMenu.propTypes = {
    handleToggle: PropTypes.func,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    state: PropTypes.object
}

UserMgmtMenu.defaultProps = {
    handleToggle: undefined,
    onMenuClick: undefined,
    open: undefined,
    state: undefined
}

export default UserMgmtMenu
