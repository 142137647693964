import React from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

export const Player = ({src}) => (
    <ReactPlayer
        controls
        url={src}
        playing={false}
        style={{marginTop: '50px'}}
    />
)

Player.propTypes = {
    src: PropTypes.string.isRequired
}
