import getUid from './uuid-provider'
import {getTypes} from '../../variation/components/file-type-icon'
import readMetadata from '../../variation/components/read-metadata'
import {uploadThumbFileToS3} from '../s3-provider'

export const prepareForCreate = async data => {
    const variation = {...data}

    variation.id = getUid()
    variation.type = 'VARIATION'
    variation.creatives = prepareCreatives(variation)
    if (variation.creatives[0] && variation.creatives[0].file) {
        variation.thumbnailKey = await uploadThumbFileToS3(variation.creatives[0])
    }

    delete variation.activeUploadCount
    delete variation.multipleUploads
    delete variation.multipleUploadsLanguageId

    return variation
}

const ORIENTATION_MAP = {
    Portrait: 1,
    Landscape: 2,
    Square: 3
}

const prepareCreatives = ({creatives}) =>
    creatives.map(c => {
        if (!c.uploadedFile) {
            return c
        }

        const file = c.uploadedFile
        if (file.Orientation && typeof file.Orientation !== 'number') {
            file.Orientation = ORIENTATION_MAP[file.Orientation]
        }

        delete c.uploadedFile

        return {
            ...c,
            ...file
        }
    })

export const prepareForUpdate = async ({data, previousData}) => {
    data.creatives = prepareCreatives(data)
    const {creatives} = data
    const generateNewThumb
        = data.creatives[0].key !== previousData.creatives[0].key
    if (generateNewThumb) {
        data.thumbnailKey
            = (await uploadThumbFileToS3(creatives[0])) || data.thumbnailKey
    }

    delete data.activeUploadCount

    return data
}

export const getFileTypes = ({data, ...rest}) => {
    const itemsWithFileTypes = data.map(item => {
        const {creatives} = item
        const fileTypes = getTypes(creatives.map(({fileTypeId}) => fileTypeId))

        return {
            ...item,
            fileTypes
        }
    })

    return {
        ...rest,
        data: itemsWithFileTypes
    }
}

export const extractCreativeInfo = async (
    rawFile,
    downloadAs = null,
    key = null
) => {
    const meta = await readMetadata(rawFile)
    return {
        file: rawFile,
        downloadAs,
        key,
        ...extractFileInfoToParent(rawFile),
        ...meta
    }
}

// todo  maybe we can completely ignore original filename and just use concept/variation name + extension?
const formatFileName = name => name.replace(/ /g, '').toLowerCase()
//  move this to the create finalform spy whatever so that we have all data structure already created there (together with metadata)
export const extractFileInfoToParent = file => {
    if (!file) {
        return {}
    }

    const {size, type, lastModified, name} = file
    return {
        fileSize: size,
        fileType: type,
        fileLastModified: lastModified,
        filename: formatFileName(name)
    }
}

export const generateCreativeKey = ({filename}, creativeUUID) =>
    [creativeUUID, filename].join('-')

export const getUUIDFromKey = key => (key ? key.split('-').shift() : '')
