import React from 'react'
import {MenuItemLink} from 'react-admin'
import PropTypes from 'prop-types'

import ArchiveIcon from '@material-ui/icons/Archive'

import {SubMenuUIComponent} from '../../layout/components/sub-menu'
// import {CreativesIcon} from '../../creatives/components/icon'
import {VariationsIcon} from '../../variation/components/icon'

const archivedOptions = [
    {
        to: {
            pathname: '/variations',
            search: `filter=${JSON.stringify({isArchivedMode: true})}`
        },
        primaryText: 'Variations',
        icon: <VariationsIcon/>
    }
    // {to: '/creatives', primaryText: 'Creatives', icon: <CreativesIcon />} // creative submenu
]

const ArchivedMenu = ({onMenuClick, handleToggle, state, open}) => {
    const options = archivedOptions.map(({to, primaryText, icon}) => (
        <MenuItemLink
            key={primaryText}
            to={to}
            primaryText={primaryText}
            leftIcon={icon}
            onClick={onMenuClick}
        />
    ))

    return (
        <SubMenuUIComponent
            handleToggle={() => handleToggle('archived')}
            isOpen={state.archived}
            sidebarIsOpen={open}
            name="Archived"
            icon={<ArchiveIcon/>}
        >
            {options}
        </SubMenuUIComponent>
    )
}

export default ArchivedMenu

ArchivedMenu.propTypes = {
    handleToggle: PropTypes.func,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    state: PropTypes.object
}

ArchivedMenu.defaultProps = {
    handleToggle: undefined,
    onMenuClick: undefined,
    open: undefined,
    state: undefined
}
