import React from 'react'
import {
    Edit,
    SimpleForm,
    TextField,
    BooleanInput,
    Toolbar,
    SaveButton
} from 'react-admin'

export const EditGame = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextField label="Id" source="id"/>
            <TextField source="name"/>
            <BooleanInput source="isActiveBrand"/>
        </SimpleForm>
    </Edit>
)

const CustomToolbar = props => (
    <Toolbar>
        {' '}
        <SaveButton {...props}/>
    </Toolbar>
)
