import React from 'react'
import PropTypes from 'prop-types'
import {DashboardMenuItem} from 'react-admin'

export const DashboardMenu = ({onMenuClick}) => (
    <DashboardMenuItem onClick={onMenuClick}/>
)

DashboardMenu.propTypes = {
    onMenuClick: PropTypes.func
}
