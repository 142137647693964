import {Auth} from 'aws-amplify'
import {fetchUtils} from 'react-admin'

export const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'})
    }

    options.user = {
        authenticated: true,
        token: (await Auth.currentSession()).idToken.jwtToken
    }

    return fetchUtils.fetchJson(url, options)
}
