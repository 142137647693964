import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
    FileInput,
    AutocompleteInput,
    ReferenceInput,
    useNotify
} from 'react-admin'
import {Publish} from '@material-ui/icons'
import {useForm} from 'react-final-form'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {uploadMediaFileToS3} from '../../../app-configs/s3-provider'
import {
    incrementActiveUploadCount,
    decrementActiveUploadCount,
    LanguageComponent,
    areLanguagesLoaded
} from './upload-component'
import {AutocompletePropertySelect} from '../property-select'
import {getLanguageId, getLanguageCode} from '../../../creatives/util/utils'

const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '38px'}
})

const MAX_UPLOADS_IN_SINGLE_DROP = 20

const MultipleUpload = props => {
    const {languageProps} = props
    const form = useForm()
    const notify = useNotify()
    const classes = useStyles()
    const [autoUpload, setAutoUpload] = useState(true)
    const autoUploadToggle = event => {
        const {checked} = event.target
        setAutoUpload(checked)
        form.change('multipleSkipAutoUpload', !checked)
    }

    return (
        <div
            className="multiple-upload-options"
            style={{backgroundColor: '#dcd9d9', padding: '10px'}}
        >
            <>
                <FormControlLabel
                    control={
                        <Switch
                            checked={autoUpload}
                            name="switch-checker"
                            color="primary"
                            source="multipleSkipAutoUpload"
                            onChange={autoUploadToggle}
                        />
                    }
                    label="Auto upload to partner"
                    className="filter-form-button"
                />
            </>
            <div className="creative-tag">
                <LanguageComponent
                    {...languageProps}
                    getSource={() => 'multipleUploadsLanguageId'}
                    variant="outlined"
                />

                <ReferenceInput
                    allowEmptylabel="CTA"
                    source="multipleUploadsCtaId"
                    reference="cta"
                    perPage={200}
                    sort={{field: 'name', order: 'ASC'}}
                >
                    <AutocompleteInput
                        allowEmpty
                        optionText="name"
                        emptyText="--reset--"
                        variant="outlined"
                    />
                </ReferenceInput>
                <AutocompletePropertySelect
                    allowMultipleSelection
                    formClassName={classes.inlineBlock}
                    resource="brandings"
                    label="Branding"
                    source="brandings"
                    optionValue="id"
                    optionText="name"
                    formSource="multipleUploadsBrandings"
                    variant="outlined"
                />
                <AutocompletePropertySelect
                    allowMultipleSelection
                    formClassName={classes.inlineBlock}
                    resource="platforms"
                    label="Platform"
                    source="platforms"
                    optionValue="id"
                    optionText="name"
                    formSource="multipleUploadsPlatforms"
                    variant="outlined"
                />

                {
                    areLanguagesLoaded(languageProps)
                    && (
                        <FileInput
                            multiple
                            source="multipleUploads"
                            placeholder={<Publish/>}
                            label={`Creative(s) - max ${MAX_UPLOADS_IN_SINGLE_DROP} files`}
                            accept="image/*, video/*, .zip"
                            options={{
                                onDrop: onDrop(form, notify, languageProps.data)
                            }}
                        >
                            <span/>
                        </FileInput>)
                }
            </div>
        </div>
    )
}

MultipleUpload.propTypes = {
    languageProps: PropTypes.object
}

const onDrop = (form, notify, languages) => async () => {
    const {
        multipleUploads,
        multipleUploadsLanguageId,
        multipleUploadsCtaId,
        multipleUploadsBrandings,
        multipleUploadsPlatforms,
        multipleSkipAutoUpload
    } = form.getState().values

    if (multipleUploads.length > MAX_UPLOADS_IN_SINGLE_DROP) {
        notify(
            `max number of creatives in a single drop is ${MAX_UPLOADS_IN_SINGLE_DROP}, you droped ${multipleUploads.length} creatives!`,
            'error'
        )
        form.change('multipleUploads', undefined)
        return
    }

    incrementActiveUploadCount(form, multipleUploads.length)
    form.change('multipleUploads', undefined)

    const uploadedCreativeFiles = []
    const uploadedCreativesPromises = multipleUploads.map(creative => {
        const file = {
            rawFile: creative.rawFile,
            src: creative.undefined
        }

        return uploadMediaFileToS3({file}, notify).then(data => {
            if (data && data.key) {
                const langCode = getLanguageCode(file.rawFile.name)
                const languageId = getLanguageId(languages)(langCode)
                const creative = {
                    file,
                    uploadedFile: {...data},
                    languageId: languageId || multipleUploadsLanguageId,
                    ctaId: multipleUploadsCtaId,
                    brandings: multipleUploadsBrandings,
                    platforms: multipleUploadsPlatforms,
                    skipAutoUpload: multipleSkipAutoUpload
                }
                uploadedCreativeFiles.push(creative)
            }

            decrementActiveUploadCount(form)
        })
    })

    await Promise.all(uploadedCreativesPromises)
    addCreativesToForm(uploadedCreativeFiles, form)
    resetFormCreativeUpload(form)
}

const addCreativesToForm = (creativesToBeAdded, form) => {
    const {creatives = []} = form.getState().values
    form.change('creatives', [...creatives, ...creativesToBeAdded])
}

const resetFormCreativeUpload = form => {
    form.change('multipleUploadsLanguageId', undefined)
    form.change('multipleUploadsCtaId', undefined)
    form.change('multipleUploadsBrandings', undefined)
    form.change('multipleUploadsPlatforms', undefined)
}

export default MultipleUpload
