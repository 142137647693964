import React from 'react'
import {
    Show,
    SimpleShowLayout,
    EditButton,
    TopToolbar,
    ListButton,
    TextField,
    ImageField
} from 'react-admin'
import PropTypes from 'prop-types'

const Title = ({record}) => <span> Post {record ? `"${record.id}"` : ''}</span>

Title.propTypes = {
    record: PropTypes.object.isRequired
}

const ShowActions = ({data, ...props}) => (
    <TopToolbar>
        <EditButton label="ra.action.edit" record={data} {...props}/>
        <ListButton label="ra.action.list" {...props}/>
    </TopToolbar>
)

ShowActions.propTypes = {
    props: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
}

export const VariationShow = props => (
    <Show title={<Title/>} actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>
            <TextField label="id" source="id"/>
            <TextField label="Concept" source="concept"/>
            <TextField label="variation" source="variation"/>
            <ImageField label="Creative" source="link"/>
        </SimpleShowLayout>
    </Show>
)
