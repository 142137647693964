import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import reportWebVitals from './report-web-vitals'

// eslint-disable-next-line import/extensions
import './index.css'

ReactDOM.render(<App/>, document.querySelector('#root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorker was changed in CreateReactApp - if we will ever need it check out https://create-react-app.dev/docs/making-a-progressive-web-app/
