import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import YouTubeIcon from '@material-ui/icons/YouTube'
import {useDataProvider, useNotify, Loading} from 'react-admin'
import {AuthenticateButton} from '../../creatives/components/authenticate-button'

const useStyles = makeStyles({
    container: {
        margin: '10px 0px',
        backgroundColor: '#d7d0d0bf',
        padding: '7px'
    },
    ytAuthMessage: {color: 'red', marginTop: '0px'},
    permissionMessage: {color: '#6b6464', marginTop: '4px'},
    ytIcon: {
        position: 'absolute',
        marginLeft: '3px',
        marginTop: '-3px'
    },
    authContainer: {margin: '5px 5px'},
    loader: {height: '150px'}
})

export const ValidateCreateYtToken = () => {
    const [validToken, setValidToken] = useState()
    const [loading, setLoading] = useState(true)
    const notify = useNotify()
    const dataProvider = useDataProvider()
    useEffect(() => {
        const validateToken = async () => {
            dataProvider
                .getOne('token', {id: 'youtube'})
                .then(({data}) => {
                    if (data.status === 'valid') {
                        setValidToken(true)
                    } else {
                        notify(data.message, 'error')
                    }

                    setLoading(false)
                })
                .catch(() => {
                    notify('Youtube Token is not Valid', 'error')
                    setValidToken(false)
                    setLoading(false)
                })
        }

        validateToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const classes = useStyles()
    const createToken = async accessCode => {
        dataProvider
            .create('token', {data: {accessCode, partner: 'youtube'}})
            .then(() => {
                notify('Youtube Token Created Successfully', 'info')
                setValidToken(true)
            })
            .catch(() => notify('Unable to create Youtube Token', 'error'))
    }

    if (loading) {
        return <Loading className={classes.loader}/>
    }

    return (
        !validToken && (
            <div className={classes.container}>
                <div className={classes.ytAuthMessage}>
                    *Youtube Authentication is required otherwise Automatic
                    upload to youtube wont work!
                    <YouTubeIcon className={classes.ytIcon}/>
                </div>
                <div className={classes.permissionMessage}>Please authenticate if you have access to MKT youtube channel or ask colleague/lead to do so.</div>
                <div className={classes.authContainer}>
                    <AuthenticateButton
                        validToken={validToken}
                        createToken={createToken}
                    />
                </div>
            </div>
        )
    )
}
