import React from 'react'
import PropTypes from 'prop-types'
import {MenuItemLink} from 'react-admin'
import {GamesIcon} from './icon'

export const GameMenu = ({onMenuClick}) => (
    <MenuItemLink
        to="/games"
        primaryText="Games"
        leftIcon={<GamesIcon/>}
        onClick={onMenuClick}
    />
)

GameMenu.propTypes = {
    onMenuClick: PropTypes.func
}

GameMenu.defaultProps = {
    onMenuClick: undefined
}
