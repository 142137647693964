import Cache from '@aws-amplify/cache'
import {
    VARIATIONS_LIST_PER_PAGE,
    CREATIVES_LIST_PER_PAGE
} from '../../app-configs/data-provider/constants'
import {
    LIST_PER_PAGE_PAGINATION_OPTIONS,
    DEFAULT_PER_PAGE_PAGINATION
} from '../../shared/list-pagination'

export const resourceRequireFilter = resource =>
    resource === 'variations' || resource === 'creatives'

export const isObjectEmpty = object => Object.keys(object).length === 0

export const setUserPerPageCache = (
    resource,
    perPage,
    expires = getTimeAfer1Month()
) => {
    const key = getCacheKeyByResource(resource)
    if (!LIST_PER_PAGE_PAGINATION_OPTIONS.includes(perPage)) {
        perPage = DEFAULT_PER_PAGE_PAGINATION
    }

    return key ? Cache.setItem(key, perPage, {expires}) : undefined
}

export const getUserPerPageCache = resource => {
    const key = getCacheKeyByResource(resource)
    const value = Cache.getItem(key)

    return LIST_PER_PAGE_PAGINATION_OPTIONS.includes(value)
        ? value
        : DEFAULT_PER_PAGE_PAGINATION
}

const getCacheKeyByResource = resource => {
    if (resource === 'variations') {
        return VARIATIONS_LIST_PER_PAGE
    }

    if (resource === 'creatives') {
        return CREATIVES_LIST_PER_PAGE
    }
}

const getTimeAfer1Month = () => {
    const d = new Date()

    return d.setMonth(d.getMonth() + 1)
}

export const addGameIdToGamesList = props => {
    const data = props.data.map(g => ({
        ...g,
        gameId: g.id
    }))

    return {
        ...props,
        data
    }
}

export const setConceptSortFilter = parameters => ({
    ...parameters,
    sort: {
        field: 'name',
        order: 'ASC'
    }
})
