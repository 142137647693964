import React from 'react'
import PropTypes from 'prop-types'
import {MenuItemLink} from 'react-admin'
import {VariationsIcon} from './icon'

export const VariationMenu = ({onMenuClick}) => (
    <MenuItemLink
        to={{
            pathname: '/variations',
            search: 'filter={}'
        }}
        primaryText="Variations"
        leftIcon={<VariationsIcon/>}
        onClick={onMenuClick}
    />
)

VariationMenu.propTypes = {
    onMenuClick: PropTypes.func
}

VariationMenu.defaultProps = {
    onMenuClick: undefined
}
