// Allowed operators are those specified in Sequelize OP documentation
// see https://sequelize.org/master/variable/index.html#static-variable-Op

// listing some of the most used here to provide code hinting and some safety

export const EQUALS = 'eq'
export const GREATER_THAN_EQUALS = 'gte'
export const LOWER_THAN_EQUALS = 'lte'
export const IN = 'in'
export const BETWEEN = 'between'
export const SUBSTRING = 'substring' // -- >  LIKE '%VALUE%'
export const STARTS_WITH = 'startsWith' // --> LIKE 'VALUE%'
