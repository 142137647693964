import React from 'react'
import {SaveButton, Toolbar} from 'react-admin'
import {FormSpy} from 'react-final-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Box} from '@material-ui/core'
import PropTypes from 'prop-types'

const CreateToolbar = ({form, mutationMode, ...props}) => {
    const {activeUploadCount = 0} = form.getState().values
    const body
        = activeUploadCount > 0 ? (
            <Box display="flex" alignItems="center">
                <CircularProgress disableShrink/>
                    &nbsp;&nbsp;
                <span>
                    Uploading media file(s) - {activeUploadCount} file(s)
                    left…
                </span>
            </Box>
        ) : (
            <SaveButton {...props}/>
        )

    return <Toolbar {...props}>{body}</Toolbar>
}

CreateToolbar.propTypes = {
    form: PropTypes.object.isRequired,
    mutationMode: PropTypes.any
}

export const PrepareToolBar = ({mutationMode, ...props}) => (
    <FormSpy>
        {st => <CreateToolbar {...props} form={st.form}/>}
    </FormSpy>
)

PrepareToolBar.propTypes = {
    mutationMode: PropTypes.any
}
