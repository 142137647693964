import {connect} from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import React from 'react'
import PropTypes from 'prop-types'

import {withStyles} from '@material-ui/core/styles'
import {translate, userLogin as userLoginAction} from 'react-admin'
import Icon from './google-icon'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    icon: {marginLeft: theme.spacing(1)},
    input: {display: 'none'}
})

const GoogleButton = ({classes, translate, userLogin}) => (
    <Button
        className={classes.button}
        variant="contained"
        component="span"
        color="primary"
        onClick={userLogin}
    >
        <Icon/>
        {translate('authentication.login.googleButton')}{' '}
    </Button>
)

GoogleButton.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object.isRequired,
    userLogin: PropTypes.func.isRequired
}
GoogleButton.defaultProps = {
    translate
}

const mapDispatchToProps = {
    userLogin: userLoginAction
}

const enhance = compose(
    connect(undefined, mapDispatchToProps),
    translate,
    withStyles(styles)
)
export default enhance(GoogleButton)
