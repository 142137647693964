import React from 'react'
import {PropTypes} from 'prop-types'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '-22px'
    },
    strip: {
        height: '20px',
        width: '100%',
        opacity: '0.7',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '17px',
        fontWeight: 'bold',
        lineHeight: '1',
        wordBreak: 'break-word',
        marginTop: '-5px',
        textAlign: 'center'
    }
})

const retrieveFontSizeByTextLength = (
    text,
    cardTitleWidth = 268,
    fontDefualtSize = 17
) => text
    ? (1 - text.length) / cardTitleWidth * fontDefualtSize
    : fontDefualtSize

export const ImageOverlay = ({record}) => {
    const classes = useStyles()
    const fontSize = retrieveFontSizeByTextLength(record.fullName)
    return (
        <div className={classes.container}>
            <div className={classes.strip} style={{fontSize: `${fontSize}px`}}>
                {record.fullName}
            </div>
        </div>
    )
}

ImageOverlay.propTypes = {
    record: PropTypes.object.isRequired
}

// TODO check if we still ganna need this
/* const truncate = (string, length = 50) =>
    string && string.length > length
        ? string.slice(0, Math.max(0, length - 3)) + '…'
        : string */
