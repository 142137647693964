import React from 'react'
import {useForm} from 'react-final-form'
import {GoogleLogin, GoogleLogout} from 'react-google-login'
import {FormDataConsumer} from 'react-admin'
import {isYoutubeLoggedIn} from './upload-to-partner'
import {
    YT_CLIENT_ID,
    YT_HOSTED_DOMAIN,
    YT_SCOPE
} from '../../app-configs/partners/partner-constants'

export const YoutubeAuthentication = () => {
    const form = useForm()
    return (
        <FormDataConsumer>
            {({formData}) => isYoutubeLoggedIn(formData) ? (
                <>
                    Logged in to Youtube &nbsp;&nbsp;
                    <GoogleLogout
                        clientId={YT_CLIENT_ID}
                        buttonText="Logout"
                        onLogoutSuccess={() => googleLogout(form)}
                    />
                </>
            ) : (
                <GoogleLogin
                    hostedDomain={YT_HOSTED_DOMAIN}
                    redirectUri={`${window.location.origin}/oauth2/idresponse`}
                    clientId={YT_CLIENT_ID}
                    accessType="offline"
                    responseType="code"
                    prompt="consent"
                    scope={YT_SCOPE}
                    onSuccess={data => saveGoogleAccessCode(form, data)}
                    onFailure={googleLoginFailed}
                />
            )}
        </FormDataConsumer>
    )
}

const googleLogout = form => form.change('accessCode', undefined)

const googleLoginFailed = error => console.error('googleLoginFailed', error)

const saveGoogleAccessCode = (form, data) => {
    const accessCode = data && data.code ? data.code : undefined
    form.change('accessCode', accessCode)
}
