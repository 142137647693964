import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import {
    CARD_MAX_SCALE,
    CARD_MIN_SCALE
} from '../../shared/shared-constent-values'
const marks = [
    {
        value: CARD_MIN_SCALE,
        label: 'min'
    },
    {
        value: CARD_MAX_SCALE,
        label: 'max'
    }
]

// TODO handle different card size slider localstorage if needed, this component is used in variations & creatives list
export const CardSizeSlider = ({setSelectedScale, selectedScale}) => {
    const [value, setValue] = useState(selectedScale)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleUp = () => {
        setSelectedScale(value)
        localStorage.setItem('cardScale', value)
    }

    return (
        <div className="card-slider-container">
            <Typography
                gutterBottom
                className="card-slider-title"
                id="non-linear-slider"
            >
                Card Size
            </Typography>
            <Slider
                value={value}
                min={CARD_MIN_SCALE}
                step={0.1}
                marks={marks}
                max={CARD_MAX_SCALE}
                aria-labelledby="non-linear-slider"
                onChange={handleChange}
                onChangeCommitted={handleUp}
            />
        </div>
    )
}

CardSizeSlider.propTypes = {
    setSelectedScale: PropTypes.func,
    selectedScale: PropTypes.number
}

export const getInitiatedScale = () =>
    Number.parseFloat(localStorage.getItem('cardScale')) || 1
