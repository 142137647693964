import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
const ITERATION = 'ITERATION'
const DUPLICATE = 'DUPLICATE'

const useStyles = makeStyles(() => ({
    selectorStyle: {
        color: '#3f51b5',
        fontSize: '14.5px'
    }
}))

export const CloningSelector = ({handleSelector}) => {
    const classes = useStyles()
    const [select, setSelect] = useState()
    const handleChange = event => {
        const {value} = event.target
        setSelect(value)
        if (value) {
            const isIteration = value === ITERATION
            handleSelector(isIteration)
        }
    }

    return (
        <Select
            native
            value={select}
            className={classes.selectorStyle}
            onChange={handleChange}
        >
            <option value="">CLONE MODE</option>
            <option value={DUPLICATE}>{DUPLICATE}</option>
            <option value={ITERATION}>{ITERATION}</option>
        </Select>
    )
}

CloningSelector.propTypes = {
    handleSelector: PropTypes.func
}
