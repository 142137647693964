import {
    Loading,
    SelectInput,
    SelectArrayInput,
    useQuery,
    LinearProgress,
    useInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    useTranslate
} from 'react-admin'
import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import Creatable from 'react-select/creatable'
import {FormSpy} from 'react-final-form'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import {
    validateConcept
} from './input-validations'

export const HardCodedPropertySelect = props => {
    const {source, ...rest} = props
    const {data, loading, error} = useQuery({
        type: 'getHardcodedDataSources',
        resource: source
    })

    if (loading) {
        return <Loading/>
    }

    if (error) {
        return (
            <>
                <ErrorOutline/>
                an error occurred rendering {source}
            </>
        )
    }

    return (
        <SelectInput
            allowEmpty
            source={source}
            choices={data}
            translateChoice={false}
            emptyValue={null}
            emptyText="--reset--"
            {...rest}
        />
    )
}

HardCodedPropertySelect.propTypes = {
    source: PropTypes.string.isRequired
}

export const AutocompletePropertySelect = props => {
    const {source, allowMultipleSelection, formSource, ...rest} = props
    const {data, loading, error} = useQuery({
        type: 'getDataSources',
        resource: source,
        payload: preparePayload(rest.filter)
    })

    if (loading) {
        return <></>
        // return <Loading />
    }

    if (error) {
        return <></>
        // return <Error />
    }

    if (data && data.length === 0) {
        return null
    }

    if (allowMultipleSelection) {
        return (
            <AutocompleteArrayInput
                {...rest}
                allowEmpty
                source={formSource || source}
                choices={data}
                translateChoice={false}
                emptyValue={null}
                emptyText="--reset--"
            />
        )
    }

    return (
        <AutocompleteInput
            allowEmpty
            source={source}
            choices={data}
            translateChoice={false}
            emptyValue={null}
            emptyText="--reset--"
            {...rest}
        />
    )
}

AutocompletePropertySelect.propTypes = {
    source: PropTypes.string.isRequired,
    allowMultipleSelection: PropTypes.bool,
    formSource: PropTypes.string
}
AutocompletePropertySelect.defaultProps = {
    allowMultipleSelection: false,
    formSource: undefined
}

const preparePayload = filter => ({
    pagination: {page: 1, perPage: 0},
    sort: {field: 'name', order: 'ASC'},
    range: '',
    filter: filter ? filter : {}
})

export const PropertySelect = props => {
    const {source, allowMultipleSelection, showLoading, ...rest} = props

    const {data, loading, error} = useQuery({
        type: 'getDataSources',
        resource: source,
        payload: preparePayload(rest.filter)
    })

    if (loading) {
        return showLoading ? <LinearProgress/> : <></>
    }

    if (error) {
        return (
            <>
                <ErrorOutline/>
                an error occurred displaying {source}
            </>
        )
    }

    if (allowMultipleSelection) {
        return (
            <SelectArrayInput
                {...rest}
                allowEmpty
                source={source}
                choices={data}
                translateChoice={false}
                emptyvalue={null}
                emptytext="--reset--"
            />
        )
    }

    return (
        <SelectInput
            allowEmpty
            source={source}
            choices={data}
            translateChoice={false}
            emptyValue={null}
            emptyText="--reset--"
            {...rest}
        />
    )
}

PropertySelect.propTypes = {
    source: PropTypes.string.isRequired,
    allowMultipleSelection: PropTypes.bool,
    showLoading: PropTypes.bool
}
PropertySelect.defaultProps = {
    allowMultipleSelection: false,
    showLoading: false
}

const creatableStyle = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        height: '49px',
        marginBottom: '28px'
    }),
    menu: styles => ({
        ...styles,
        zIndex: 999,
        overflowY: 'auto'
    }),
    option: (styles, {isDisabled, isFocused}) => ({
        ...styles,
        backgroundColor: isFocused ? '#E8E8E8' : 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
        color: 'black'
    }),
    descInfo: {
        fontSize: '12px',
        color: 'gray',
        marginBottom: '5px'
    },
    validateInfo: {
        color: '#f44336',
        marginTop: '-21px',
        marginBottom: '8px',
        marginLeft: '13px',
        fontSize: '0.75rem',
        fontFamily: 'Helvetica'
    }
}
const ConceptHelper = ({style}) => (
    <div style={style}>
        *Select concept or start writing to Add a new one
    </div>
)

ConceptHelper.propTypes = {
    style: PropTypes.object
}
ConceptHelper.defaultProps = {
    style: null
}
const specifyError = (errors, translate) => {
    if (errors.concept) {
        if (typeof errors.concept === 'object') {
            return translate(errors.concept.message, errors.concept.args)
        }

        return translate(errors.concept)
    }

    return null
}

const ConceptValidator = ({style, errors}) => {
    const translate = useTranslate()
    const errorMessage = specifyError(errors, translate)
    return errorMessage ? <div style={style}>{errorMessage}</div> : null
}

ConceptValidator.propTypes = {
    style: PropTypes.object,
    errors: PropTypes.any
}
ConceptValidator.defaultProps = {
    style: null
}

export const ConceptAutoCompleteInput = props => (
    <CreatableConceptAutoCompleteSelect
        resource="concepts"
        validate={validateConcept}
        label="Concept"
        source="concept"
        {...props}
    />
)

const CreatableConceptAutoCompleteSelect = props => {
    const {
        input: {name, onChange}
    } = useInput(props)
    const {
        label,
        record: {concept}
    } = props
    const {data, loading, error: failure} = useQuery({
        type: 'getDataSources',
        resource: 'concepts'
    })

    const [selectedOption, setSelectedOption] = useState()
    const initialValue = {
        value: concept,
        label: concept
    }

    const handleChange = selection => {
        setSelectedOption(selection)
        onChange(selection && selection.label ? selection.label : '')
    }

    if (loading) {
        return <Loading/>
    }

    if (failure) {
        return (
            <>
                <ErrorOutline/>
                an error occurred displaying Concepts
            </>
        )
    }

    const options = data.map(({name}) => ({
        value: name,
        label: name
    }))

    return (
        <FormSpy>
            {fs => (
                <div>
                    <ConceptHelper style={creatableStyle.descInfo}/>
                    <Creatable
                        isClearable
                        name={name}
                        menuPlacement="auto"
                        placeholder={label}
                        classNamePrefix="react-select"
                        value={selectedOption || initialValue}
                        options={options}
                        styles={creatableStyle}
                        onChange={handleChange}
                    />
                    <ConceptValidator
                        style={creatableStyle.validateInfo}
                        errors={fs.errors}
                    />
                </div>
            )}
        </FormSpy>
    )
}

CreatableConceptAutoCompleteSelect.propTypes = {
    label: PropTypes.string.isRequired,
    record: PropTypes.object
}
CreatableConceptAutoCompleteSelect.defaultProps = {
    record: {}
}

// todo check property blurOnSelect
