import {
    TextInput,
    NumberInput,
    RefreshButton,
    useListContext
} from 'react-admin'
import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import {Form, FormSpy} from 'react-final-form'
import flatten from 'flat'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {SUBSTRING} from '../../shared/filters-operators'
import {ReferencedPropertySelect} from './shared-components'
import {
    OptionalFilterField,
    OPTIONAL_FILTERS
} from './filter-form-optional-fields'
import {AddSubscriptionButton} from '../../subscription/add-subscription-button'
import {SetupFilterView} from './setup-filter-view'

export const VariationFilterForm = props =>
    props.context === 'form' ? <FormComponent {...props}/> : null // <PostFilterButton/>

VariationFilterForm.propTypes = {
    context: PropTypes.string
}

VariationFilterForm.defaultProps = {
    context: ''
}

const FormComponent = mainProps => {
    const {basePath} = mainProps
    const {
        displayedFilters,
        setFilters,
        filterValues,
        hideFilter
    } = useListContext()

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values)
        }
    }

    //  follow similar implementation from https://github.com/marmelab/react-admin/blob/51f72620ce2dd635ff55ace0378ec149ada46d15/packages/ra-ui-materialui/src/list/filter/FilterForm.tsx#L99 to eventually hide some filters and show the defaults
    return (
        <Form initialValues={filterValues} onSubmit={onSubmit}>
            {props => {
                const {
                    values,
                    handleSubmit,
                    form: {change}
                } = props
                if (values.branchId && values.branchId.length === 0) {
                    values.subBranches = null
                }

                return (
                    <form
                        className="variation-list-form"
                        onSubmit={handleSubmit}
                    >
                        <NotFilteredFields/>

                        <ReferencedPropertySelect
                            allowMultipleSelection
                            label="Branch"
                            cReference="branches"
                            source="branchId"
                            filter={getFilter(values, 'branchId')}
                        />
                        {(values.branchId && values.branchId.length > 0) && <ReferencedPropertySelect
                            allowMultipleSelection
                            label="Sub Branch"
                            cReference="subBranches"
                            source="subBranches.id"
                            resource="subBranches"
                            filter={getFilter(values, 'subBranches.id')}
                        />}

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            allowAutoComplete
                            label="Game"
                            cReference="games"
                            source="gameId"
                            filter={{
                                isActiveBrand: true,
                                ...getFilter(values, 'gameId')
                            }}
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            allowAutoComplete
                            label="Concept"
                            cReference="concepts"
                            source="concept"
                            optionValue="name"
                            filter={getFilter(values, 'concept')}
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            label="Branding"
                            cReference="brandings"
                            source="creatives.brandings.id"
                            filter={getFilter(values, 'creatives.brandings.id')}
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            label="Platform"
                            //  example of Operator for Array (In or NotIn) WhereBuilder in backend by default uses IN when Value is Array
                            // source={`creatives.platforms.id|op=${IN}`}
                            cReference="platforms"
                            source="creatives.platforms.id"
                            filter={getFilter(values, 'creatives.platforms.id')}
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            label="CTA"
                            cReference="cta"
                            source="creatives.ctaId"
                            filter={getFilter(values, 'creatives.ctaId')}
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            allowAutoComplete
                            label="Resolution"
                            cReference="resolutions"
                            source="creatives.resolutions"
                            optionValue="name"
                        />

                        <ReferencedPropertySelect
                            alwaysOn
                            allowMultipleSelection
                            label="File Type"
                            cReference="filetypes"
                            // optionValue="id" even though column is named filetypeId the value saved there is the name...
                            source="creatives.filetypeId"
                            optionValue="name"
                            filter={getFilter(values, 'creatives.filetypeId')}
                        />

                        {OPTIONAL_FILTERS.map(filter => (
                            <OptionalFilterField
                                key={filter.filterKey}
                                displayedFilters={displayedFilters}
                                hideFilter={hideFilter}
                                values={values}
                                getFilter={getFilter}
                                changeFormItem={change}
                                {...filter}
                            />
                        ))}

                        <div className="filter-buttons-component">
                            <FilterButtons basePath={basePath}/>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

FormComponent.propTypes = {
    values: PropTypes.object,
    handleSubmit: PropTypes.func,
    form: PropTypes.object,
    change: PropTypes.func
}

FormComponent.defaultProps = {
    handleSubmit: () => {},
    values: {},
    form: {},
    change: () => {}
}

const NotFilteredFields = () => (
    <div className="not-filtered-fields">
        <TextInput
            alwaysOn
            resource="variations"
            label="Variation Name"
            source={`name|op=${SUBSTRING}`}
            className="filter-text-style"
        />

        <TextInput
            alwaysOn
            resource="variations"
            label="Version"
            source="hierarchy.version"
            className="filter-text-style"
            min={1}
        />

        <NumberInput
            alwaysOn
            resource="variations"
            label="Variation ID"
            source="id"
            className="filter-text-style"
            min={1}
        />

        <NumberInput
            alwaysOn
            resource="creatives"
            label="Creative ID"
            source="creatives.id"
            className="filter-text-style"
            min={1}
        />

        <TextInput
            alwaysOn
            label="Creative Name"
            resource="creatives"
            source={`creatives.fileName|op=${SUBSTRING}`}
            className="filter-text-style"
        />
    </div>
)

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = {values: true, pristine: true}
export const FilterButtons = () => {
    const {setFilters} = useListContext()

    return (
        <FormSpy subscription={FormSpySubscription}>
            {props => {
                const {values, form, pristine} = props
                const resetForm = () => {
                    form.reset() // rests not submitted form
                    setFilters({}, []) // clears URI
                }

                return (
                    <div className="filter-form-buttons">
                        <PostFilterButton changeFormItem={form.change}/>
                        <RefreshButton
                            label="Reset filters"
                            onClick={resetForm}
                        />
                        <div className="apply-filter-btn">
                            <Button
                                disabled={pristine}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    console.log(
                                        ' will launch filters with values',
                                        values
                                    )
                                    setFilters(values)
                                }}
                            >
                                <SearchIcon/>
                                Apply
                            </Button>
                        </div>
                        <AddSubscriptionButton values={values}/>
                        <SetupFilterView values={values}/>
                    </div>
                )
            }}
        </FormSpy>
    )
}

FilterButtons.propTypes = {
    values: PropTypes.object,
    pristine: PropTypes.object,
    form: PropTypes.object
}

FilterButtons.defaultProps = {
    values: {},
    pristine: {},
    form: {}
}

// skip resolutions for now -- readd after changing resolution Model in DB/backend
const EXCLUDED_AUTOMATIC_FILTERS_KEYS = new Set([
    `name|op=${SUBSTRING}`,
    'hierarchy.version',
    'id',
    'creatives.id',
    `creatives.fileName|op=${SUBSTRING}`,
    'creatives.resolutions'
])

const getFilter = (values, source) => {
    const filter = {}
    const flattenedValues = flatten(values, {safe: true})
    Object.keys(flattenedValues).forEach(key => {
        if (EXCLUDED_AUTOMATIC_FILTERS_KEYS.has(key)) {
            return
        }

        if (source !== key) {
            const filterValue = flattenedValues[key]
            if (filterValue && filterValue.length > 0) {
                const association = formatAssociationWhereClause(key, source)
                filter[association] = filterValue
            }
        }
    })

    return filter
}

const formatAssociationWhereClause = (key, source) => {
    if (source.includes('creatives')) {
        if (key.includes('creatives')) {
            return `$${key}$`
        }

        return `$creatives.variations.${key}$`
    }

    return `$variations.${key}$`
}

const PostFilterButton = props => {
    const {changeFormItem} = props
    const [anchorElement, setAnchorElement] = useState(null)
    const handleClick = event => {
        setAnchorElement(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElement(null)
    }

    const {showFilter, hideFilter, displayedFilters} = useListContext()

    const filterClicked = filter => {
        if (displayedFilters && displayedFilters[filter]) {
            changeFormItem(filter, undefined)
            hideFilter(filter)
        } else {
            showFilter(filter)
        }

        // remove to keep filters menu open after showing/removing filter
        handleClose()
    }

    return (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="filter-form-button"
                onClick={handleClick}
            >
                Add filters
            </Button>
            <Menu
                keepMounted
                id="simple-menu"
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={handleClose}
            >
                {OPTIONAL_FILTERS.map(({filterKey, label}) => {
                    const icon
                        = displayedFilters && displayedFilters[filterKey] ? (
                            <RemoveCircleOutlineIcon/>
                        ) : (
                            <AddCircleOutlineIcon/>
                        )
                    return (
                        <MenuItem
                            key={filterKey}
                            onClick={() => filterClicked(filterKey)}
                        >
                            {icon} &nbsp; {label}
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

PostFilterButton.propTypes = {
    changeFormItem: PropTypes.func.isRequired
}
