import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
    required,
    FormDataConsumer,
    AutocompleteInput,
    ImageInput,
    ReferenceInput,
    useNotify
} from 'react-admin'
import TextField from '@material-ui/core/TextField'
import {Publish} from '@material-ui/icons'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import readMetadata, {getOrientation} from '../read-metadata'
import {Player} from './video-player'
import {useForm} from 'react-final-form'
import {
    uploadMediaFileToS3,
    removeFileFromS3
} from '../../../app-configs/s3-provider'
import {getUUIDFromKey} from '../../../app-configs/data-provider/data-formatters'
import {AutocompletePropertySelect} from '../property-select'
import {getLanguageCode, getLanguageId} from '../../../creatives/util/utils'

const imageStyle = {
    display: 'block',
    maxWidth: '95%',
    maxHeight: '300px',
    width: 'auto',
    height: 'auto'
}

const MediaPlayer = ({type, src, imageStyle}) => {
    if (!type) {
        return <></>
    }

    return type.startsWith('video/') ? (
        <div>
            <Player src={src}/>
        </div>
    ) : (
        <img src={src} style={imageStyle} alt=""/>
    )
}

MediaPlayer.propTypes = {
    type: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    imageStyle: PropTypes.object.isRequired
}

const Preview = ({resIndex, ...props}) => {
    const {
        record: {rawFile, src}
    } = props
    const {name, lastModified, size, type} = rawFile
    const [metadata, setMetadata] = useState()
    useEffect(() => {
        readMetadata(rawFile).then(setMetadata)
    }, [rawFile, rawFile.name, setMetadata]) // this additional parameter is very important to avoid constant re-render

    if (!rawFile) {
        return <></>
    }

    const lastModifiedDate = lastModified ? new Date(lastModified) : ''
    return (
        <div>
            <MediaPlayer type={type} src={src} imageStyle={imageStyle}/>
            <TextField
                disabled
                id="standard-read-only-input"
                label="Name"
                value={name}
                className="read-only-text-Field"
            />
            <ul style={{marginTop: '10px'}}>
                <li>Type: {type}</li>
                <li>Size: {size} Bytes</li>
                <li>Last modified date: {lastModifiedDate.toString()}</li>
            </ul>
            {metadata && (
                <ul style={{marginTop: '10px'}}>
                    <li>Orientation: {metadata.Orientation}</li>
                    <li>Height: {metadata.ImageHeight}</li>
                    <li>Width: {metadata.ImageWidth}</li>
                    {metadata.Duration && (
                        <li>Duration: {metadata.Duration}</li>
                    )}
                    {metadata.CreateDate && (
                        <li>
                            Creation date: {metadata.CreateDate.toString()}
                        </li>
                    )}
                    {metadata.Saturation && (
                        <li>Saturation : {metadata.Saturation}</li>
                    )}
                </ul>
            )}
        </div>
    )
}

Preview.propTypes = {
    record: PropTypes.object,
    className: PropTypes.string,
    resIndex: PropTypes.string
}

Preview.defaultProps = {
    className: ''
}

const displayCreative = (form, id) => {
    const record = getRecord(form, id)
    if (!record) {
        return <></>
    }

    const {
        id: creativeId,
        src,
        key,
        fileTypeId,
        fileName,
        fileSize,
        height,
        width,
        duration,
        createdAt,
        updatedAt
    } = record

    return (
        <div>
            <MediaPlayer type={fileTypeId} src={src} imageStyle={imageStyle}/>
            <TextField
                disabled
                id="standard-read-only-input"
                label="Name"
                value={fileName}
                className="read-only-text-Field"
            />

            {creativeId && <TextField
                disabled
                id="standard-read-only-input"
                label="Unique Identifier"
                value={`${creativeId} -- ${getUUIDFromKey(key)}`}
                className="read-only-text-Field"
            />}

            <ul style={{marginTop: '10px'}}>
                <li>Type: {fileTypeId}</li>
                <li>Size: {fileSize} Bytes</li>
                {updatedAt && (
                    <li>Last modified date: {updatedAt.toString()}</li>
                )}
            </ul>
            <ul style={{marginTop: '10px'}}>
                <li>Orientation: {getOrientation({width, height})}</li>
                <li>Height: {height}</li>
                <li>Width: {width}</li>
                {duration && <li>Duration: {duration}</li>}
                {createdAt && <li>Creation date: {createdAt.toString()}</li>}
            </ul>
        </div>
    )
}

const imageInput = (getSource, onDrop, id) => (
    <ImageInput
        validate={required()}
        source={getSource('file')}
        placeholder={<Publish/>}
        label="File"
        accept="image/*, video/*, .zip"
        options={{
            onDrop: onDrop()
        }}
    >
        <Preview source="src" resIndex={id}/>
    </ImageInput>
)

const getRecord = (form, id) => {
    const {creatives} = form.getState().values
    const index = getIndex(id)

    return creatives[index] && creatives[index].src
        ? creatives[index]
        : undefined
}

const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '38px'}
})

const UploadArea = props => {
    const form = useForm()
    const {languageProps, id} = props
    const crIndex = getIndex(id)
    const {creatives} = form.getState().values
    const {skipAutoUpload} = creatives[crIndex] ? creatives[crIndex] : {}
    const [autoUpload, setAutoUpload] = useState(!skipAutoUpload)

    useEffect(() => {
        setAutoUpload(!skipAutoUpload)
    }, [skipAutoUpload])

    const handleAutoUploadChange = (event, id) => {
        const {checked} = event.target
        setAutoUpload(checked)
        form.change(`${id}.skipAutoUpload`, !checked)
    }

    const notify = useNotify()
    const classes = useStyles()

    return (
        <FormDataConsumer key={props.source} {...props}>
            {({getSource}) => {
                const {id} = props
                return (
                    <div key={getSource()} className="creative">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={autoUpload}
                                    name="switch-checker"
                                    color="primary"
                                    source={getSource('skipAutoUpload')}
                                    onChange={event => handleAutoUploadChange(event, id)}
                                />
                            }
                            label="Auto upload to partner"
                            className="filter-form-button"
                        />
                        <div className="creative-tag">
                            <LanguageComponent
                                getSource={getSource}
                                {...languageProps}
                            />

                            <ReferenceInput
                                allowEmpty
                                label="CTA"
                                source={getSource('ctaId')}
                                reference="cta"
                                perPage={200}
                                sort={{field: 'name', order: 'ASC'}}
                            >
                                <AutocompleteInput
                                    allowEmpty
                                    optionText="name"
                                    emptyText="--reset--"
                                />
                            </ReferenceInput>
                            <AutocompletePropertySelect
                                allowMultipleSelection
                                formClassName={classes.inlineBlock}
                                resource="brandings"
                                label="Branding"
                                source="brandings"
                                optionValue="id"
                                optionText="name"
                                formSource={getSource('brandings')}
                            />
                            <AutocompletePropertySelect
                                allowMultipleSelection
                                formClassName={classes.inlineBlock}
                                resource="platforms"
                                label="Platform"
                                source="platforms"
                                optionValue="id"
                                optionText="name"
                                formSource={getSource('platforms')}
                            />
                        </div>

                        {getRecord(form, id)
                            ? displayCreative(form, id)
                            : (areLanguagesLoaded(languageProps)
                                ? imageInput(getSource, () =>
                                    fileDropped(form, id, notify, languageProps.data)
                                )
                                : <></>
                            )}
                    </div>
                )
            }}
        </FormDataConsumer>
    )
}

UploadArea.propTypes = {
    props: PropTypes.object,
    source: PropTypes.string,
    id: PropTypes.any,
    languageProps: PropTypes.object,
    record: PropTypes.object
}

export const areLanguagesLoaded = languageProps => languageProps && !languageProps.loading && !languageProps.error

export const LanguageComponent = ({getSource, data = {}, ...rest}) => {
    const choices = Object.values(data)
    return (
        <AutocompleteInput
            allowEmpty
            label="Language"
            optionText={getLanguageOptionText}
            emptyText="--reset--"
            choices={choices}
            source={getSource('languageId')}
            {...rest}
        />
    )
}

LanguageComponent.propTypes = {
    getSource: PropTypes.func,
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.bool
}

export const getLanguageOptionText = choice => {
    let displayedName = (choice && choice.name) ? choice.name : '--reset--'
    if (choice && choice.code) {
        displayedName += ` [${choice.code.toUpperCase()}]`
    }

    return displayedName
}

/*
fixme: if dropped file is deleted from form during s3 upload, form SaveButton display "Uploading media file(s)"
form should stop waiting for "await uploadMediaFileToS3(...)" and decrement "activeUploadCount"
*/
const fileDropped = (form, fileId, notify, languages) => async file => {
    const creative = {file: {rawFile: file[0]}}
    await uploadFile(form, creative, fileId, notify, languages)
}

export const uploadFile = async (form, creative, fileId, notify, languages) => {
    incrementActiveUploadCount(form)
    const result = await uploadMediaFileToS3(creative, notify)
    if (result && result.key) {
        const {creatives} = form.getState().values
        const uploadIndex = getIndex(fileId)
        // check creative is not removed from form during upload to S3
        if (creatives[uploadIndex] && creatives[uploadIndex].file) {
            const languageCode = getLanguageCode(result.filename)
            const languageId = getLanguageId(languages)(languageCode)
            const uploadedCreativeKey = `${fileId}.uploadedFile`
            form.change(`${fileId}.languageId`, languageId)
            form.change(uploadedCreativeKey, {
                ...result
            })
        } else {
            await removeFileFromS3(result.key)
        }
    }

    decrementActiveUploadCount(form)
}

export const incrementActiveUploadCount = (form, count = 1) => {
    const {activeUploadCount = 0} = form.getState().values
    form.change('activeUploadCount', activeUploadCount + count)
}

export const decrementActiveUploadCount = form => {
    const {activeUploadCount} = form.getState().values
    form.change('activeUploadCount', activeUploadCount - 1)
}

const getIndex = id => id.replace(/\D/g, '')

export default UploadArea
