// todo if we need more data try out https://mediainfo.js.org/  (  quite fast and complete,  but heavy)
const readMetadata = async file => {
    const read = file =>
        new Promise(resolve => {
            if (file.type.startsWith('video/')) {
                const vid = document.createElement('video')
                vid.src = URL.createObjectURL(file)
                vid.preload = 'metadata'
                vid.addEventListener('loadedmetadata', () => {
                    window.URL.revokeObjectURL(vid.src)
                    resolve({
                        width: vid.videoWidth,
                        height: vid.videoHeight,
                        duration: vid.duration
                    })
                })
            } else if (file.type.startsWith('image/')) {
                const img = new Image()
                img.src = URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    resolve({width: img.width, height: img.height})
                })
            }
        })

    return read(file).then(metadata => ({
        ImageWidth: metadata.width,
        ImageHeight: metadata.height,
        Orientation: getOrientation(metadata),
        Duration: metadata.duration
            ? Number.parseInt(metadata.duration, 10)
            : undefined
    }))
}

export default readMetadata
export const getOrientation = ({width, height}) =>
    height === width ? 'Square' : (height > width ? 'Portrait' : 'Landscape')
