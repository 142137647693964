import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {alpha} from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import classnames from 'classnames'
import {translate, crudDelete, startUndoable} from 'ra-core'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {Button} from 'react-admin'

const styles = theme => ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
})

const CustomDeleteTagBtnConfirmation = props => {
    const [showDialog, setDialog] = useState(false)
    const handleDelete = event => {
        event.preventDefault()
        setDialog(false)
        const {
            dispatchCrudDelete,
            startUndoable,
            resource,
            record,
            basePath,
            redirect,
            undoable
        } = props
        if (undoable) {
            startUndoable(
                crudDelete(resource, record.id, record, basePath, redirect)
            )
        } else {
            dispatchCrudDelete(resource, record.id, record, basePath, redirect)
        }
    }

    const {label = 'ra.action.delete', classes = {}, className} = props

    return (
        <>
            <Button
                key="button"
                label={label}
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                onClick={() => setDialog(true)}
            >
                <ActionDelete/>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                aria-label="Are you sure?"
                onClose={() => setDialog(false)}
            >
                <DialogTitle>
                    Are you sure you want to delete this Tag?
                    <div>
                        <b>
                            Deleting this tag will remove it from all existing
                            Variations/Creatives that use it.
                        </b>
                    </div>
                </DialogTitle>
                <DialogContent/>
                <DialogActions>
                    <Button
                        key="button"
                        label="Delete tag"
                        className={classnames(
                            'ra-delete-button',
                            classes.deleteButton,
                            className
                        )}
                        onClick={event => handleDelete(event)}
                    >
                        <ActionDelete/>
                    </Button>
                    <Button
                        label="ra.action.cancel"
                        onClick={() => setDialog(false)}
                    >
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

CustomDeleteTagBtnConfirmation.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    dispatchCrudDelete: PropTypes.func.isRequired,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func
    ]),
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func,
    undoable: PropTypes.bool
}

CustomDeleteTagBtnConfirmation.defaultProps = {
    redirect: 'list',
    undoable: true,
    basePath: '',
    classes: {},
    className: '',
    label: '',
    record: {},
    startUndoable: () => {}
}

export default compose(
    connect(null, {startUndoable, dispatchCrudDelete: crudDelete}),
    translate,
    withStyles(styles)
)(CustomDeleteTagBtnConfirmation)
