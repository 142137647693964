import Storage from '@aws-amplify/storage'
import Cache from '@aws-amplify/cache'
import {generateThumb} from '../../src/variation/components/upload/thumb-generator'
import getUid from './data-provider/uuid-provider'
import {
    extractCreativeInfo,
    generateCreativeKey
} from './data-provider/data-formatters'
import {ImageDataConverters as ImageDataConverter} from '../../src/variation/components/upload/image-data-converter'

const prepareForUpload = async (creative, fileId) => {
    const file = await extractCreativeInfo(creative.file.rawFile)

    file.downloadAs = generateCreativeKey(file, fileId)

    return file
}

export const uploadMediaFileToS3 = async (creative, notify) => {
    try {
        const fileId = getUid()
        const mediaFile = await prepareForUpload(creative, fileId)
        const {file, fileType, downloadAs} = mediaFile
        let {key} = await Storage.put(downloadAs, file, {
            contentType: fileType
        })

        if (key.startsWith('public/')) {
            key = key.replace('public/', '')
        }

        return {
            ...mediaFile,
            key
        }
    } catch (error) {
        console.error('uploadMediaFileToS3 failed', {reason: error, creative})
        if (notify) {
            notify(
                `file: ${creative.file.rawFile.name} upload failed: ${error.message}`,
                'error'
            )
        }
    }
}

export const uploadThumbFileToS3 = async ({file, key: fileId}) => {
    /*
    fixme: generate thumb from pre-uploaded/parsed file fails !!
    current behaviour: variation will keep using current thumb
     */
    if (!file) {
        return
    }

    const thumbBlob = await generateThumb(file).then(thumb => new ImageDataConverter(thumb.thumbSrc).dataURItoBlob())
    const thumbId = 'thumb-' + fileId
    const fileType = 'image/png'
    let {key} = await Storage.put(thumbId, thumbBlob, {
        contentType: fileType
    })

    if (key.startsWith('public/')) {
        key = key.replace('public/', '')
    }

    return key
}

export const retrieveThumbnailUrls = async ({data, ...rest}) => {
    const itemsWithThumbUrl = await Promise.all(
        data.map(async item => {
            const {thumbnailKey} = item
            // todo: add default image if no thumbnailKey available
            if (thumbnailKey) {
                const thumbnailUrl = await findOrAddCache(thumbnailKey)
                item = {
                    ...item,
                    thumbnailUrl
                }
            }

            return item
        })
    )

    return {
        ...rest,
        data: itemsWithThumbUrl
    }
}

export const removeFileFromS3 = key => Storage.remove(key)

export const retrieveVariationCreatives = async variation => {
    if (!variation || !variation.data) {
        return variation
    }

    const {
        data: {creatives}
    } = variation
    const fetchedCreatives = await Promise.all(
        creatives.map(async item => {
            const {key} = item
            if (key) {
                const src = await findOrAddCache(key)
                item = {
                    ...item,
                    src
                }
            }

            return item
        })
    )

    return {
        ...variation,
        data: {
            ...variation.data,
            creatives: fetchedCreatives
        }
    }
}

export const retrieveCreativesUrls = async creatives => creatives
    ? Promise.all(
        creatives.map(async item => {
            const {key} = item
            if (key) {
                const url = await findOrAddCache(key)
                item = {
                    ...item,
                    url
                }
            }

            return item
        })
    )
    : undefined

const DEFAULT_EXPIRE = 900
const config = {
    defaultTTL: (DEFAULT_EXPIRE - 10) * 1000 // TimeToLive in MS
}
Cache.configure(config)

export const findOrAddCache = async key =>
    Cache.getItem(key, {callback: () => getItemFromStorage(key)})

const getItemFromStorage = async key => {
    const url = await Storage.get(key, {expires: DEFAULT_EXPIRE})
    Cache.setItem(key, url)

    return url
}
