import {withStyles} from '@material-ui/core/styles'
import {Filter, TextInput, NumberInput} from 'react-admin'
import React from 'react'
import {ReferencedPropertySelect} from '../../variation/components/shared-components'
import {getLanguageOptionText} from '../../variation/components/upload/upload-component'

const filterStyles = () => ({
    mainFilter: {
        padding: '5px'
    },
    filterNode: {
        marginLeft: '-50px'
    }
})

export const Filters = withStyles(filterStyles)(props => {
    const {classes, ...rest} = props
    return (
        <Filter {...rest} className={classes.mainFilter}>
            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                allowAutoComplete
                label="Game"
                cReference="games"
                source="games"
                allowEmpty={false}
                filter={{isActiveBrand: true}}
            />

            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                allowAutoComplete
                label="Concept"
                cReference="concepts"
                source="concept"
                optionValue="name"
                optionText="name"
                allowEmpty={false}
            />
            <TextInput
                alwaysOn
                label="Variation Name"
                resource="creatives"
                source="variationName"
                className="filter-text-style"
            />
            <NumberInput
                alwaysOn
                resource="creatives"
                label="Creative ID"
                source="cid"
                className="filter-text-style"
                min={1}
            />

            <TextInput
                alwaysOn
                label="Creative Name"
                resource="creatives"
                source="fileName"
                className="filter-text-style"
            />
            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                label="Branding"
                cReference="brandings"
                source="brandings"
            />

            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                label="Platform"
                cReference="platforms"
                source="platforms"
            />

            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                label="CTA"
                cReference="cta"
                source="ctaId"
            />
            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                label="Resolutions"
                cReference="resolutions"
                source="resolutions"
                optionValue="name"
            />

            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                label="File Type"
                cReference="filetypes"
                source="filetypes"
                optionValue="name"
            />

            <NumberInput
                alwaysOn
                resource="creatives"
                label="Duration ( = sec )"
                source="duration"
                className="filter-text-style"
                min={1}
            />
            {/* source={`duration|op=${LOWER_THAN_EQUALS}`} */}

            <ReferencedPropertySelect
                alwaysOn
                allowMultipleSelection
                allowAutoComplete
                label="Language"
                cReference="languages"
                source="languageId"
                optionText={getLanguageOptionText}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                className={classes.filterNode}
                label="Mood"
                cReference="moods"
                source="moods"
            />
            <ReferencedPropertySelect
                allowMultipleSelection
                label="Theme"
                cReference="themes"
                source="themes"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                label="Execution Styles"
                cReference="styles"
                source="styles"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                label="Color Scheme"
                cReference="colorSchemes"
                source="colorSchemes"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                label="Custom Tags"
                cReference="customTags"
                source="customTags"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                label="Motivation"
                cReference="motivations"
                source="motivations"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                label="Classification"
                cReference="classifications"
                source="classifications"
                className={classes.filterNode}
            />

            <ReferencedPropertySelect
                allowMultipleSelection
                allowAutoComplete
                label="Content"
                cReference="contents"
                source="contents"
                className={classes.filterNode}
            />
        </Filter>
    )
})
