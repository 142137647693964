import React, {useState} from 'react'
import {
    Button,
    SimpleForm,
    Create,
    SelectInput,
    FormDataConsumer,
    required,
    useNotify,
    useRedirect,
    useUnselectAll
} from 'react-admin'
import {useForm} from 'react-final-form'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import dataProvider from '../../app-configs/data-provider/data-provider'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {PARTNERS} from '../../app-configs/partners/partners'
import {
    getYtPlaylist,
    getFbGameAccounts
} from '../../app-configs/partners/utils'
import {FacebookAuthentication} from './facebook-authentication'
import {YoutubeAuthentication} from './youtube-authentication'
import {PropTypes} from 'prop-types'
import {PropertySelect} from '../../variation/components/property-select'
import i18n from '../../app-configs/i18n'

const transform = data => {
    if (data.partner === 'youtube') {
        data.accessToken = data.accessCode
        delete data.accessCode
    }

    return data
}

export const UploadCreativesToPartner = ({
    filterValues,
    selectedIds,
    showDialog,
    setShowDialog,
    ...props
}) => {
    const handleCloseClick = () => setShowDialog(false)
    const notify = useNotify()
    const redirect = useRedirect()
    const unselect = useUnselectAll()
    const [showLoader, setShowLoader] = useState(false)
    const onSuccess = () => {
        setShowLoader(false)
        notify(i18n.translate('creative.uploadToPartnerStarted'), 'info')
        unselect('creatives')
        redirect('/variations')
    }

    return (
        <div>
            <Dialog
                fullWidth
                open={showDialog}
                aria-label="Upload Creatives to Partner"
                onClose={handleCloseClick}
            >
                <DialogTitle>Upload to Partner Media Library</DialogTitle>
                <DialogContent>
                    <>
                        <Create
                            {...props}
                            resource="uploadtopartners"
                            basePath="/uploadtopartners"
                            record={{
                                creativeIds: selectedIds
                            }}
                            transform={transform}
                            onSuccess={onSuccess}
                        >
                            <SimpleForm
                                redirect={false}
                                handleSubmit={() => {
                                    setShowLoader(true)
                                }}
                            >
                                <SelectInput
                                    source="partner"
                                    choices={PARTNERS}
                                    optionText="name"
                                    optionValue="id"
                                    validate={isCreativesUploadedToPartner}
                                />
                                <FormDataConsumer>
                                    {({formData}) => (
                                        <Authenticate
                                            partner={formData.partner}
                                        />
                                    )}
                                </FormDataConsumer>

                                <PropertySelect
                                    resource="uploadtopartners"
                                    validate={required()}
                                    label="Game"
                                    source="games"
                                    optionValue="id"
                                    optionText="name"
                                    filter={{
                                        isActiveBrand: true
                                    }}
                                />

                                <FormDataConsumer>
                                    {({formData}) => {
                                        if (isFacebookLoggedIn(formData)) {
                                            return (
                                                <>
                                                    <br/>
                                                    <SelectFbPartnerAccount
                                                        gameId={formData.games}
                                                    />
                                                </>
                                            )
                                        }

                                        if (isYoutubeLoggedIn(formData)) {
                                            return (
                                                <>
                                                    <br/>
                                                    <YoutubePlaylistInfo
                                                        gameId={formData.games}
                                                    />
                                                </>
                                            )
                                        }

                                        return <></>
                                    }}
                                </FormDataConsumer>
                            </SimpleForm>
                        </Create>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                        <IconCancel/>
                    </Button>
                </DialogActions>
                {showLoader && <Uploading/>}
            </Dialog>
        </div>
    )
}

UploadCreativesToPartner.propTypes = {
    filterValues: PropTypes.any,
    selectedIds: PropTypes.any,
    showDialog: PropTypes.any,
    setShowDialog: PropTypes.any
}

const isFacebookLoggedIn = formData =>
    formData.partner === 'facebook' && formData.accessToken
export const isYoutubeLoggedIn = formData =>
    formData.partner === 'youtube' && formData.accessCode

const Uploading = () => (
    <>
        <div
            style={{
                backgroundColor: 'gray',
                width: '600px',
                height: '420px',
                opacity: '0.4',
                position: 'absolute',
                top: '66px',
                zIndex: '20'
            }}
        />
        <div
            style={{
                position: 'absolute',
                top: '40%',
                background: '#2d2c2c',
                width: '100%',
                height: '100px',
                zIndex: '20'
            }}
        >
            <div
                style={{
                    color: 'white',
                    fontSize: '28px',
                    position: 'absolute',
                    top: '31px',
                    left: '31px'
                }}
            >
                \n Uploading Creatives… Please wait\n
            </div>
        </div>
    </>
)

/*
Facebook SDK is disabled on localhost - only allowed in https

use following URL to generate userToken:
https://developers.facebook.com/tools/explorer/2883438961931896/?method=POST&path=act_1280414765328202%2Fadimages&version=v8.0

with following permissions:
email
read_insights
pages_show_list
ads_management
ads_read
business_management
publish_to_groups
pages_read_engagement
pages_manage_posts

and set it initial values as 'accessToken' in form to authenticate with fb developer access
 */
// eslint-disable-next-line no-unused-vars
const byPassAuthentication = () => true

const Authenticate = ({partner}) => {
    switch (partner) {
        case 'facebook':
            return <FacebookAuthentication/>

        case 'youtube':
            return <YoutubeAuthentication/>

        default:
            return <></>
    }
}

Authenticate.propTypes = {
    partner: PropTypes.any
}

const SelectFbPartnerAccount = ({gameId}) => {
    const gameAccounts = gameId ? getFbGameAccounts(gameId) : []

    return (
        <SelectInput
            source="accountId"
            choices={gameAccounts}
            optionValue="accountId"
            optionText={displayFbPartnerAccount}
            validate={isCreativesUploadedToPartner}
        />
    )
}

SelectFbPartnerAccount.propTypes = {
    gameId: PropTypes.any
}

const displayFbPartnerAccount = ({accountId, description}) =>
    description + ' -- ' + accountId

const YoutubePlaylistInfo = ({gameId}) => {
    const form = useForm()
    if (!gameId) {
        return <></>
    }

    const playlist = gameId ? getYtPlaylist(gameId) : undefined

    if (!playlist) {
        return <p>YT playlist: NA</p>
    }

    form.change('accountId', playlist.plId)

    return (
        <>
            <p>
                YT playlist: <b>{playlist.plName}</b>
            </p>
            <p>
                playlistId: <b>{playlist.plId}</b>
            </p>
        </>
    )
}

YoutubePlaylistInfo.propTypes = {
    gameId: PropTypes.number
}

const isCreativesUploadedToPartner = async (
    prop,
    {partner, creativeIds, accountId, accessCode, accessToken}
) => {
    if (!accessToken && !accessCode) {
        return 'Please authenticate Partner to continue'
    }

    const filter = {
        partner,
        creativeId: creativeIds,
        accountId
    }

    const parameters = {
        filter,
        pagination: {page: 1, perPage: 50},
        sort: {field: 'id', order: 'ASC'}
    }

    return dataProvider
        .getList('creativepartners', parameters)
        .then(({data}) => {
            if (data.length === 0) {
                return
            }

            const uploadedCids = data
                .map(({creativeId}) =>
                    creativeIds.includes(creativeId) ? creativeId : undefined
                )
                .filter(Number)

            if (!accountId) {
                return
            }

            return `following CIDs are already uploaded to the above account (${uploadedCids.join(
                ', '
            )})`
        })
        .catch(error => {
            console.log(error)
        })
}
