import React from 'react'
import {Filter, TextInput, SelectInput} from 'react-admin'

export const Filters = props => (
    <Filter {...props} style={{padding: '5px'}}>
        <TextInput
            alwaysOn
            resettable
            label="Author"
            source="q"
            className="filter-text-style"
        />

        <SelectInput
            alwaysOn
            resettable
            source="partner"
            choices={[
                {id: 'youtube', name: 'youtube'},
                {id: 'facebook', name: 'facebook'}
            ]}
        />
    </Filter>
)
