import reactAdminMessages from 'ra-language-english'

import dashboardMessages from '../../../dashboard/i18n/en'
import {creativeMessages} from '../../../variation/i18n/en'
import authenticationMessages from '../../../login/i18n/en'

export const messages = {
    ...reactAdminMessages,
    ...creativeMessages,
    ...dashboardMessages,
    ...authenticationMessages
}
