import React from 'react'
import {useForm} from 'react-final-form'
import {FacebookProvider, LoginButton, Status} from 'react-facebook'
import {FB_APP_ID, FB_SCOPE} from '../../app-configs/partners/partner-constants'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {IconButton} from '@material-ui/core'

export const FacebookAuthentication = () => (
    <div>
        <FacebookProvider appId={FB_APP_ID}>
            <Status>
                {({status}) => {
                    if (status === 'connected') {
                        return <FetchAndSetAccessToken/>
                    }

                    return (
                        <div>
                            <LoginButton scope={FB_SCOPE}>
                                <span>Login via Facebook</span>
                            </LoginButton>
                        </div>
                    )
                }}
            </Status>
        </FacebookProvider>
    </div>
)

const FetchAndSetAccessToken = () => {
    const form = useForm()
    window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
            const accessToken = response.authResponse.accessToken
            form.change('accessToken', accessToken)
        }
    })

    // TODO: fetch fb username
    // const name = window.FB.api('/me', response => response)

    return (
        <div>
            Facebook sign in successful &nbsp;&nbsp;
            <FacebookLogoutButton/>
        </div>
    )
}

const FacebookLogoutButton = () => (
    <IconButton
        notify
        creative
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
            window.FB.logout(response => {
                console.log('response  ', response)
            })
        }}
    >
        <ExitToAppIcon/>
    </IconButton>
)
