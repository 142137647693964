import React, {useState, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import {useListContext, useNotify, Confirm} from 'react-admin'
const isEqual = require('lodash.isequal')

export const SetupFilterView = ({values}) => {
    const [checked, setChecked] = useState(false)
    const handleChange = event => {
        setChecked(event.target.checked)
    }

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        name="switch-checker"
                        color="primary"
                        onChange={handleChange}
                    />
                }
                label="Views Setup"
                className="filter-form-button"
            />
            {checked && (
                <Container
                    values={values}
                    checked={checked}
                    setChecked={setChecked}
                />
            )}
        </>
    )
}

SetupFilterView.propTypes = {
    values: PropTypes.object
}

const getInitiatedList = () => {
    const filters = localStorage.getItem('savedFilters')
    return filters ? new Map(JSON.parse(filters)) : new Map([])
}

const Container = ({checked, setChecked, values}) => {
    const [savedList, setSavedList] = useState(getInitiatedList())
    const {setFilters} = useListContext()
    const notify = useNotify()
    useEffect(() => {
        localStorage.setItem(
            'savedFilters',
            JSON.stringify(Array.from(savedList.entries()))
        )
    }, [savedList, savedList.size])

    const createHandler = key => {
        const clonedList = new Map(savedList)
        clonedList.set(key, values)
        setSavedList(clonedList)
        notify('New filter was created!', 'info')
    }

    const applyHandler = (key, value) => {
        setFilters(value)
        notify(`Filter ${filterName} was applied!`, 'info')
    }

    const deleteHandler = key => {
        const clonedList = new Map(savedList)
        clonedList.delete(key)
        setSavedList(clonedList)
        notify(`Filter ${key} was deleted!`, 'info')
    }

    const filterName = getFilterName(savedList, values)
    return (
        <Drawer anchor="right" open={checked} onClose={() => setChecked(false)}>
            <div className="filter-drawer-container">
                {filterName ? (
                    <AlreadyCreated name={filterName}/>
                ) : (
                    <CreateView
                        savedList={savedList}
                        createHandler={createHandler}
                    />
                )}
                <Divider/>
                <SavedFilters
                    savedList={savedList}
                    applyHandler={applyHandler}
                    deleteHandler={deleteHandler}
                />
            </div>
        </Drawer>
    )
}

Container.propTypes = {
    values: PropTypes.object,
    checked: PropTypes.bool,
    setChecked: PropTypes.func
}
const AlreadyCreated = ({name}) => (
    <div className="create-filter-container">
        <div>Filter was already Created under the name:-</div>
        <b>{name}</b>
    </div>
)

AlreadyCreated.propTypes = {
    name: PropTypes.string
}

const CreateView = ({savedList, createHandler}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [createdValue, setCreatedValue] = useState(null)
    const handleOnBlur = event => {
        setCreatedValue(event.target.value)
        if (event.target.value === '') {
            setErrorMessage('Filter name is required')
        } else if (savedList && savedList.get(event.target.value)) {
            setErrorMessage(
                'Filter name is already in use, enter different name.'
            )
            setCreatedValue(null)
        } else {
            setErrorMessage(null)
        }
    }

    return (
        <div>
            <div>Create a new Filter</div>
            <div className="create-filter-container">
                <TextField
                    required
                    id="filled-error-helper-text"
                    label="Add filter name"
                    helperText={errorMessage}
                    variant="filled"
                    error={Boolean(errorMessage)}
                    onBlur={handleOnBlur}
                />
                <Button
                    disabled={Boolean(!createdValue)}
                    variant="contained"
                    color="primary"
                    onClick={() => createHandler(createdValue)}
                >
                    <SaveIcon/>
                    CREATE FILTER
                </Button>
            </div>
        </div>
    )
}

CreateView.propTypes = {
    savedList: PropTypes.any,
    createHandler: PropTypes.func
}

const SavedFilters = ({savedList, applyHandler, deleteHandler}) => [...savedList].map(filter => (
    <FilterItem
        key={filter[0]} // keys are unique values and represent filter name
        filterName={filter[0]}
        filterValue={filter[1]}
        applyHandler={applyHandler}
        deleteHandler={deleteHandler}
    />
))

/**
 * Check if current filter values already created by comparing the currentFilter with saved savedList
 * if so return the saved filter associated with this values
 * @param {Map} savedList
 * @param {object} currentFilter
 * @returns {*} ,filter name if exists
 */
const getFilterName = (savedList, currentFilter) => {
    const [name] = [...savedList]
        .filter(item => isEqual(currentFilter, item[1]))
        .flat()
    return name
}

const FilterItem = ({filterName, filterValue, applyHandler, deleteHandler}) => {
    const [showConfirm, setShowConfirm] = useState(false)
    return (
        <div key={filterName}>
            <div style={{backgroundColor: '#fbfafa'}}>
                <Confirm
                    isOpen={showConfirm}
                    title="Delete Filter"
                    content="Are you sure you want to delete this Filter?"
                    confirm="Yes"
                    confirmColor="primary"
                    cancel="Cancel"
                    onConfirm={() => deleteHandler(filterName)}
                    onClose={() => setShowConfirm(false)}
                />
                <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => setShowConfirm(true)}
                >
                    <DeleteIcon/>
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => applyHandler(filterName, filterValue)}
                >
                    <SearchIcon/>
                </IconButton>
                {filterName}
            </div>
            <Divider/>
        </div>
    )
}

FilterItem.propTypes = {
    filterName: PropTypes.string,
    filterValue: PropTypes.object,
    applyHandler: PropTypes.func,
    deleteHandler: PropTypes.func
}
