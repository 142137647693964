import React, {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'
import {
    List,
    TextField,
    Datagrid,
    FunctionField,
    ReferenceField,
    DateField,
    useUnselectAll,
    RefreshButton,
    useListController
} from 'react-admin'
import {Box, Typography, Chip} from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import AppsIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'
import {PropTypes} from 'prop-types'
import {
    FileTypeIcon,
    getMacroType
} from '../../variation/components/file-type-icon'
import {Tags} from '../../variation/components/aside'
import {UploadCreatives} from '../../variation/components/upload-button'
import {ShareCreatives} from './share-creatives'
import {
    getFbGameDescriptionByAccountID,
    getYtPlayListName
} from '../../app-configs/partners/utils'
import {
    DownloadButton,
    DownloadSelectedCreatives
} from '../../variation/components/download'
import {Filters} from './list-filter'
import {findOrAddCache} from '../../app-configs/s3-provider'
import {FiltersWarningMessage} from '../../shared/warning-message'
import {ListPagination} from '../../shared/list-pagination'
import {CREATIVES_LIST_PER_PAGE} from '../../app-configs/data-provider/constants'
import {
    isObjectEmpty,
    getUserPerPageCache
} from '../../app-configs/data-provider/utils'
import {formatBytes} from '../../variation/components/media-size-res'
import {cardDataGridJsx} from './card'

export const CreativesList = props => {
    const {resource, ...rest} = useListController(props)
    const unselectAll = useUnselectAll()
    const [loaded, setLoaded] = useState(false)
    const [isListLayout, setIsListLayout] = useState(true)
    if (window.location.hash.includes(resource) && !loaded) {
        setLoaded(true)
        unselectAll(resource)
    }

    return (
        <List
            {...props}
            sort={{field: 'id', order: 'DESC'}}
            perPage={getUserPerPageCache(CREATIVES_LIST_PER_PAGE)}
            pagination={<ListPagination/>}
            bulkActionButtons={<CreativeBulkActionButtons {...props}/>}
            filters={<Filters {...props.filterProps}/>}
            actions={<PostActions {...props} setIsListLayout={setIsListLayout} isListLayout={isListLayout}/>}
        >
            {getDataGrid(isListLayout, rest)}
        </List>
    )
}

CreativesList.propTypes = {
    filterProps: PropTypes.object
}

const CreativeBulkActionButtons = props => (
    <>
        <DownloadSelectedCreatives {...props}/>
        <UploadCreatives {...props}/>
        <ShareCreatives {...props}/>
    </>
)

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right'
}
const PostActions = ({
    resource,
    filters,
    displayedFilters,
    filterValues,
    setFilters,
    showFilter,
    isListLayout,
    setIsListLayout
}) => (
    <CardActions style={cardActionStyle}>
        {filters
                && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
        <RefreshButton
            label="Reset Filters"
            onClick={() => {
                setFilters({}, [])
            }}
        />
        <LayoutSelector isListLayout={isListLayout} setIsListLayout={setIsListLayout}/>
    </CardActions>
)

PostActions.propTypes = {
    displayedFilters: PropTypes.any,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    resource: PropTypes.string.isRequired,
    showFilter: PropTypes.func,
    setFilters: PropTypes.func,
    isListLayout: PropTypes.bool,
    setIsListLayout: PropTypes.func
}

const LayoutSelector = ({setIsListLayout, isListLayout}) => {
    let gridIconColor = 'disabled'
    let listIconColor = 'primary'
    if (!isListLayout) {
        gridIconColor = 'primary'
        listIconColor = 'disabled'
    }

    return (
        <>
            <ListIcon color={listIconColor} className="cursor-pointer" onClick={() => setIsListLayout(true)}/>
            <AppsIcon color={gridIconColor} className="cursor-pointer" onClick={() => setIsListLayout(false)}/>
        </>
    )
}

LayoutSelector.propTypes = {
    isListLayout: PropTypes.bool,
    setIsListLayout: PropTypes.func
}

const getDataGrid = (isListLayout, {filterValues, ...props}) => {
    const layOutJsx = isListLayout ? listDataGridJsx() : cardDataGridJsx(props)
    if (isObjectEmpty(filterValues)) {
        return (
            <>
                <FiltersWarningMessage/>
                {layOutJsx}
            </>
        )
    }

    return layOutJsx
}

export const displaySize = ({fileSize}) => formatBytes(fileSize, 1)

const listDataGridJsx = () => (
    <Datagrid expand={<CreativeInfo/>}>
        <FunctionField label="Id" sortBy="id" render={displayId}/>
        <FunctionField
            label="FileType"
            sortBy="fileTypeId"
            render={displayFileType}
        />
        <TextField source="fileName"/>
        <ReferenceField
            label="Orientation"
            source="orientationId"
            reference="orientations"
            sortBy="orientationId"
            link={false}
        >
            <TextField source="name"/>
        </ReferenceField>
        <FunctionField
            label="Resolution"
            render={displayResolution}
            sortBy="height"
        />
        <FunctionField
            label="Size"
            render={displaySize}
            sortBy="fileSize"
        />
        <DateField showTime source="createdAt"/>
        <FunctionField label="Download" render={downloadBtn}/>
    </Datagrid>
)

export const CreativeInfo = ({record, isListGrid = true}) => {
    const {key, fileTypeId, partners, language, cta} = record
    const [url, setUrl] = useState()
    useEffect(() => {
        if (key) {
            const getUrl = async () => {
                const creativeUrl = await findOrAddCache(key)
                setUrl(creativeUrl)
            }

            getUrl()
        }
    }, [key])

    const creativeDisplay = getCreativeDisplay(fileTypeId, url, isListGrid)
    const isNotEmpty = propArray => propArray && propArray.length > 0

    return (
        <Box display="flex" flexDirection="column">
            <Box flex={1} mr="0.5em">
                {creativeDisplay}
            </Box>
            <Box p="1em" display="flex" justifyContent="space-between">
                {isNotEmpty(record.platforms) && (
                    <Box flex={1} mr="0.5em">
                        <Tags
                            record={record}
                            source="platforms"
                            label="Platforms"
                        />
                    </Box>
                )}
                {isNotEmpty(record.brandings) && (
                    <Box flex={1} mr="0.5em">
                        <Tags
                            record={record}
                            source="brandings"
                            label="Brandings"
                        />
                    </Box>
                )}
                {language && language.code && (
                    <Box flex={1} mr="0.5em">
                        <Typography gutterBottom>language</Typography>
                        <Chip
                            key={language.code}
                            label={language.code}
                            variant="outlined"
                        />
                    </Box>
                )}
                {cta && (
                    <Box flex={1} mr="0.5em">
                        <Typography gutterBottom>CallToAction</Typography>
                        <Chip key={cta} label={cta.name} variant="outlined"/>
                    </Box>
                )}
            </Box>
            {isNotEmpty(partners) && (
                <Box flex={1} mr="0.5em">
                    <Typography gutterBottom>Partners</Typography>
                    {partners.map(displayCreativePartnerInfo)}
                </Box>
            )}
        </Box>
    )
}

CreativeInfo.propTypes = {
    record: PropTypes.object,
    isListGrid: PropTypes.bool
}

const displayFileType = ({fileTypeId}) => (
    <FileTypeIcon fileType={getMacroType(fileTypeId)}/>
)
displayFileType.propTypes = {
    fileTypeId: PropTypes.any
}

export const downloadBtn = record => (
    <DownloadButton creative={record} record={record.variations[0]}/> // TODO STP-2134 - check if we can change the association between the creative and variation to fix this
)

const displayId = ({id}) => `cid-${id}`

export const displayResolution = ({width, height}) => {
    if (!width || !height) {
        return 'Resolution: n/a'
    }

    return width + 'x' + height
}

export const displayCreativePartnerInfo = ({
    accountId,
    partner,
    hash,
    id,
    etag
}) => {
    let partnerInfo
    if (partner === 'facebook') {
        const gameDescription = getFbGameDescriptionByAccountID(accountId)
        partnerInfo = [
            <Chip
                key={`p.game-${id}`}
                label={gameDescription}
                variant="outlined"
            />,
            <Chip key={`p.accId-${id}`} label={accountId} variant="outlined"/>,
            <Chip
                key={`p.hash-${id}`}
                label={`hash: ${hash}`}
                variant="outlined"
            />
        ]
    }

    if (partner === 'youtube') {
        const plName = getYtPlayListName(accountId)
        const openYtVideo = () =>
            window.open(`https://youtu.be/${hash}`, '_blank')

        partnerInfo = [
            <Chip
                key={`p.PlName-${id}`}
                label={`PL: ${plName}`}
                variant="outlined"
            />,
            <Chip
                key={`p.hash-${id}`}
                label={`vID: ${hash}`}
                variant="outlined"
                onClick={openYtVideo}
            />,
            <Chip
                key={`p.etag-${id}`}
                label={`eTag: ${etag}`}
                variant="outlined"
            />
        ]
    }

    return (
        <Box key={hash} display="flex">
            <Chip key={`p.partner-${id}`} label={partner} variant="outlined"/>
            {partnerInfo}
        </Box>
    )
}

displayCreativePartnerInfo.propTypes = {
    accountId: PropTypes.object,
    partner: PropTypes.string,
    hash: PropTypes.string,
    id: PropTypes.number,
    etag: PropTypes.string
}

export const getCreativeDisplay = (fileTypeId, url, isListGrid) => {
    const videoStyle = {
        marginTop: '5px',
        marginBottom: '5px',
        maxHeight: isListGrid ? '300px' : '210px',
        maxWidth: '400px'
    }

    return (
        fileTypeId.startsWith('video/') ? (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <ReactPlayer
                    controls
                    url={url}
                    playing={false}
                    style={videoStyle}
                    light={!isListGrid}
                />
            </div>
        ) : (
            <img src={url} className={isListGrid ? 'creative-expand-img' : 'creative-card-img'} alt=""/>
        )
    )
}
