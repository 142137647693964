import React from 'react'
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    usePermissions
} from 'react-admin'
import {PropTypes} from 'prop-types'
import CustomDeleteTagBtnConfirmation from './custom-tag-delete-button'
import {hasEditPermission} from '../app-configs/auth/utils'
import {Filters} from '../shared/filter-name'
import {
    ListPagination,
    DEFAULT_PER_PAGE_PAGINATION
} from '../shared/list-pagination'

export const TagList = ({basePath, ...props}) => {
    const {permissions} = usePermissions()
    const isPathConcept = isPathConcepts(basePath)
    return (
        <List
            {...props}
            basePath={basePath}
            filters={<Filters/>}
            pagination={!isPathConcept && <ListPagination/>}
            perPage={!isPathConcept && DEFAULT_PER_PAGE_PAGINATION}
            bulkActionButtons={false}
            // bulkActionButtons={!isPathConcept}  // we are not providing BulkDelete and BulkEdit anyway
        >
            <Datagrid>
                {!isPathConcepts(basePath) && <TextField source="id"/>}
                <TextField source="name"/>
                <TextField source="usages"/>
                {hasEditPermission(permissions) && (
                    <EditButton label="ra.action.edit"/>
                )}
                {!isPathConcept && hasEditPermission(permissions) && (
                    <CustomDeleteTagBtnConfirmation label="ra.action.delete"/>
                )}
            </Datagrid>
        </List>
    )
}

export const isPathConcepts = basePath => basePath === '/concepts'

TagList.propTypes = {
    basePath: PropTypes.string
}
