import React from 'react'
import {Admin, Resource, ShowGuesser} from 'react-admin'

import './App.css' // eslint-disable-line import/extensions
import './app-configs/aws-config' // eslint-disable-line import/no-unassigned-import

import i18n from './app-configs/i18n'
import {lightTheme} from './layout/components/themes'
import dashboard from './dashboard'
import authProvider from './app-configs/auth/auth-provider'
import dataProvider from './app-configs/data-provider/data-provider'
import SocialLogin from './login/components/social-login'
import appLayout from './layout'
import {VariationCreate} from './variation/components/create'
import {VariationList} from './variation/components/list'
import {TagEdit} from './tags/edit'
import {TagCreate} from './tags/create'
import {VariationShow} from './variation/components/show'
import reducers from './app-configs/reducers' // let's try to use react hooks ( use saga and redux really for just what needs to be centralized)
import sagas from './app-configs/sagas'
import {VariationEdit} from './variation/components/edit'
import {TagList} from './tags/list'
import {
    hasUploadPermission,
    hasEditPermission,
    hasViewPermission,
    hasCreateTagPermission,
    hasTagAdminPermission,
    hasUserMgmtPermission
} from './app-configs/auth/utils'
import {ListGames} from './games/list'
import {EditGame} from './games/edit'
import {CreativesList} from './creatives/components/list'
import {ListPartnerUploadFailures} from './uploads/components/list-partner-upload-failures'
import {ListSubscription} from './subscription/list'
import {UserList} from './users/components/list'
import {RoleList} from './users/components/role-list'
import {SharedLinksList} from './creatives/components/sharedlinks-list'

const App = () => (
    <Admin
        disableTelemetry
        title="Creative Library" // this is needed to customize error title in Appbar when errors occur
        theme={lightTheme}
        layout={appLayout}
        authProvider={authProvider}
        dashboard={dashboard.dashboard}
        dataProvider={dataProvider}
        loginPage={SocialLogin}
        customReducers={reducers}
        customSagas={sagas}
        i18nProvider={i18n}
    >
        {permissions => [
            <Resource
                key="variations"
                name="variations"
                create={hasUploadPermission(permissions) ? VariationCreate : null}
                list={VariationList}
                edit={hasEditPermission(permissions) ? VariationEdit : null}
                show={hasViewPermission(permissions) ? VariationShow : null}
            />,
            <Resource key="creatives" name="creatives" list={CreativesList}/>,
            <Resource
                key="games"
                name="games"
                options={{label: 'Games'}}
                edit={hasTagAdminPermission(permissions) ? EditGame : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? ListGames : null}
            />,
            <Resource
                key="brandings"
                name="brandings"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Brandings'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="moods"
                name="moods"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Moods'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={TagList}
            />,
            <Resource
                key="colorSchemes"
                name="colorSchemes"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'ColorSchemes'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="contents"
                name="contents"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Content'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="styles"
                name="styles"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Styles'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="classifications"
                name="classifications"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Classifications'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="motivations"
                name="motivations"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Motivations'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="themes"
                name="themes"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Themes'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="cta"
                name="cta"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'CTA'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="customTags"
                name="customTags"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Custom Tags'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="platforms"
                name="platforms"
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                options={{label: 'Platforms'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="concepts"
                name="concepts"
                options={{label: 'Concepts'}}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource key="orientations" name="orientations"/>,
            <Resource key="filetypes" name="filetypes"/>,
            <Resource key="languages" name="languages"/>,
            <Resource key="uploadtopartners" name="uploadtopartners"/>,
            <Resource key="creativepartners" name="creativepartners"/>,
            <Resource key="resolutions" name="resolutions"/>,
            <Resource
                key="branches"
                name="branches"
                options={{label: 'Branches'}}
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="subBranches"
                name="subBranches"
                options={{label: 'SubBranches'}}
                create={hasCreateTagPermission(permissions) ? TagCreate : null}
                edit={hasTagAdminPermission(permissions) ? TagEdit : null}
                show={hasTagAdminPermission(permissions) ? ShowGuesser : null}
                list={hasTagAdminPermission(permissions) ? TagList : null}
            />,
            <Resource
                key="partnerUploadFailures"
                name="partnerUploadFailures"
                list={ListPartnerUploadFailures}
            />,
            <Resource
                key="subscriptions"
                name="subscriptions"
                list={ListSubscription}
            />,
            <Resource
                key="users"
                name="users"
                list={hasUserMgmtPermission(permissions) ? UserList : null}
            />,
            <Resource
                key="roles"
                name="roles"
                list={hasUserMgmtPermission(permissions) ? RoleList : null}
            />,
            <Resource
                key="sharedlinks"
                name="sharedlinks"
                list={SharedLinksList}
            />
        ]}
    </Admin>
)
export default App
