import {AutocompletePropertySelect} from './property-select'
import React from 'react'
import PropTypes from 'prop-types'
export const SubBranchComp = props => (
    <AutocompletePropertySelect
        allowMultipleSelection
        resource="variations"
        label="Sub Branch"
        source="subBranches"
        optionValue="id"
        optionText="name"
        filter={props.filterId ? {branchId: props.filterId} : {}}

    />
)
SubBranchComp.propTypes = {
    filterId: PropTypes.number
}

SubBranchComp.defaultProps = {
    filterId: undefined
}
