import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Responsive, translate} from 'react-admin'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'

class MenuComponent extends Component {
    constructor() {
        super()

        this.state = {}
    }

    handleToggle(menu) {
        this.setState(state => ({[menu]: !state[menu]}))
    }

    render() {
        const {onMenuClick, open, logout, translate, children} = this.props

        const cloned = React.Children.map(children, child => React.cloneElement(child, {
            onMenuClick,
            open,
            translate,
            handleToggle: this.handleToggle.bind(this),
            state: this.state
        }))

        return (
            <div>
                {cloned}
                <Responsive small={logout} medium={null}/>
            </div>
        )
    }

    static propTypes = {
        children: PropTypes.array,
        logout: PropTypes.object,
        onMenuClick: PropTypes.func,
        open: PropTypes.bool,
        translate: PropTypes.func
    }

    static defaultProps = {
        children: undefined,
        logout: undefined,
        onMenuClick: undefined,
        open: undefined,
        translate
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme
})

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate)

export const MenuUIComponent = enhance(MenuComponent)
