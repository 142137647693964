import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'

import {
    MuiThemeProvider,
    createTheme,
    withStyles
} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import {defaultTheme} from 'react-admin'
import GoogleLoginButton from './google-button'
import {TermsAndPolicies} from './terms-and-policies'

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        marginTop: '28em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        backgroundColor: theme.palette.secondary[500]
    }
})

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */

class Login extends Component {
    theme = createTheme(this.props.theme)

    containerRef = React.createRef()

    backgroundImageLoaded = false

    updateBackgroundImage = () => {
        if (!this.backgroundImageLoaded && this.containerRef.current) {
            const {backgroundImage} = this.props
            this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`
            this.backgroundImageLoaded = true
        }
    }

    // Load background image asynchronously to speed up time to interactive
    lazyLoadBackgroundImage() {
        const {backgroundImage} = this.props

        if (backgroundImage) {
            const img = new Image()
            img.addEventListener('load', this.updateBackgroundImage)
            img.src = backgroundImage
        }
    }

    componentDidMount() {
        this.lazyLoadBackgroundImage()
    }

    componentDidUpdate() {
        if (!this.backgroundImageLoaded) {
            this.lazyLoadBackgroundImage()
        }
    }

    render() {
        const {classes} = this.props
        return (
            <MuiThemeProvider theme={this.theme}>
                <div ref={this.containerRef} className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon/>
                            </Avatar>
                        </div>
                        <GoogleLoginButton/>
                    </Card>
                    <TermsAndPolicies/>
                </div>
            </MuiThemeProvider>
        )
    }

    static propTypes = {
        backgroundImage: PropTypes.string,
        theme: PropTypes.object,
        classes: PropTypes.object.isRequired
    }

    static defaultProps = {
        backgroundImage:
            'https://images.unsplash.com/photo-1475669698648-2f144fcaaeb1?auto=format&fit=crop&w=1920&q=80',
        theme: defaultTheme
    }
}

export default withStyles(styles)(Login)
