import {maxLength, minLength, required} from 'react-admin'

// eslint-disable-next-line no-unused-vars
const validateVariationName = (value, allValues) => {
    const regNoTabs = /^[^\t]*$/
    const regNoSurroundingSpaces = /^[^ ]+.*[^ ]$/

    if (!regNoTabs.test(value)) {
        return 'The variation name must not contain tab spacing'
    }

    if (!regNoSurroundingSpaces.test(value)) {
        return 'The variation name must not contain spaces in front and after the name'
    }

    return ''
}

export const validateConcept = [required(), minLength(3)]
export const validateName = [
    ...validateConcept,
    maxLength(35),
    validateVariationName
]

export const integer = value => {
    if (!Number.isInteger(value)) {
        return 'Number must not contain decimals'
    }
}

export const memoize = fn => {
    const cache = {}
    return n => {
        if (n in cache) {
            return cache[n]
        }

        const result = fn(n)
        cache[n] = result
        return result
    }
}

export const validateCreative = values => {
    const errors = {}
    if (!values.creatives || values.creatives.length === 0) {
        errors.creatives = ['At least one Creative must be uploaded']
    }

    return errors
}
