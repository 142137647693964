import React, {useState} from 'react'
import Storage from '@aws-amplify/storage'
import {Form} from 'react-final-form'
import {
    maxLength,
    minLength,
    required,
    useListContext,
    useNotify,
    TextInput
} from 'react-admin'
import PublishIcon from '@material-ui/icons/Publish'
import Button from '@material-ui/core/Button'
import IconCancel from '@material-ui/icons/Cancel'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {PropTypes} from 'prop-types'
import i18n from '../../app-configs/i18n'
import {generateCreativeFileName} from '../util/utils'
import {generateZipFile} from '../../variation/utils/url-zipper'
import getUid from '../../app-configs/data-provider/uuid-provider'

export const ShareCreatives = props => {
    const [showDialog, setShowDialog] = useState(false)
    const [shareFiles, setShareFiles] = useState()

    const {data, selectedIds} = useListContext()
    const selectedCreatives = selectedIds.map(id => data[id])

    const onShareAllClick = async () => {
        setShowDialog(true)
        const files = selectedCreatives.map(creative => {
            const downloadAs = generateCreativeFileName(
                creative,
                creative.variations[0] // TODO STP-2134 - check if we can change the association between the creative and variation to fix this
            )
            return {name: downloadAs, key: creative.key}
        })
        setShareFiles(files)
    }

    return (
        <div>
            <Button
                color="primary"
                startIcon={<PublishIcon/>}
                onClick={onShareAllClick}
            >
                Share To Partners
            </Button>
            {shareFiles && (
                <ShareCreativesForm
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    files={shareFiles}
                    {...props}
                />
            )}
        </div>
    )
}

const validateLink = [required(), minLength(3), maxLength(100)]
const ShareCreativesForm = ({
    selectedIds,
    files,
    showDialog,
    setShowDialog,
    ...props
}) => {
    const handleCloseClick = () => setShowDialog(false)
    const notify = useNotify()
    const [zipProgress, setZipProgress] = useState(undefined)
    const [uploadProgress, setUploadProgress] = useState(undefined)
    const [isSubmitClicked, setIsSubmitClicked] = useState(false)

    const resetDialog = () => {
        setZipProgress(undefined)
        setUploadProgress(undefined)
        setIsSubmitClicked(false)
    }

    const shareLink = async ({link: linkName}) => {
        setIsSubmitClicked(true)

        const onSuccessCallback = async (folder, zipName) => {
            setUploadProgress('0%')
            const uid = getUid()
            const uidZipName = `${uid}_${zipName}`
            const sharedZip = await Storage.put(uidZipName, folder, {
                progressCallback(progress) {
                    const uploadPercentage = (
                        (progress.loaded * 100)
                        / progress.total
                    ).toFixed(2)
                    setUploadProgress(`${uploadPercentage}%`)
                },
                level: 'protected',
                acl: 'public-read',
                contentType: 'application/zip'
            }).catch(error => {
                console.error('creative.shareWithPartnerUploadError', {
                    reason: error
                })
                notify(
                    i18n.translate('creative.shareWithPartnerUploadError'),
                    'error'
                )
            })

            const url = await Storage.get(sharedZip.key, {
                level: 'protected'
            })
            const [link] = url.split('?') // FILE IS PUBLICLY ACCESSIBLE, AMZ tokens are splitted away
            setShowDialog(false)
            notify(`Here is your shareable link:  ${link}`, 'info')
            console.log('Here is your shareable link', link)
            resetDialog()
        }

        const onFailureCallback = () => {
            notify(i18n.translate('creative.shareWithPartnerZipError'), 'error')
            resetDialog()
        }

        const onUpdateCallback = value => {
            setZipProgress(value)
        }

        await generateZipFile(
            files,
            linkName,
            onSuccessCallback,
            onUpdateCallback,
            onFailureCallback
        )
    }

    const title = `Creating shareable link for (${selectedIds.length}) creatives`

    return (
        <div>
            <Dialog
                fullWidth
                open={showDialog}
                aria-label="Share Creatives to Partners"
                onClose={handleCloseClick}
            >
                <DialogTitle>{title}</DialogTitle>
                <>
                    <Form {...props} onSubmit={shareLink}>
                        {props => {
                            const {handleSubmit} = props
                            return (
                                <form redirect="false" onSubmit={handleSubmit}>
                                    <DialogContent>
                                        <p>
                                            <b>
                                                All generated links will be
                                                available in the dashboard
                                            </b>
                                        </p>
                                        <TextInput
                                            resettable
                                            label="Link"
                                            source="link"
                                            validate={validateLink}
                                            disabled={isSubmitClicked}
                                        />

                                        {zipProgress && (
                                            <>
                                                <p>Zipping {zipProgress}</p>
                                                {uploadProgress && (
                                                    <p>
                                                        Uploading{' '}
                                                        {uploadProgress}
                                                    </p>
                                                )}
                                                <p>
                                                    Closing this dialog will not
                                                    stop process, you will be
                                                    notified when link is ready
                                                </p>
                                            </>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            startIcon={<CloudUploadIcon/>}
                                            disabled={isSubmitClicked}
                                        >
                                            Share
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<IconCancel/>}
                                            onClick={handleCloseClick}
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </form>
                            )
                        }}
                    </Form>
                </>
            </Dialog>
        </div>
    )
}

ShareCreativesForm.propTypes = {
    selectedIds: PropTypes.array,
    files: PropTypes.array,
    showDialog: PropTypes.bool,
    setShowDialog: PropTypes.func,
    handleSubmit: PropTypes.func
}
