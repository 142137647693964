import React from 'react'
import AppBar from './ra-appbar-without-refresh-button'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import {PropTypes} from 'prop-types'
import {UserMenu} from 'react-admin'
import UserIcon from './user-icon'

const styles = () => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
})

const UserProfileMenu = props => <UserMenu {...props} icon={<UserIcon/>}/>

const GGSIcon = () => (
    <div>
        <img
            src="/logo-ggs.png"
            alt="Goodgamestudios Logo"
            style={{height: 30}}
        />
    </div>
)

export const CustomizedAppBar = props => {
    const {classes} = props

    const appBarStyle = {
        backgroundColor:
            process.env.REACT_APP_ENVSTAGE !== 'production' && '#808080',
        marginTop: '-50px'
    }
    return (
        <AppBar {...props} userMenu={<UserProfileMenu/>} style={appBarStyle}>
            <GGSIcon/>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            >
                Creative Library Tool -- (<b>{process.env.REACT_APP_ENVSTAGE}</b>)&nbsp;&nbsp;
            </Typography>
        </AppBar>
    )
}

CustomizedAppBar.propTypes = {
    classes: PropTypes.object.isRequired
}
export const CustomAppBar = withStyles(styles)(CustomizedAppBar)
