import React from 'react'
import {withRouter} from 'react-router-dom'
import dashboard from '../../dashboard'
import {TagMenu} from '../../tags/menu'
import {GameMenu} from '../../games/menu'
import {ConceptMenu} from '../../concepts/menu'
import {VariationMenu} from '../../variation/components/menu'
import {CreativesMenu} from '../../creatives/components/menu'
import {MenuUIComponent} from './menu-ui-comp'
import ArchivedMenu from '../../archived/components/archived-menu'
import UserMgmtMenu from '../../users/components/menu'

const MenuComponent = () => (
    <MenuUIComponent>
        <dashboard.menu/>
        <VariationMenu/>
        <TagMenu/>
        <GameMenu/>
        <ConceptMenu/>
        <CreativesMenu/>
        <ArchivedMenu/>
        <UserMgmtMenu/>
    </MenuUIComponent>
)

export const CLBMenu = withRouter(MenuComponent)
