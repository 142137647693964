import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {
    List,
    Datagrid,
    DateField,
    ChipField,
    SingleFieldList,
    ReferenceArrayField,
    DeleteButton
} from 'react-admin'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {ListPagination} from '../shared/list-pagination'
import {getUserEmail} from '../login/selectors/user-selector'

const list = ({email}) => (
    <>
        <div className="subs-list-title">
            <NotificationsIcon/>
            <span className="subs-list-header">
                <b>Subscriptions</b>
            </span>
        </div>
        <List
            hasList
            title="- Subscriptions -"
            actions={false}
            perPage={50}
            pagination={<ListPagination/>}
            filterDefaultValues={{email}}
            bulkActionButtons={false}
            resource="subscriptions"
            empty={<></>}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            basePath="/subscriptions"
        >
            <Datagrid>
                <DisplayTag
                    label="Games"
                    reference="games"
                    source="gameId"
                />
                <DisplayTag
                    label="Branches"
                    reference="branches"
                    source="branchId"
                />
                <DateField showTime source="createdAt"/>
                <DeleteButton undoable redirect="/"/>
            </Datagrid>
        </List>
    </>
)

list.propTypes = {
    email: PropTypes.string.isRequired
}

const DisplayTag = props => {
    const {reference, source, label, ...rest} = props
    const {
        record: {filter}
    } = props
    if (filter && filter[source]) {
        return (
            <ReferenceArrayField
                label={label}
                reference={reference}
                source={`filter.${source}`}
                {...rest}
            >
                <SingleFieldList linkType={false}>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        )
    }

    return <></>
}

DisplayTag.propTypes = {
    reference: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    record: PropTypes.object,
    filter: PropTypes.object
}

DisplayTag.defaultProps = {
    record: {},
    filter: {}
}

const mapStateToProps = state => ({
    email: getUserEmail(state)
})

export const ListSubscription = connect(mapStateToProps)(list)
