import React from 'react'
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    SaveButton,
    useRedirect,
    translate,
    Toolbar,
    Edit,
    minLength
} from 'react-admin'
import Button from '@material-ui/core/Button'

const validateRole = [required(), minLength(3)]

export const RoleCreateForm = props => (
    <Create {...props} className="drawer-form">
        <SimpleForm title="Add role" toolbar={<RoleFormToolbar/>}>
            <TextInput source="name" validate={validateRole}/>
        </SimpleForm>
    </Create>
)

export const RoleEditForm = props => (
    <Edit {...props} className="drawer-form">
        <SimpleForm title="Update role" toolbar={<RoleFormToolbar/>}>
            <TextInput source="name" validate={validateRole}/>
        </SimpleForm>
    </Edit>
)

const RoleFormToolbar = translate(({translate, ...props}) => {
    const redirect = useRedirect()
    return (
        <Toolbar {...props} className="drawer-action-btns">
            <SaveButton/>
            <>
                <Button variant="contained" onClick={() => redirect('/roles')}>
                    {translate('ra.action.cancel')}
                </Button>
            </>
        </Toolbar>
    )
})
