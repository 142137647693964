import red from '@material-ui/core/colors/red'
import {createTheme} from '@material-ui/core/styles'

export const darkTheme = {
    palette: {
        type: 'dark',
        secondary: {
            light: '#85c462',
            main: '#729339',
            dark: '#376429',
            contrastText: '#fff'
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    }
}

export const lightTheme = createTheme({
    palette: {
        type: 'light',
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff'
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    sidebar: {
        width: 240,
        closedWidth: 55,
        fontSize: 'large'
    },
    datagrid: {
        header: {
            fontWeight: 'bold'
        }
    }
})
