export const creativeMessages = {
    creative: {
        brand: 'Brand',
        concept: 'Concept',
        variation: 'Variation',
        description: 'Source Files',
        downloading: 'Downloading and preparing creative(s) have started',
        prepareForDownload: 'Preparing creative(s) for download',
        downloadSuccess: 'Creative(s) download completed',
        downloadError: 'Error: Couldn’t download creative(s)',
        downloadFailedMessage:
            'Download creative(s) zip failed, please download individually',
        downloadSelected: 'Download selected',
        uploadToPartnerStarted:
            'Upload process started! failed uploads (if any) will be shown in dashboard',
        shareSelected: 'Share selected',
        prepareForShare: 'Preparing creative(s) for share',
        shareWithPartnerZipError:
            'Error: Couldn’t zip creative(s) zip for sharing',
        shareWithPartnerUploadError:
            'Error: Couldn’t upload zipped folder for sharing'
    }
}
