import React from 'react'
import {PropTypes} from 'prop-types'
import MovieIcon from '@material-ui/icons/Movie'
import ImageIcon from '@material-ui/icons/Image'
import GifIcon from '@material-ui/icons/Gif'
import CodeIcon from '@material-ui/icons/Code'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'

export const getTypes = filetypes => [...new Set(filetypes.map(getMacroType))]

const isVideo = type => type.includes('video')
const isGif = type => type.includes('gif')
const isImage = type => type.includes('image') && !isGif(type)
const isData = type => type.includes('application')

export const getMacroType = type => {
    try {
        if (isVideo(type)) {
            return 'video'
        }

        if (isGif(type)) {
            return 'gif'
        }

        if (isImage(type)) {
            return 'image'
        }

        if (isData(type)) {
            return 'data'
        }

        return 'unknown'
    } catch (error) {
        console.error(error)
        return 'unknown'
    }
}

const IconMapper = {
    video: () => <MovieIcon/>,
    image: () => <ImageIcon/>,
    gif: () => <GifIcon/>,
    data: () => <CodeIcon/>,
    unknown: () => <BrokenImageIcon/>
}

export const FileTypeIcon = ({fileType}) => Object.prototype.hasOwnProperty.call(IconMapper, fileType) ? (
    IconMapper[fileType]()
) : (
    <BrokenImageIcon/>
)

FileTypeIcon.propTypes = {
    fileType: PropTypes.string.isRequired
}
