const translations = {
    dashboard: {
        name: 'Dashboard',
        welcome: {
            title: 'Welcome to Creative Library Admin Tool',
            message: 'Logged in as %{name}',
            role: 'Role: %{role}'
        }
    }
}

export default translations
