import {Auth, Hub} from 'aws-amplify'
/**
 * When provider is passed to federatedSignIn (if Auth.configure contains oauth configuration for the redirect and domain)
 * AWS cognito HostedUI is skipped and login happens directly via the provider login screen.
 * FederatedSignIn does not receive any result when promise is resolved.
 * ( Login will be handled after the redirect from HostedUI - and reload of the main page)
 * @param provider
 */
function * waitForHubLogin() {
    yield waitForHostedUISignin()
    yield changeTheMainRoute()
}

function * changeTheMainRoute() {
    yield (window.location.href = '/')
}

function * waitForSocialLoginAction() {
    // yield takeEvery(SOCIAL_LOGIN, runSocialLogin)
    //  after the redirect from the hostedui  page is reinitialized therefore original saga is interrupted.
    yield waitForHubLogin()
}

// generator defining which actionTypes must be listened to in order to trigger the saga
export const socialLogin = waitForSocialLoginAction

const waitForHostedUISignin = () => new Promise(resolve => {
    Hub.listen('auth', ({payload: {event}}) => {
        switch (event) {
            case 'signUp':
                // todo  CHECK IF WE WANT TO RESOLVE HERE IN CASE OF SIGN UP ( or if we can still rely on the signin )
                break
                //  we receive an event from cognitoHostedUI but we wait better wait for signIn event (data here is just the auth configuration + some user info,  while we need the CognitoUser)
                // case 'cognitoHostedUI':
            case 'signIn':
                Auth.currentAuthenticatedUser().then(resolve)
                break
            default:
                break
        }
    })
})
