import React from 'react'
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    ChipField,
    ReferenceArrayField,
    SingleFieldList,
    CreateButton,
    CardActions,
    useRedirect,
    DateField,
    DeleteButton,
    RefreshButton
} from 'react-admin'
import {Drawer} from '@material-ui/core'
import {Route} from 'react-router'
import PropTypes from 'prop-types'
import {
    ListPagination,
    DEFAULT_PER_PAGE_PAGINATION
} from '../../shared/list-pagination'
import {UserCreateForm, UserEditForm} from './user-form'
import {Filters} from './list-filter'

export const UserList = props => {
    const redirect = useRedirect()
    const onClose = () => redirect('/users')
    return (
        <>
            <List
                {...props}
                filters={<Filters/>}
                pagination={<ListPagination/>}
                perPage={DEFAULT_PER_PAGE_PAGINATION}
                bulkActionButtons={false}
                actions={<PostActions {...props}/>}
                sort={{field: 'email', order: 'ASC'}}
            >
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="email"/>
                    <ReferenceArrayField
                        label="Roles"
                        reference="roles"
                        source="roles"
                    >
                        <SingleFieldList linkType={false}>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateField showTime source="grantedAccessAt"/>
                    <EditButton/>
                    <DeleteButton undoable/>
                </Datagrid>
            </List>
            <Route path="/users/create">
                {({match}) => (
                    <Drawer
                        open={Boolean(match)}
                        anchor="right"
                        onClose={onClose}
                    >
                        <UserCreateForm {...props}/>
                    </Drawer>
                )}
            </Route>
            <Route path="/users/:id">
                {({match}) => {
                    const isMatch
                        = match && match.params && match.params.id !== 'create'

                    return (
                        <Drawer
                            open={isMatch || false}
                            anchor="right"
                            onClose={onClose}
                        >
                            {isMatch ? (
                                <UserEditForm
                                    id={isMatch ? match.params.id : null}
                                    {...props}
                                />
                            ) : (
                                <div className="drawer-form"/>
                            )}
                        </Drawer>
                    )
                }}
            </Route>
        </>
    )
}

const PostActions = ({
    resource,
    filters,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    setFilters
}) => (
    <CardActions>
        {filters
                && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    basePath,
                    context: 'button'
                })}
        <RefreshButton
            label="Reset Filters"
            onClick={() => {
                setFilters({}, [])
            }}
        />
        <CreateButton basePath={basePath}/>
    </CardActions>
)

PostActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    displayedFilters: PropTypes.any,
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    resource: PropTypes.string.isRequired,
    showFilter: PropTypes.func,
    setFilters: PropTypes.func
}
