import {useForm} from 'react-final-form'
import {ReferenceInput, required, SelectInput} from 'react-admin'
import {SubBranchComp} from './sub-branch'
import React from 'react'
import PropTypes from 'prop-types'

export const Branch = ({targetSource}) => {
    const form = useForm()
    const values = form.getState().values
    const selectedBranchId = values[targetSource] || null
    const resetSubBranches = () => {
        form.change('subBranches', null)
    }

    return (
        <>
            <ReferenceInput
                label="Branch"
                source={targetSource}
                reference="branches"
                optionValue="id"
                optionText="name"
                validate={required()}
                perPage={0}
                onChange={resetSubBranches}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            { selectedBranchId && <SubBranchComp filterId={selectedBranchId}/>}
        </>
    )
}

Branch.propTypes = {
    targetSource: PropTypes.string
}

Branch.defaultProps = {
    targetSource: undefined
}
