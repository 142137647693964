import React from 'react'
import PropTypes from 'prop-types'
import {MenuItemLink} from 'react-admin'
import {ConceptsIcon} from './icon'

export const ConceptMenu = ({onMenuClick}) => (
    <MenuItemLink
        to="/concepts"
        primaryText="Concepts"
        leftIcon={<ConceptsIcon/>}
        onClick={onMenuClick}
    />
)

ConceptMenu.propTypes = {
    onMenuClick: PropTypes.func
}

ConceptMenu.defaultProps = {
    onMenuClick: undefined
}
