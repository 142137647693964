import {socialLogin} from './sagas/social-login-saga'
import {checkUserAuth} from './sagas/check-user-saga'
import {setAuthorizedUser} from './reducers/set-auth-user-reducer'
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    sagas: [socialLogin, checkUserAuth],
    reducers: {
        user: setAuthorizedUser
    }
}
