import {withStyles} from '@material-ui/core/styles'
import {Filter, TextInput, NumberInput, NullableBooleanInput} from 'react-admin'
import React from 'react'

const filterStyles = () => ({
    mainFilter: {
        padding: '5px'
    },
    filterNode: {
        marginLeft: '-50px'
    }
})

export const Filters = withStyles(filterStyles)(props => {
    const {classes, resource, defaultTitle, ...rest} = props
    return (
        <Filter {...rest} className={classes.mainFilter}>
            <TextInput
                alwaysOn
                resource={resource}
                label="Name"
                source="q"
                className="filter-text-style"
            />
            <NumberInput
                alwaysOn
                resource={resource}
                label="Id"
                source="id"
                className="filter-text-style"
                min={1}
            />
            <NullableBooleanInput
                alwaysOn
                resource={resource}
                label="Active Brand"
                source="isActiveBrand"
                className="filter-text-style"
            />
        </Filter>
    )
})
