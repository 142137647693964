import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import {withStyles} from '@material-ui/core/styles'

const styles = {
    listItem: {
        paddingLeft: '1rem'
    },
    listItemText: {
        paddingLeft: 0,
        fontSize: '1rem',
        marginLeft: '-15px'
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    }
}

const SubMenuComponent = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children
}) => (
    <>
        <ListItem
            dense
            button
            className={classes.listItem}
            onClick={handleToggle}
        >
            <ListItemIcon>{isOpen ? <ExpandMore/> : icon}</ListItemIcon>
            <ListItemText
                inset
                primary={isOpen ? name : ''}
                secondary={isOpen ? '' : name}
                className={classes.listItemText}
            />
        </ListItem>
        <Collapse unmountOnExit in={isOpen} timeout="auto">
            <List
                dense
                disablePadding
                component="div"
                className={
                    sidebarIsOpen
                        ? classes.sidebarIsOpen
                        : classes.sidebarIsClosed
                }
            >
                {children}
            </List>
            <Divider/>
        </Collapse>
    </>
)

SubMenuComponent.propTypes = {
    children: PropTypes.array,
    classes: PropTypes.object,
    handleToggle: PropTypes.func,
    icon: PropTypes.object,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    sidebarIsOpen: PropTypes.bool
}

SubMenuComponent.defaultProps = {
    children: null,
    classes: null,
    handleToggle: undefined,
    icon: undefined,
    isOpen: false,
    name: undefined,
    sidebarIsOpen: false
}

const enhance = compose(withStyles(styles))

export const SubMenuUIComponent = enhance(SubMenuComponent)
