import {Auth} from 'aws-amplify'
import {put, takeLatest} from 'redux-saga/effects'
import {setAuthUser} from '../actions/action-creators'
import dataProvider from '../../app-configs/data-provider/data-provider'
import {initPartnersAccounts} from '../../app-configs/partners/utils'
import {showNotification} from 'react-admin'

function * checkAuthenticatedUser() {
    const user = yield Auth.currentAuthenticatedUser({
        bypassCache: false
    }).catch(error => {
        console.log(error)
    })
    if (user) {
        yield put(setAuthUser(user))
        redirectIfNoNotificationsInDashboard()

        try {
            yield initPartnersAccounts()
        } catch (error) {
            yield put(showNotification(error.message, 'warning'))
        }
    }
}

const parameters = {
    filter: {},
    sort: {
        field: 'failureTimestamp',
        order: 'ASC'
    },
    range: [0, 1],
    pagination: {
        page: 1,
        perPage: 0
    }
}

const redirectIfNoNotificationsInDashboard = () => {
    // only check notifications on dashboard url
    if (window.location.href.endsWith('/#/')) {
        dataProvider
            .getList('partnerUploadFailures', parameters)
            .then(({data}) => {
                if (data.length === 0) {
                    window.location.href = '/#/variations'
                }
            })
    }
}

function * waitForUserCheckAction() {
    yield takeLatest('RA/REGISTER_RESOURCE', checkAuthenticatedUser)
}

export const checkUserAuth = waitForUserCheckAction
