import Storage from '@aws-amplify/storage'

export const loadSharedLinksByLoggedUser = async () => Storage.list('', {level: 'protected'})
    .then(async result => {
        const baseUrl = await getBaseUrl(result)
        const results = result
            .map(item => ({
                id: item.eTag,
                name: item.key,
                link: baseUrl + item.key,
                createdAt: item.lastModified
            }))
            .sort((a, b) =>
                a.createdAt.getTime() < b.createdAt.getTime() ? 1 : -1
            )

        return {data: results, total: results.length}
    })
    .catch(error => console.log(error))

const getBaseUrl = async results => {
    if (results && results.length > 0) {
        const path = await Storage.get(results[0].key, {level: 'protected'})
        return path.split('?')[0].replace(results[0].key, '')
    }
}
