import React from 'react'
import {List, TextField, Datagrid, DateField, useListContext} from 'react-admin'
import {
    ListPagination
} from '../../shared/list-pagination'
import {FailedCreativesUploadMessage} from '../../shared/warning-message'
import {UploadCreativesByIds} from '../../variation/components/upload-button'
import {Filters} from './filter-list-partner-upload-failure'

export const ListPartnerUploadFailures = () => (
    <List
        hasList
        title="- Failed Partner upload -"
        actions={<ListActions/>}
        perPage={50}
        pagination={<FailureListPagination/>}
        bulkActionButtons={false}
        filters={<Filters/>}
        resource="partnerUploadFailures"
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        basePath="/partnerUploadFailures"
        sort={{field: 'failureTimestamp', order: 'DESC'}}
        empty={<></>}
    >
        <Datagrid>
            <TextField source="creativeId" label="CreativeId"/>
            <TextField source="partner"/>
            <TextField source="accountId"/>
            <TextField source="author"/>
            <DateField showTime source="failureTimestamp"/>
            <TextField source="message" style={{wordBreak: 'break-all'}}/>
        </Datagrid>
    </List>
)

const FailureListPagination = () => {
    const {loaded, data} = useListContext()
    const ids = getIdsFromDataList(data)
    if (loaded && ids.length === 0) {
        return null
    }

    return <ListPagination/>
}

const ListActions = () => {
    const {data, loaded} = useListContext()
    const ids = getIdsFromDataList(data)

    if (loaded && ids.length === 0) {
        return null
    }

    return (
        <div className="failed-upload-list-actions">
            <FailedCreativesUploadMessage/>
            <UploadCreativesByIds creativeIds={ids}/>
        </div>
    )
}

const getIdsFromDataList = data =>
    Object.values(data).reduce(
        (acc, {creativeId}) =>
            creativeId && acc.includes(creativeId) ? acc : [...acc, creativeId],
        []
    )
