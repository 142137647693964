import React from 'react'
import {
    useQuery,
    SelectArrayInput,
    SelectInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    TextInput
} from 'react-admin'
import {PropTypes} from 'prop-types'
import {FormSpy} from 'react-final-form'

export const ReferencedPropertySelect = props => {
    const {
        source,
        allowMultipleSelection,
        allowAutoComplete,
        cReference,
        ...rest
    } = props
    const payload = {
        pagination: {page: 1, perPage: 0},
        sort: {field: 'name', order: 'ASC'},
        filter: rest.filter ? rest.filter : {}
    }
    const {data, loading, error} = useQuery({
        type: 'getDataSources',
        resource: cReference,
        payload
    })

    const arrayInputProps = {
        source,
        choices: data,
        translateChoice: false,
        ...rest
    }
    const singleInputProp = {
        allowEmpty: true,
        emptyValue: null,
        emptyText: '--reset--',
        ...arrayInputProps
    }

    if (loading || error) {
        return (
            <SelectInput
                disabled
                choices={[]}
                translateChoice={false}
                source={source}
                {...rest}
            />
        )
    }

    if (allowAutoComplete) {
        return allowMultipleSelection ? (
            <AutocompleteArrayInput {...arrayInputProps}/>
        ) : (
            <AutocompleteInput {...singleInputProp}/>
        )
    }

    return allowMultipleSelection ? (
        <SelectArrayInput {...arrayInputProps} style={{minWidth: '160px'}}/>
    ) : (
        <SelectInput {...singleInputProp} style={{overflowY: 'auto'}}/>
    )
}

ReferencedPropertySelect.propTypes = {
    source: PropTypes.string.isRequired,
    cReference: PropTypes.string.isRequired,
    allowMultipleSelection: PropTypes.bool.isRequired,
    allowAutoComplete: PropTypes.bool
}

export const CreativeValidation = () => (
    <FormSpy>
        {fs => fs.errors.creatives ? (
            <div
                style={{color: '#f44336', fontSize: '0.75rem'}}
            >{`* ${JSON.stringify(fs.errors.creatives)}`}
            </div>
        ) : null}
    </FormSpy>
)

export const ForkedFrom = ({record}) => record.forkedFrom ? (
    <TextInput
        disabled
        resource="variations"
        source="forkedFrom"
        label="Forked From - Parent Id"
        className="forked-from-text-layout"
    />
) : null

ForkedFrom.propTypes = {
    record: PropTypes.object
}
