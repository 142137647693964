import React, {useState} from 'react'
import {PropTypes} from 'prop-types'
import PublishIcon from '@material-ui/icons/Publish'
import {useRedirect} from 'react-admin'
import Button from '@material-ui/core/Button'
import {UploadCreativesToPartner} from '../../creatives/components/upload-to-partner'

export const UploadVariation = ({record}) => {
    const redirect = useRedirect()
    const creativeIds = record.creatives.map(c => c.id).join(',')
    const url = `/creatives?filter={"id":[${creativeIds}]}`

    return (
        <Button
            color="primary"
            startIcon={<PublishIcon/>}
            onClick={() => redirect(url)}
        >
            Upload
        </Button>
    )
}

UploadVariation.propTypes = {
    record: PropTypes.object
}

UploadVariation.defaultProps = {
    record: undefined
}

export const UploadCreativesByIds = ({creativeIds}) => {
    const redirect = useRedirect()
    const creativeIdsArray = creativeIds.map(id => id).join(',')
    const url = `/creatives?filter={"id":[${creativeIdsArray}]}`

    return (
        <Button
            color="primary"
            startIcon={<PublishIcon/>}
            onClick={() => redirect(url)}
        >
            Retry upload
        </Button>
    )
}

UploadCreativesByIds.propTypes = {
    creativeIds: PropTypes.array
}

UploadCreativesByIds.defaultProps = {
    creativeIds: []
}

export const UploadCreatives = props => {
    const [showDialog, setShowDialog] = useState(false)
    const openPartnerForm = () => setShowDialog(true)

    return (
        <>
            <Button color="primary" startIcon={<PublishIcon/>} onClick={openPartnerForm}>Upload To Partner</Button>
            <UploadCreativesToPartner showDialog={showDialog} setShowDialog={setShowDialog} {...props}/>
        </>
    )
}

UploadCreatives.propTypes = {
    props: PropTypes.object
}

UploadCreatives.defaultProps = {
    props: undefined
}
