import React from 'react'
import {Pagination} from 'react-admin'
import {PropTypes} from 'prop-types'
import {
    resourceRequireFilter,
    getUserPerPageCache
} from '../app-configs/data-provider/utils'

export const ListPagination = props => {
    const {resource, filterValues} = props
    if (resourceRequireFilter(resource) && !areFiltersUsed(filterValues)) {
        return <></>
    }

    return paginationComponent(props)
}

ListPagination.propTypes = {
    resource: PropTypes.string,
    filterValues: PropTypes.object
}

const areFiltersUsed = (filterValues = {}) =>
    Object.keys(filterValues).length > 0

export const LIST_PER_PAGE_PAGINATION_OPTIONS = [50, 100, 150, 200]
export const DEFAULT_PER_PAGE_PAGINATION = 150
const paginationComponent = props => {
    const {resource, total} = props
    let {perPage} = props
    if (
        !LIST_PER_PAGE_PAGINATION_OPTIONS.includes(perPage)
        && resourceRequireFilter(resource)
    ) {
        perPage = getUserPerPageCache(resource)
    }

    return (
        total > 0 && (
            <Pagination
                labelRowsPerPage="Records Per Page"
                rowsPerPageOptions={LIST_PER_PAGE_PAGINATION_OPTIONS}
                perPage={perPage}
                {...props}
            />
        )
    )
}

paginationComponent.propTypes = {
    resource: PropTypes.string.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
}
