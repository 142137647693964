export const generateCreativeFileName = (creative, variation) => {
    const {key, duration, language, width, height, id, platforms} = creative
    const {name, id: variationId, concept, hierarchy} = variation
    if (!variationId || !name || !concept) {
        throw new TypeError(
            'Variation missing essential property for generating file name'
        )
    }

    const parts = [variationId]

    const version = hierarchy ? `v-${hierarchy.version}` : null
    if (version) {
        parts.push(version)
    }

    parts.push(concept, name, preparePlatformName(platforms), `${width}x${height}`)

    if (duration) {
        parts.push(`${duration}sec`)
    }

    if (language && language.code) {
        parts.push(language.code.toUpperCase())
    }

    parts.push('cid-' + id)
    const extension = key.split('.').pop()

    return `${parts.join('_')}.${extension}`
}

export const preparePlatformName = platforms => {
    if (platforms && platforms.length > 1) {
        return 'Multi'
    }

    if (platforms && platforms.length === 1) {
        if (!Object.prototype.hasOwnProperty.call(platforms[0], 'name')) {
            throw new TypeError('Platform dosnt have Prop name')
        }

        return platforms[0].name
    }

    return 'None'
}

export const getLanguageCode = filename => {
    if (!filename) {
        return
    }

    const regexp = /((lang=)([a-z]+))/i
    // eslint-disable-next-line no-unused-vars
    const [match, group, pattern, code] = filename.match(regexp) || []

    return code && code.toLowerCase()
}

export const getLanguageId = languages => langCode => {
    if (!languages) {
        return
    }

    languages = Object.values(languages)
    const lang = languages.find(({code}) => code === langCode) || {}

    return lang.id
}

// module.exports = {
//     preparePlatformName,
//     generateCreativeFileName,
//     getLanguageId,
//     getLanguageCode
// }
