import React, {useState, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import {Close} from '@material-ui/icons'
import {Box, Button, Typography} from '@material-ui/core'
import {EditButton, Labeled, Loading, usePermissions, useRedirect, useNotify} from 'react-admin'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import {formatBytes} from './media-size-res'
import ReactPlayer from 'react-player'
import CustomDeleteBtnConfirmation from './custom-delete-btn-confirmation'
import {DownloadButton, DownloadAll} from './download'
import {FileTypeIcon, getMacroType} from './file-type-icon'
import {retrieveCreativesUrls} from '../../app-configs/s3-provider'
import dataProvider from '../../app-configs/data-provider/data-provider'
import {hasEditPermission} from '../../app-configs/auth/utils'
import {UploadVariation} from './upload-button'
import {displayCreativePartnerInfo} from '../../creatives/components/list'
import {CloningSelector} from './clone-selector'
import {getCloneURI, prepareData} from '../../shared/cloning-helper'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

export const Aside = ({selectedItemId, setSelectedItemId, isArchivedMode}) => {
    const [selectedItem, setSelectedItem] = useState()
    const [mainCreativeIndex, setMainCreativeIndex] = useState(0)
    const redirect = useRedirect()
    const handleSelector = isIteration => {
        const data = prepareData(selectedItem, isIteration)
        const url = getCloneURI(data)
        redirect(url)
    }

    document.addEventListener('keydown', event => {
        if (event.key === 'Escape') {
            setSelectedItemId()
        }
    })

    useEffect(() => {
        if (selectedItemId) {
            const getSelectedItem = async id => {
                // `aside${id}` 'aside' used as flag in get-variation to add associations
                const item = await dataProvider
                    .getOne('variations', {id: `aside${id}`})
                    .then(async ({data}) => {
                        const creatives
                            = data && data.creatives
                                ? await retrieveCreativesUrls(data.creatives)
                                : []
                        return {
                            ...data,
                            creatives
                        }
                    })

                setSelectedItem(item)
            }

            getSelectedItem(selectedItemId)
            setMainCreativeIndex(0)
        }
    }, [selectedItemId])

    const {permissions} = usePermissions()

    if (!selectedItemId) {
        return <></>
    }

    if (!selectedItem || selectedItemId !== selectedItem.id) {
        return asideComponent(<Loading/>, setSelectedItemId)
    }

    const mainCreativeUrl
        = selectedItem.creatives && selectedItem.creatives[mainCreativeIndex]
            ? selectedItem.creatives[mainCreativeIndex].url
            : undefined

    return asideComponent(
        <>
            <Box style={ReviewDetails}>Review Details</Box>
            {isMainCreativeIndexVideo(selectedItem, mainCreativeIndex) ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <ReactPlayer
                        controls
                        url={mainCreativeUrl}
                        playing={false}
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px'
                        }}
                    />
                </div>
            ) : (
                <img src={mainCreativeUrl} className="list-aside-img" alt=""/>
            )}
            <Box
                textAlign="center"
                fontWeight="fontWeightMedium"
                style={{
                    color: '#757575',
                    marginBottom: '10px',
                    marginTop: '10px'
                }}
            >
                {selectedItem.fullName}
            </Box>

            <DisplayFields record={selectedItem}/>

            <DisplayCreatives
                record={selectedItem}
                disaplayIndex={mainCreativeIndex}
                onClick={setMainCreativeIndex}
            />

            {!isArchivedMode && (
                <Box p="1em" display="flex" justifyContent="space-around">
                    {hasEditPermission(permissions) && (
                        <EditButton
                            label="ra.action.edit"
                            basePath="/variations"
                            record={selectedItem}
                        />
                    )}

                    <DownloadAll record={selectedItem}/>

                    <UploadVariation record={selectedItem}/>
                    {hasEditPermission(permissions) && (<CloningSelector handleSelector={handleSelector}/>)}
                    <CustomDeleteBtnConfirmation
                        label="ra.action.delete"
                        record={selectedItem}
                        resource="variations"
                        basePath="/variations"
                        funcClose={setSelectedItemId}
                    />
                </Box>
            )}
        </>,
        setSelectedItemId
    )
}

Aside.propTypes = {
    selectedItemId: PropTypes.number,
    setSelectedItemId: PropTypes.func.isRequired,
    isArchivedMode: PropTypes.bool
}

Aside.defaultProps = {
    selectedItemId: undefined
}

const isMainCreativeIndexVideo = (selectedItem, mainCreativeIndex) =>
    selectedItem
    && selectedItem.creatives
    && selectedItem.creatives[mainCreativeIndex]
    && selectedItem.creatives[mainCreativeIndex].fileTypeId
    && selectedItem.creatives[mainCreativeIndex].fileTypeId.startsWith('video/')

const asideComponent = (body, setSelectedItemId) => (
    <Box
        p="1em"
        bgcolor="white"
        overflow="auto"
        display="flex"
        flexDirection="column"
        zIndex="modal"
        position="fixed"
        right="0"
        top="2px"
        boxShadow={3}
        width="600px"
        height="95%"
    >
        <Box display="block" justifyContent="flex-end">
            <Button
                variant="contained"
                color="primary"
                style={{width: '90px'}}
                startIcon={<Close/>}
                onClick={() => setSelectedItemId()}
            >
                Close
            </Button>
        </Box>
        {body}
    </Box>
)

const formatResolution = (width, height) => {
    if (!width || !height) {
        return 'Resolution: n/a'
    }

    return 'Resolution: ' + width + 'x' + height
}

const DisplayCreatives = props => {
    const {
        record: {creatives}
    } = props

    if (!creatives) {
        return <></>
    }

    return (
        <Box flex={1} mr="1em">
            <Creatives creatives={creatives} {...props}/>
        </Box>
    )
}

DisplayCreatives.propTypes = {
    record: PropTypes.object.isRequired
}

const Creatives = props => {
    const {creatives, disaplayIndex, onClick, record} = props
    const notify = useNotify()

    return (
        <div>
            <Typography gutterBottom variant="h6" style={{color: '#a2a0a0'}}>
                Creatives
            </Typography>

            {creatives.map((creative, index) => {
                const className = ['asideCreativeName']
                if (disaplayIndex === index) {
                    className.push('selectedCreative')
                }

                return (
                    <div
                        key={creative.id}
                        style={{
                            backgroundColor: '#ece8e8',
                            padding: '10px',
                            marginBottom: '10px'
                        }}
                    >
                        <Box
                            key={creative.fileName}
                            display="flex"
                            marginBottom="5px"
                            className="cursor-pointer"
                            justifyContent="space-between"
                            onClick={() => onClick(index)}
                        >
                            <Box mr="3px" display="flex">
                                <FileTypeIcon
                                    fileType={getMacroType(creative.fileTypeId)}
                                />
                                <Typography className={className.join(' ')}>
                                    {creative.fileName}
                                </Typography>
                            </Box>
                            <Box mr="0.5em" marginLeft="20px">
                                <Chip
                                    label={`cid-${creative.id}`}
                                    color="primary"
                                    variant="outlined"
                                    deleteIcon={<FileCopyOutlinedIcon/>}
                                    onDelete={() => {
                                        window.navigator.clipboard.writeText(encodeURI(`${window.location.origin}/#/creatives?filter={"cid":${creative.id}}`))
                                        notify('The link to creative has been copied to clipboard!', 'info')
                                    }}/>
                                {creative.language
                                    && creative.language.name && (
                                    <Chip label={creative.language.name}/>
                                )}
                                {creative.orientation
                                    && creative.orientation.name && (
                                    <Chip
                                        label={
                                            'Orientation: '
                                                + creative.orientation.name
                                        }
                                    />
                                )}
                                {creative.width && creative.height && (
                                    <Chip
                                        label={formatResolution(
                                            creative.width,
                                            creative.height
                                        )}
                                    />
                                )}
                                <Chip
                                    label={formatBytes(creative.fileSize, 1)}
                                />
                            </Box>
                            <Box
                                mr="0.5em"
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <DownloadButton
                                    creative={creative}
                                    record={record}
                                />
                            </Box>
                        </Box>
                        <Box flex={2} mr="1em">
                            <Box display="flex">
                                {creative.cta && (
                                    <Box flex={1} mr="0.5em">
                                        <CreativeTags
                                            tags={creative.cta}
                                            label="CTA"
                                        />
                                    </Box>
                                )}
                                {creative.platforms
                                    && creative.platforms.length > 0 && (
                                    <Box flex={1} mr="0.5em">
                                        <CreativeTags
                                            tags={creative.platforms}
                                            label="Platforms"
                                        />
                                    </Box>
                                )}
                                {creative.brandings
                                    && creative.brandings.length > 0 && (
                                    <Box flex={1} mr="0.5em">
                                        <CreativeTags
                                            tags={creative.brandings}
                                            label="Brandings"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {isCreativeUploadedToPartners(creative) && (
                            <Box flex={2} mr="1em">
                                <h5>Partners</h5>
                                <div style={{fontSize: '13px'}}>
                                    {creative.partners.map(
                                        displayCreativePartnerInfo
                                    )}
                                </div>
                            </Box>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

Creatives.propTypes = {
    creatives: PropTypes.array.isRequired,
    disaplayIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired
}

const isCreativeUploadedToPartners = ({partners}) => partners.length > 0

const DisplayFields = ({record}) => (
    <Box p="1em">
        <Typography gutterBottom variant="h6">
            Info
        </Typography>
        <Box display="flex">
            <Box flex={3} mr="1em">
                <Box display="flex">
                    {record.game && record.game.name && (
                        <Box flex={2} mr="0.5em">
                            <Labeled label="Game">
                                <TextField
                                    fullWidth
                                    disabled
                                    value={record.game.name}
                                    style={{marginBottom: '8px'}}
                                />
                            </Labeled>
                        </Box>
                    )}
                    <Box flex={2} mr="0.5em">
                        <Labeled label="Concept">
                            <TextField
                                fullWidth
                                disabled
                                value={record.concept}
                                style={{marginBottom: '8px'}}
                            />
                        </Labeled>
                    </Box>
                    <Box flex={2} mr="0.5em">
                        <Labeled label="Variation">
                            <TextField
                                disabled
                                fullWidth
                                value={record.name}
                                style={{marginBottom: '8px'}}
                            />
                        </Labeled>
                    </Box>
                </Box>

                <Box display="flex">
                    {record.description && (
                        <Box flex={2} mr="0.5em">
                            <Labeled label="Source Files">
                                <TextField
                                    fullWidth
                                    disabled
                                    value={record.description}
                                    className="aside-simple-text-layout"
                                />
                            </Labeled>
                        </Box>
                    )}
                    <Box flex={2} mr="0.5em">
                        <Labeled label="UniqueId ( for KPI )">
                            <TextField
                                fullWidth
                                disabled
                                value={record.id}
                                className="aside-simple-text-layout"
                            />
                        </Labeled>
                    </Box>
                    {record.version && (
                        <Box flex={2} mr="0.5em">
                            <Labeled label="Version">
                                <TextField
                                    fullWidth
                                    disabled
                                    value={record.version}
                                    className="aside-simple-text-layout"
                                />
                            </Labeled>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>

        <Box flex={2} mr="1em">
            <Typography gutterBottom variant="h6">
                Tags
            </Typography>

            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <Tags
                        record={record}
                        source="classification"
                        label="Classification"
                    />
                </Box>
                <Box flex={1} mr="0.5em">
                    <Tags
                        record={record}
                        source="motivation"
                        label="Motivation"
                    />
                </Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <Tags record={record} source="moods" label="Moods"/>
                </Box>
                <Box flex={1} mr="0.5em">
                    <Tags record={record} source="themes" label="Themes"/>
                </Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <Tags
                        record={record}
                        source="colorSchemes"
                        label="Color Schemes"
                    />
                </Box>
                <Box flex={1} mr="0.5em">
                    <Tags
                        record={record}
                        source="contents"
                        label="Contents"
                    />
                </Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <Tags
                        record={record}
                        source="style"
                        label="Execution Style"
                    />
                </Box>
                {record.branch && (
                    <Box flex={1} mr="0.5em">
                        <Tags
                            record={record}
                            source="branch"
                            label="Branch"
                        />
                    </Box>
                )}
            </Box>
            <Box display="flex">
                {record.subBranches && record.subBranches.length > 0 && (
                    <Box flex={1} mr="0.5em">
                        <Tags
                            record={record}
                            source="subBranches"
                            label="Sub Branches"
                        />
                    </Box>)}
                {record.customTags && record.customTags.length > 0 && (
                    <Box flex={1} mr="0.5em">
                        <Tags
                            record={record}
                            source="customTags"
                            label="Custom Tags"
                        />
                    </Box>
                )}
            </Box>
        </Box>
    </Box>
)

DisplayFields.propTypes = {
    record: PropTypes.object.isRequired
}

export const Tags = props => {
    const {record, source, label} = props

    if (!record[source]) {
        return <></>
    }

    const value = !Array.isArray(record[source])
        ? [record[source]]
        : record[source]
    return (
        <>
            <Typography gutterBottom>{label}</Typography>
            {value.map(({id, name}) => <Chip key={id} label={name} variant="outlined"/>)}
        </>
    )
}

Tags.propTypes = {
    record: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

const CreativeTags = props => {
    const {tags, label} = props
    if (!tags) {
        return <></>
    }

    const value = !Array.isArray(tags) ? [tags] : tags
    return (
        <>
            <Typography gutterBottom>{label}</Typography>
            {value.map(({id, name}) => <Chip key={id} label={name} variant="outlined"/>)}
        </>
    )
}

CreativeTags.propTypes = {
    tags: PropTypes.any,
    label: PropTypes.string.isRequired
}

const ReviewDetails = {
    color: '#929090',
    fontSize: 'large',
    marginTop: '-28px',
    minWidth: '500px',
    fontWeight: 'bold',
    textAlign: 'center'
}
